import { GET_USER_PRODUCT_FAIL, GET_USER_PRODUCT_REQUEST, GET_USER_PRODUCT_SUCCESS } from "../Constant/getUserProduct"


export const userProductReducer = (state = { userProductR: [] }, action) => {
    switch (action.type) {
        case GET_USER_PRODUCT_REQUEST:
         
            return {
              loading: true,
              userProductR: [],
            }

case GET_USER_PRODUCT_SUCCESS:
    console.log(action.payload,'acion');
    return{
        loading:false,
        userProductR:action.payload,
    }
    case GET_USER_PRODUCT_FAIL:
        // console.log(action.payload);
        return{
            loading:false,
            userProductR:action.payload
        }
        default:
            return state;}}