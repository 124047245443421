import React, { useEffect, useState } from 'react'
import './navbar.css'
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from "@material-ui/icons/Add";
import { NavLink, useNavigate } from 'react-router-dom';
import { searchProduct } from '../../Redux/Action/listProductAction';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useParams } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';

import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import Dashboard from '@material-ui/icons/Dashboard';


const Navbar = () => {
  <script>
    
  </script>
  const dispatch=useDispatch()
  const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer);

  const {cartItems}=useSelector(state=>state.cart)
  const [keyword, setKeyword] = useState('')
  const navigate=useNavigate()
  const handleClick=()=>{
navigate('/products')
  }
  const handleDashboard=()=>{
navigate('/admin/dashboard')
  }
  const handleNavigate=()=>{
navigate('/login')
  }
  const handleCart=()=>{
navigate('/cart')
  }
  const handleClickHome=()=>{
navigate('/home')
  }
  const handleClickProfile=()=>{
navigate('/profile')
  }
  const handleSignin=()=>{
navigate('/signin')
  }
  const handleChange=(e)=>{
if(keyword!==''){
  navigate(`/products/${keyword}`)}
  setKeyword(e.target.value)
}

  return (
<>
<div className="navbar">
<div className="logo" onClick={handleClick}>
  <img src='./images/logo.png' alt='logo' style={{cursor:'pointer'}}/>

</div>
<div className="companyName" onClick={handleClickHome} style={{cursor:'pointer'}}>Sello Point</div>

<div className="searchBar">
  <div className="search text-center">
    <input type='text' placeholder='Please Search Here' className='text-center'
    onChange={handleChange}
    // style={{cursor:'pointer'}}
    />
  </div>
  <div className="search-icon text-center align-content-center align-items-center">
<SearchIcon/>
  </div>
</div>
<div className="cart-wishlist">

  <div className="user-login">
    <div>
  {userDetails?<img src={`/uploads/users/${userDetails?userDetails.fileName:null}`} alt=''
  onClick={handleClickProfile} style={{cursor:'pointer'}}
  /> :
   <PersonIcon style={{cursor:'pointer'}}/>}
</div>
        <span> 
{userDetails?userDetails.name:<div onClick={handleNavigate} style={{cursor:'pointer'}}>Hello! Sign in</div>}<br/>
</span></div>
<div className="wishlist" onClick={handleDashboard}>
{  userDetails?.role==='superAdmin'?<div><Dashboard  style={{cursor:'pointer'}}/>
<span style={{cursor:'pointer'}}>Dashboard</span></div>:<div className="ishlist"><FavoriteIcon style={{cursor:'pointer'}}/><span style={{cursor:'pointer'}}>WishList<br/>
  0 items
  </span></div>}</div>

  <div className="cart-option" onClick={handleCart}> <ShoppingCartIcon style={{cursor:'pointer'}}/> <span style={{cursor:'pointer',color:'white'}}>
  {cartItems?.length>0?cartItems.length:`Cart`}
    
    </span></div>



</div>
</div>
{/* new */}

</>
  )
}

export default Navbar