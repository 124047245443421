import React, { useEffect, useState } from 'react'
import {useFormik,} from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import {DatePicker} from 'antd'
// import moment from 'moment'
import { useAlert } from 'react-alert';
import MetaData from '../layout/MetaData';
import Swal from 'sweetalert2'
import moment from 'moment'
import { addOffer1Action } from '../../Redux/Action/offerAction';
import { json } from 'react-router-dom';
const {RangePicker}=DatePicker
const Offer1 = () => {
    const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer);
    console.log(userDetails,'user details');
    const [preview, setPreview] = useState('')
    const [fileName, setFileName] = useState('')
    const [duration,setDuration]=useState()
    const [startDate,setStartDate]=useState()
    const [endDate,setEndDate]=useState()

    // // console.log(duration,'duration');
    // let creationDate=new Date()
    // const creationTime=new Date().toLocaleTimeString()
    // let month=creationDate.getMonth()+1
    // let day= creationDate.getDate()
    // let year=creationDate.getFullYear()
    // creationDate=day+'-'+month+'-'+year
    const [user, setUser] = useState(userDetails._id)
const dispatch=useDispatch()
const alert=useAlert()
    const handleImage=(e)=>{
      let reader= new FileReader();
          reader.onload=(e)=>{
  if(reader.readyState===2){
          setPreview(reader.result)
          setFieldValue('offerImage',(reader.result));}
      }
      
        reader.readAsDataURL(e.currentTarget.files[0]) 
      
      setFileName(e.target.files[0].name)
    }
    
    // console.log(userDetails._id,'user details,reducers');
    const {handleBlur,handleReset,handleSubmit,setFieldValue,handleChange,values,touched,errors}=useFormik({
        initialValues:{
         name:'',offerImage:'', deal:''
          },
          validationSchema:Yup.object().shape({
     name:Yup.string().min(3).max(50).required('please enter image name'),
        offerImage:Yup.string().required('image is required'),
        // deal:Yup.date().required('date  is required')
       
               
        }),
         onSubmit:(values)=>{
          const{name,offerImage,deal}=values
         console.log(values,fileName,user,duration,'val');
             dispatch(addOffer1Action({name,offerImage,fileName,user,startDate,endDate}))
           
            }})
        //     useEffect(() => {
        //   if(success===true){
        //     // alert.success('offer has been created successfully')
        //     Swal.fire({
        //       title: "Sweet!",
        //       text: "Modal with a custom image.",
        //       imageUrl: "https://unsplash.it/400/200",
        //       imageWidth: 400,
        //       imageHeight: 200,
        //       imageAlt: "Custom image"
        //     });
        //   }mm
        //   if(success===undefined){
        //     alert.error('offer not created')
        //   }
        //     }, [alert,success])
            
  return (
  <>
 <MetaData title={`add new offer`} />
 <div className="container-fluid mt-5 h-auto">
  <div className="row">
    <div className="col-lg-2 col-sm-12 col-md-2 bg-transparent">side bar</div>
    <div className="col-lg-10 col-sm-12 col-md-10">
<section className="register">
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-12 col-xl-11" >
        <div className="card text-black w-100" style={{background:'none',border:'none'}}>
          <div className="card-bo p-md-5">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">

                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Add offer</p>
      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>

      <div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Image Name</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='Full Name'
                 onChange={handleChange}  onBlur={handleBlur} name='name' />
                      <div className='error' style={{color:'red'}}>
                        {touched.name && errors.name ? errors.name:''}
                      </div>
                    </div>
                  </div>


<div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">upload Image</label>
                  </div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="file" className="form-control ms-3"                   
                    onChange={handleImage}
                      
                    />
                      <div className='error' style={{color:'red'}}>
                        {touched.offerImage && errors.offerImage ? errors.offerImage:''}
                      </div>
                  
                    
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">select date</label></div>
                              <div className="form-outline flex-fill mb-0">
                                <div className="col-6">
                                  <input type='date' onChange={(e)=>setStartDate(e.target.value)}/>
                                </div>
                                <div className="col-6">

                                <input type='date' onChange={(e)=>setEndDate(e.target.value)}/>

                                </div>
              {/* <RangePicker onChange={(values)=>(
                setDuration(values.map(item=>{
                
return item.format('D-M-YYYY')
                }
  )))} name='deal'/> */}
<div className='error' style={{color:'red'}}>
{touched.deal && errors.deal ? errors.deal:''}
</div>
  </div>
  
  </div>
                 {/* <div className='text-center mb-3'> {preview!==''?<img src={preview}/>:<h6>no picture found</h6>}
                 </div> */}
                 <button type="submit" className="btn btn-primary btn-lg">Upload</button>
</form>
</div></div></div></div></div></div></div></section></div></div></div>

  </>
  )
}

export default Offer1