import React from 'react'
import {FaStar,FaBeer,FaStarHalfAlt} from 'react-icons/fa'

const Star = ({star}) => {
    console.log(star,'stars');
    const option={
      // backgroundColor:'red',
      color:'tomato'
    }
    
  return (
<>
<div className="star">
{star>=1?<FaStar style={{...option}}/>:star=1.5?<FaStarHalfAlt/>:<FaStar/>}
{star>=2?<FaStar/>:star=2.5?<FaStarHalfAlt/>:<FaStar/>}
{star>=3?<FaStar/>:star=3.5?<FaStarHalfAlt/>:<FaStar/>}
{star>=4?<FaStar/>:star=4.5?<FaStarHalfAlt/>:<FaStar/>}
{/* {star=5?<FaStar/>:star=4.5?<FaStarHalfAlt/>:<FaStar/>} */}
</div>

</>
  )
}

export default Star