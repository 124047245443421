import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { productData } from '../../Redux/Action/listProductAction';
import { NavLink } from 'react-router-dom'
import { Rating } from "@material-ui/lab";
const FilterProductDetails = () => {
    const {filterdProductDetail}=useSelector(state=>state.productsFilterReducer)
    console.log(filterdProductDetail,'filteration');
    const dispatch=useDispatch()
    // useEffect(() => {
    //    dispatch(productData())
    // }, [dispatch])
    const options = {
        // size: "large",
        // name:'simple-controlled',
        readOnly: false,
        // precision: 0.5,
        color: "red",
        activeColor: "green",
        isHalf: true,
      };

  return (
    <>
  <div className="container">
    <div className="row">
    {
    filterdProductDetail && filterdProductDetail.map((item,i)=>{
      const {_id,productPictures,name}=item
      return(
        <>
   
   <div
                        className="col-lg-3 col-md-6 fw-bold main-div bg-light m-2 text-center"
                        key={i}
                        style={{ width: "18rem", height: "25rem", border:'5px solid white' }}
                      >
                         <h3 className="w-100 name">{name}</h3>
                         <NavLink to={`/products/new/${_id}`}>
                            <img
                              src={`/uploads/products/${productPictures[0].img}`}
                              className="productImage"
                                                       />
                          </NavLink>
  <div className='price'> Rs:{item.price}/-</div>
<Rating className='text-center' value={item.ratings} {...options}/>
<h6>more options</h6>
  <div className="card-body">
    
  </div>
   
                           
  </div>



        </>
    )})
 }
  
    </div>
  </div>
    
    </>
  )
}

export default FilterProductDetails