import React, { useEffect, useState } from 'react'
import {useFormik,} from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';

import { useAlert } from 'react-alert';
import MetaData from '../layout/MetaData';
import Swal from 'sweetalert2'
import { addBannerAction } from '../../Redux/Action/bannerAction';
import Sidebar from '../DashBoard/Sidebar';
import Loader from '../layout/Loader/Loader';
const AddBanner = () => {
    const  {userDetails}= useSelector((state) => state.userLoadReducer);
    const  {banner,loading,success}= useSelector((state) => state.postBannerReducer);
    console.log(banner,loading,success,'banner,loading,success');
 
// console.log(category,'category',success,'success',message,'messag');
    const [preview, setPreview] = useState('')
    const [fileName, setFileName] = useState('')
    const [user, setUser] = useState(userDetails._id)
const dispatch=useDispatch()
const alert=useAlert()
    
    // console.log(userDetails._id,'user details,reducers');
    const {handleBlur,handleReset,handleSubmit,setFieldValue,handleChange,values,touched,errors}=useFormik({
        initialValues:{
         name:'',bannerImage:''
          },
          validationSchema:Yup.object().shape({
     name:Yup.string().min(3).max(50).required('please enter image name'),
        bannerImage:Yup.string().required('image is required')
               
        }),
         onSubmit:(values)=>{
        //  console.log(values,fileName,user,'val');
          dispatch(addBannerAction({values,fileName,user}))
           
            }})
            const handleImage=(e)=>{
              let reader= new FileReader();
                  reader.onload=(e)=>{
          if(reader.readyState===2){
                  setPreview(reader.result)
                  setFieldValue('bannerImage',(reader.result));}
              }
              if(e.currentTarget.files[0].size>300000){
              
        alert.error('size limit increased')
        return;
              }else{
                reader.readAsDataURL(e.currentTarget.files[0]) 
              }
              setFileName(e.target.files[0].name)
            }
            useEffect(() => {
          if(success===true && loading===false){
            // alert.success('category has been created successfully')
            Swal.fire({
              icon:'success',
              title: "success",
              text: "banner uploaded",
              timer:3000
            });
          }
          if(success===false && loading===false){
            alert.error('banner not created')
          }
            }, [alert,success,loading])
            
  return (
  <>
 <MetaData title={`add banner`} />
 {
  loading?<Loader/>:(
    
 <div className="container-fluid mt-5 h-auto">
 <div className="row">
   <div className="col-lg-2 col-sm-12 col-md-2 bg-transparent"><Sidebar/></div>
   <div className="col-lg-10 col-sm-12 col-md-10">
<section className="register">
 <div className="container h-100">
   <div className="row d-flex justify-content-center align-items-center h-100">
       <div className="col-lg-12 col-xl-11" >
       <div className="card text-black w-100" style={{background:'none',border:'none'}}>
         <div className="card-bo p-md-5">
           <div className="row justify-content-center">
             <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">

               <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Add banner</p>
     <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>

     <div className="d-flex flex-row align-items-center mb-4">
                <div className='col-3'> <label className="form-label" for="form3Example1c">Image Name</label></div>
                             <div className="form-outline flex-fill mb-0">
                     <input type="text" className="form-control ms-3" placeholder='Full Name'
                onChange={handleChange}  onBlur={handleBlur} name='name' />
                     <div className='error' style={{color:'red'}}>
                       {touched.name && errors.name ? errors.name:''}
                     </div>
                   </div>
                 </div>


<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'><label className="form-label" for="form3Example3c">upload Image</label>
                 </div>
                   <div className="form-outline flex-fill mb-0">
                   <input type="file" className="form-control ms-3"                   
                   onChange={handleImage}
                     
                   />
                     <div className='error' style={{color:'red'}}>
                       {touched.bannerImage && errors.bannerImage ? errors.bannerImage:''}
                     </div>
                 
                   
                   </div>
                 </div>
          
                <div className='text-center mb-3'> {preview!==''?<img src={preview}/>:<h6>no picture found</h6>}
                </div>
                <button type="submit" className="btn btn-primary btn-lg">Upload</button>
</form>
</div></div></div></div></div></div></div></section></div></div></div>
  )
 }

  </>
  )
}

export default AddBanner