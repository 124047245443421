import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ADD } from '../../Redux/Action/cartAction';
import '../Products/products.css'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { FaShoppingCart, FaRegBookmark, FaStar, FaFireAlt } from 'react-icons/fa';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import './category.css'
import  Slider  from '@mui/material/Slider';
import { pricefilter } from '../../Redux/Action/priceFilter';
import { subCategory } from '../../Redux/Action/subCategoryAction';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { listProductDetails } from '../../Redux/Action/productDetailsAction';
import { getFilterProductsByCategory, getFilterProductsBySubCategory, getProduct } from '../../Redux/Action/listProductAction';
import MetaData from '../layout/MetaData';
import ProductsProps from '../Props/ProductsProps';
import colorData from '../Products/ColorData';
const GetSubCategory = () => {
  const params=useParams()
  // console.log(params,'params');
    const {getSubcategory}= useSelector((state) => state.getSubCategoryReducer);
    const  {filterProductByCategory,filterProductBySubCategory}= useSelector((state) => state.productsFilterReducer);
    // console.log(getSubcategory,'get sub category');
    const { products } = useSelector((state) => state.productsReducer);
    console.log(filterProductByCategory?.length,'filter product length');
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const [value, setValue] = useState(false)
   
    const [ram, setRam] = useState('')
    // console.log(ram,'rmmmmmmmmmmmmmmmmmmmmm');
    const [processor, setProcessor] = useState(products)
    const [weight, setWeight] = useState(filterProductByCategory)
    const [color, setColor] = useState(filterProductByCategory)
    const [star, setStar] = useState(filterProductByCategory)
    const [camera, setCamera] = useState(filterProductByCategory)
    const [brand, setBrand] = useState(filterProductByCategory)
    const [memory, setMemory] = useState(filterProductByCategory)
    const [size, setSize] = useState(filterProductByCategory)
        const {filteredprice}=useSelector(state=>state.filterPrice)
      console.log(filteredprice);
 let min=filteredprice[0]
 let max=filteredprice[1]

 const sizeFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.size!==''?item.size:null}))]
 const cameraFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.camera!==''?item.camera:null}))]
 const colorFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.color!==''?item.color:null}))]
 const weightFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.weight!==''?item.weight:null}))]
 const brandFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.brand!==''?item.brand:null}))]
 const starFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.star!==''?item.star:null}))]
 const ramFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.ram!==''?item.ram:null}))]
 const memoryFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.memory!==''?item.memory:null}))]
 const processorFilter=[...new Set(filterProductByCategory?.map((item)=>{return item?.processor!==''?item.processor:null}))]
     const handleChange = (event, newValue) => {
      dispatch(pricefilter(newValue));
    };
    const handleGetColor=(e)=>{
      // console.log(e.target.value,'color coloe');
      setColor(e.target.value)
      setValue(false?true:false)
            }
    const handleGetSize=(e)=>{
      // console.log(e.target.value,'color coloe');
      setSize(e.target.value)
            }
            console.log(color,'color');
    const handleClick=(e)=>{
      const handleName=e.categoryName.split(' ')
      console.log(handleName,'handle name');
      navigate(`/product/detail/${e._id}/${e.categoryName}`)
     }
     const handleFilterByRam=(e)=>{
      // console.log(e,'e target name');
setRam(e)
     }
  
     const handleRam=()=>{
     filterProductByCategory?.filter((item)=>       
      item.ram===ram
      )
      // console.log(abc,'abc');
     }
     const handleFilterBySize=(e)=>{
setSize(e)
     }
     const handleFilterByColor=(e)=>{
setColor(e)
     }
     const handleFilterByWeight=(e)=>{
setWeight(e)
     }
     const handleFilterByMemory=(e)=>{
setMemory(e)
     }
     const handleFilterByBrand=(e)=>{
setBrand(e)
     }
     const handleFilterByProcessor=(e)=>{
setProcessor(e)
     }
     const handleFilterByCamera=(e)=>{
setCamera(e)
     }
     const handleFilterByStar=(e)=>{
setStar(e)
     }
     
  return (
   <>
    


{/* main categories products */}
<MetaData title={`Products`} />

        <div className="main-product-div">

<div className="filter">


<div className="sidebar-filter text-center text-capitalize boxsizing">
               <div className="text-center price-filter w-100 mt-1">Price Filter</div>
          <Slider
            style={{ width: "80%" }}
            getAriaLabel={() => "Temperature range"}
            value={filteredprice}
            aria-label="Always visible"
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={0}
            max={1000000}
          />
                            </div>
        <div className='filterCategory'>
     {ramFilter.length>1?<div>Ram{
     ramFilter?.map((item)=>{
       return(<>
      
         {/* <li onClick={(e)=>console.log(e.target.innerText,'e target')}>{item}</li> */}
         <li onClick={(e)=>handleFilterByRam(e.target.innerText)}>{item}</li>
         </>) 
     })}
 
 </div>:null}
 </div>                    
        <div className='filterCategory'>

         {sizeFilter.length>1?<div>Size{
        sizeFilter?.map((item)=>{
          return(<>
            <li onClick={(e)=>handleFilterBySize(e.target.innerText)}>{item}</li>
            </>) 
        })
       }</div>:null}
 </div>                    
 <div className='filterCategory'>
 {processorFilter.length>1?<div>Processor{
        processorFilter?.map((item)=>{
          return(<>
            <li onClick={(e)=>handleFilterByProcessor(e.target.innerText)}>{item}</li>
            </>) 
        })
      }</div>:null}
 </div>  
                    
        <div className='filterCategory'>
          {/* {cameraFilter.length} */}
        {cameraFilter.length>2?<div>Back Camera{
        cameraFilter?.map((item)=>{
              return(<>
                 <li onClick={(e)=>handleFilterByCamera(e.target.innerText)}>{item}</li>
              </>)
              
            })
          }</div>:null}
          </div>       
        <div className='filterCategory'>
        {weightFilter.length>1?<div>Weight{
        weightFilter?.map((item)=>{
              return(<>
                     <li onClick={(e)=>handleFilterByWeight(e.target.innerText)}>{item}</li>
              </>)
              
            }) }</div>:null}
          </div>       
        <div className='filterCategory'>
        {memoryFilter.length>1?<div>Memory{
        memoryFilter?.map((item)=>{
              return(<>
                   <li onClick={(e)=>handleFilterByMemory(e.target.innerText)}>{item}</li>
              </>)
              
            }) }</div>:null}
          </div>       
        <div className='filterCategory'>
        {brandFilter.length>1?<div>Brand{
        brandFilter?.map((item)=>{
              return(<>
                   <li onClick={(e)=>handleFilterByBrand(e.target.innerText)}>{item}</li>
              </>)
              
            })}</div>:null}
          </div>  
        <div className='filterCategory'>
        {starFilter.length>1?<div>Rating{
        starFilter?.map((item)=>{
              return(<>
                   <li onClick={(e)=>handleFilterByStar(e.target.innerText)}>{item}</li>
              </>)
              
            })}</div>:null}
          </div>  
                            </div>

<div className="container-div-products">
  {/* {products?.length} */}
  {
    filterProductByCategory?.length>0?
    filterProductByCategory?.filter((item)=>
      item?.salePrice >= min && item?.salePrice <= max)
    .filter((item)=>
   item.ram===ram ||

    item?.processor===processor ||
    item?.size===size ||
    item?.weight===weight ||
    item?.memory===memory 

      )
      .map((item, i) => {
                    const {
                      _id,
                      productPictures,
                      name,
                      categoryName,
                      discountInPercent,
                      sellingPrice,
                      salePrice,
                      createdAt,
                      size
                    } = item;
    
                    return (
                      <>
                        {/* <Link > */}
                        
                        <ProductsProps 
                        key={i}
                       fileName={<img src={`/uploads/products/${item.productPictures[0]?item.productPictures[0].img:null}`}  alt='' />}
                            name={name}
                          salePrice={salePrice}
                          sellingPrice={sellingPrice}
                          discountInPercent={discountInPercent}
                          _id={_id}
                          categoryname={categoryName}
                          productPictures={productPictures}
                          size={size}
                        />
                      </>
                    );
        }):'helllo'
    }
  




</div>

</div>
     
   </>
  )
}

export default GetSubCategory