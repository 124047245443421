import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './AllCat.css'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { deepSubCategory, subCategory } from '../../../Redux/Action/subCategoryAction'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import { getAllCategory, getSubCategory } from '../../../Redux/Action/catgoryAction'
import { getFilterProductsByCategory, getFilterProductsBySubCategory } from '../../../Redux/Action/listProductAction'
import { useAlert } from 'react-alert'
const GetMainCategories = () => {
  const alert=useAlert()


  const  {category}= useSelector((state) => state.getCategoryReducer);
 
   const params=useParams()
   const dispatch=useDispatch()
   const navigate=useNavigate()
useEffect(() => {
dispatch(getSubCategory())
dispatch(getAllCategory())
dispatch(deepSubCategory())
}, [dispatch])


    const handleclick=(value)=>{
    // console.log(value,'value');
        setTimeout(() => {
          dispatch(getSubCategory(value))
          dispatch(getFilterProductsByCategory(value))
       
        },0)}
const handleName=(e)=>{
  // <Link to={`/category/${name}`}>
navigate(`/category/${e}`)
}

  return (
 <>

    <div className="maincategory boxsizing">
    {/* <Slider {...settings}> */}
    {
category && category?.map((item,i)=>{
    const {_id,name,fileName}=item
    return(
        <>
  <div className="card1" onClick={()=>{handleclick(_id);handleName(name)}}>
 
    <div className="category-images">
    <img className="card-img-top1 object-fit-cover  mx-auto mt-2" 
  src={`/uploads/categories/${fileName&&fileName}`} 
  alt={name}/>
    </div>
    <div className="cat-text">
{item.name?item.name.substring(0,25):'sello'}
</div>

</div>

</>
    )

})}
{/* <hr/> */}
    </div>


 </>
  )
}

export default GetMainCategories