import React, { useEffect, useState } from 'react'
import Sidebar from '../DashBoard/Sidebar'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryDetails, updateCategories } from '../../Redux/Action/catgoryAction'
import MetaData from '../layout/MetaData'
import { useAlert } from 'react-alert'

const UpdateCategory = () => {
    const {category,}=useSelector(state=>state.getCategoriesDetailsReducer)
    const {updateCategory,success,loading}=useSelector(state=>state.updateCategoriesReducer)
    console.log(success,loading,'updateCategory,loading,success');
// console.log(loading,success);
const {id}=useParams()
const alert=useAlert()
const dispatch=useDispatch()
    const [preview, setPreview] = useState(category.fileName)
    const [fileName, setFileName] = useState(category.fileName)
    const [name, setName] = useState(category.name)
    console.log(name,'nme');
    const [image, setImage] = useState('')

    const handleImage=(e)=>{
        let reader= new FileReader();
            reader.onload=(e)=>{
    if(reader.readyState===2){
            setPreview(reader.result)
            setImage(reader.result)}
        }
        if(e.currentTarget.files[0].size>300000){
        
  alert.error('size limit increased')
  return;
        }else{
          reader.readAsDataURL(e.currentTarget.files[0]) 
        }
        setFileName(e.target.files[0].name)
      }
      useEffect(() => {
if(loading===false && success===true){
  alert.success('Category Updated')
}
if(loading===false && success===false){
  alert.error('Category did not Update')
}
     dispatch(getCategoryDetails(id))
     
      }, [dispatch,alert,id,loading,success])

      useEffect(() => {
        setName(category.name)
        setFileName(category.fileName)
        setPreview(category.fileName)
      }, [category.name,category.fileName])

      const handleClick=async()=>{
        dispatch(updateCategories({name,image,fileName,id}))
      }
      
  return (
<>
<MetaData title={`Update Category`} />
{/* copy area starts */}
<div className="container-fluid">
  <div className="row d-flex flex-row">
 <div className="col-lg-2"><Sidebar/></div>
 <div className="col-lg-10">
<section className="register">
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-12 col-xl-11" >
        <div className="card text-black w-100" style={{background:'none',border:'none'}}>
          <div className="card-bo p-md-5">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">

                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">update category</p>
{/* copy area end */}

<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Catgeory Name</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='Full Name'
                     value={name} onChange={(e)=>setName(e.target.value)}
                  name='name' />
                  
                    </div>
                  </div>


<div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">upload Image</label>
                  </div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="file" className="form-control ms-3"                   
            //    onChange={handleImage}
               onChange={handleImage}
                    />
                
                  
                    
                    </div>
                  </div>
           
                  <div className="row bottom-images-div d-flex flex-row">
    
     
     <div className='text-center m-2'>
                    Old Image: {<img src={`/uploads/categories/${category.fileName && category.fileName}`}
                  alt={fileName}  style={{height:'75px',width:'100px'}}/>
                 
                  }
                 </div>
     <div className='text-center m-2'> {preview!==''?<img src={preview}style={{height:'75px',width:'100px'}}/>:<h6>no picture found</h6>}</div>
      </div>

                 <button type="submit" className="btn btn-primary btn-lg" onClick={handleClick}>Upload</button>




                </div></div></div></div></div></div></div></section></div></div></div>

</>
  )
}

export default UpdateCategory
