import axios from "axios";
import { ALL_CAT, CAT_REQUEST, SEND_FILTER, SUB_CAT,CHILD_SUB_CAT, SUB_SUB_CAT } from "../Constant/categoryConstant";

export const subCategory=(data)=>(dispatch)=>{
  console.log(data,'data');
dispatch({ type: SUB_CAT, payload: data });

dispatch({ type: SEND_FILTER, payload: data });
}
export const finalsubCategory=(data)=>(dispatch)=>{
  console.log(data,'data');
dispatch({ type: 'FINAL_CATEGORY', payload: data });


}

export const childSubCategory=(data)=>(dispatch)=>{
  console.log(data,'data');
  dispatch({type:'CHILD_SUB_CATEGORY',payload:data})
}


export const finalSubCategory=(data)=>(dispatch)=>{
  console.log(data,'data 1 1 1 ');
   dispatch({   type:SEND_FILTER, payload:data})
console.log(data,'Children Sub Category');
}
export const deepSubCategory=()=>async(dispatch)=>{
  dispatch ({type:CAT_REQUEST})
  const {data} = await axios.get(`/category/child`);
  // console.log(data,'data category');
  const category=(data.data)
  // console.log(category,'subcate category'); 
  
      dispatch({ type: ALL_CAT, payload: category});} 