import {
    CREATE_ORDER_REQUEST,
    SINGLE_ORDER_FAIL,SINGLE_ORDER_REQUEST,SINGLE_ORDER_SUCCESS,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    MY_ORDERS_REQUEST,
    MY_ORDERS_SUCCESS,
    MY_ORDERS_FAIL,
    ALL_ORDERS_REQUEST,
    ALL_ORDERS_SUCCESS,
    ALL_ORDERS_FAIL,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    UPDATE_ORDER_RESET,
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_FAIL,
    DELETE_ORDER_RESET,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    CLEAR_ERRORS,
  } from "../Constant/orderConstant";
  
  export const newOrderReducer = (state = {newOrder:{}}, action) => {
    switch (action.type) {
      case CREATE_ORDER_REQUEST:
        return {
          loading: true,
          ...state,
          
        };
  
      case CREATE_ORDER_SUCCESS:
        return {
          loading: false,
          success:true,
          newOrder: action.payload,
        };
  
      case CREATE_ORDER_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const myOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case MY_ORDERS_REQUEST:
        return {
          loading: true,
          ...state,
        };
  
      case MY_ORDERS_SUCCESS:
        return {
          loading: false,
          orders: action.payload,
        };
  
      case MY_ORDERS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const allOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case ALL_ORDERS_REQUEST:
        return {
          loading: true,
        };
  
      case ALL_ORDERS_SUCCESS:
        return {
          loading: false,
          orders: action.payload,
        };

      case ALL_ORDERS_FAIL:
        console.log(state,'order states');
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const orderReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_ORDER_REQUEST:
      case DELETE_ORDER_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case UPDATE_ORDER_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
  
      case DELETE_ORDER_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_ORDER_FAIL:
      case DELETE_ORDER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case UPDATE_ORDER_RESET:
        return {
          ...state,
          isUpdated: false,
        };
  
      case DELETE_ORDER_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const orderDetailsReducer = (state = {order:[]}, action) => {
    switch (action.type) {
      case ORDER_DETAILS_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case ORDER_DETAILS_SUCCESS:
        return {
          loading: false,
          order: action.payload,
         
        };
        
  
      case ORDER_DETAILS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  export const singleOrderReducer = (state = {singleOrder:{}}, action) => {
    switch (action.type) {
      case SINGLE_ORDER_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case SINGLE_ORDER_SUCCESS:
        return {
          loading: false,
          singleOrder: action.payload,
         
        };
        
  
      case SINGLE_ORDER_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const orderFilterReducer = (state = {filterOrders:[],orders:[]}, action) => {
    switch (action.type) {
      case ALL_ORDERS_SUCCESS:
          

        return {
          loading: false,
          orders: action.payload,
          
        };
  
      case 'FILTER_ORDER_SUCCESS':
        console.log(action.payload.startDates,'orders order order');
let x=[]
const orders=state.orders
// x=orders.filter((item)=>console.log(item.creationDate,'creation date',action.payload.startDates,'endDates'
x=orders.filter((item)=>item.creationDate<='30-3-2024')


console.log(x,'xxxxxxxxxxxxxxxxx');
// x=orders.filter((item)=>item.totalPrice>=action.payload.clickorder && item.taxPrice>=action.payload.clickTax
// &&
// console.log(item.creationDate.toLocaleDateString()>=action.payload.startDate.toLocaleDateString(),'first date'
// && item.creationDate.toLocaleDateString()<=action.payload.endDate.toLocaleDateString(),'secon date'
// ))
        return {
          ...state,
          loading: false,        
          filterOrders:x
        };
        
  
      default:
        return state;
    }
  };
  