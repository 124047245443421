import React, { useState } from "react";
import "../Props/ProductProps.css";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import MetaData from "../layout/MetaData";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { pricefilter } from "../../Redux/Action/priceFilter";
import Slider from "@mui/material/Slider";

import ProductsProps from "../Props/ProductsProps";

const { RangePicker } = DatePicker;
const Products = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { products } = useSelector((state) => state.productsReducer);
  console.log(products);
  const { filteredprice } = useSelector((state) => state.filterPrice);
  let [startDates, setStartDates] = useState();
  let [endDates, setEndDates] = useState();
  let [dates, setDates] = useState([]);
  let [color, setColor] = useState('')
  let [ram, setRam] = useState('1 GB')
  let [duration,setDuration]=useState(['04-04-1950', '04-04-2050'])
  console.log(duration,'duration');
  // let [nameFilter, setNameFilter] = useState('')
  console.log(color,'color');
  const [value, setValue] = useState(false)

  // console.log(startDates,'start date',endDates,'end date');
  let min = filteredprice[0];
  let max = filteredprice[1];

  const handleChange = (event, newValue) => {
    dispatch(pricefilter(newValue));
  };
//   const handleGetValue=(e)=>{
// setNameFilter(e.target.value)
//   }
// if(color===''){
// setColor(products)
// return;
// }

  duration=duration!==''.split(',')
  console.log(duration,'duration');
  let startDate=duration[0]
  let endDate=duration[1]
    console.log(startDate,'start date',endDate,'enddate');
  

const filterByDate=products.filter((item)=>{
  let {createdAt}=item
createdAt=createdAt.split('T')
createdAt=createdAt[0]
return createdAt>=startDates && createdAt <= endDates
// console.log(createdAt,'created At',startDates,'start date');
})
console.log(filterByDate,'filter by date');
const colorfilter=[...new Set(products.map((item)=>{return item.color}))]
// console.log(colorfilter,'color filter');
const sizeFilter=[...new Set(products.map((item)=>{return item?.size}))]
const ramFilter=[...new Set(products.map((item)=>{return item?.ram}))]
// console.log(ramFilter,'ram filter');
const processorFilter=[...new Set(products.map((item)=>{return item?.processor}))]
const cameraFilter=[...new Set(products.map((item)=>{return item?.camera}))]
const memoryFilter=[...new Set(products.map((item)=>{return item?.memory}))]

const handleDatePicker=()=>{

}

  return (
    <>
      <MetaData title={`Products`} />

        <div className="main-product-div">

<div className="filter">
{/* <RangePicker onChange={(values)=>(
                setDuration(values.map(item=>{
                
return item.format('DD-MM-YYYY')
                }
  )))}/> */}
  {/* <input type='date' onChange={(e)=>setStartDates(e.target.value)}/> */}
  {/* <input type='date' onChange={(e)=>setEndDates(e.target.value)}/> */}

<div className="sidebar-filter text-center text-capitalize boxsizing">
               <div className="text-center price-filter w-100 mt-1">Price Filter</div>
          <Slider
            style={{ width: "80%" }}
            getAriaLabel={() => "Temperature range"}
            value={filteredprice}
            aria-label="Always visible"
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={0}
            max={10000}
          />
      
                            </div></div>

<div className="container-div-products">

  {products &&
            products
              .filter((item) => item.salePrice >= min && item.salePrice <= max )           
              .map((item, i) => {
                         return (
                  <>
                    {/* <Link > */}
                    
                    <ProductsProps 
                    key={i}
             fileName=
             {<img src={`/uploads/products/${item.productPictures[0]?item.productPictures[0].img:null}`}  alt='' />}
                    name={item.name}
                    salePrice={item.salePrice}
                     sellingPrice={item.sellingPrice}
                     star={item.star}
                     productPictures={item.productPictures}
                     _id={item._id}
                    />
                  </>
                );
    })
}




</div>

</div>

    </>
  );
};

export default Products;
