import React from 'react'
// import Star from './Star'
import './ProductProps.css'
import { redirect, useNavigate } from 'react-router-dom'
import {ADD } from "../../Redux/Action/cartAction";
import { useDispatch, useSelector } from 'react-redux';
const ProductsProps = (props) => {
const {productPictures,name,salePrice,sellingPrice,fileName,_id}=props
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const handleClick=(e)=>{
    // console.log(e,'eeeeeeeeeeeeeeeeeee');
navigate(`/product/detail/${e}`)
  }
  const addToCartHandler = ({quantity,name,productPictures,salePrice,_id}) => {
    dispatch(ADD({quantity:1,name,productPictures,salePrice,_id}));

  };
  const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer)
  const buyNow=()=>{
    if(!userDetails){
      navigate('/user/login')
      if(userDetails){
        redirect('/shipping')
      }
    }
    else{
      navigate('/shipping')
    }
  }
  return (
   <>
   <div className="products-map-inner-div">
    {/* <div className="div-test">test</div> */}
                <div className='products-first-div'>
          <div className="products-image-div" onClick={()=>handleClick(_id)}>
          {fileName}
          {/* {console.log(productPictures,'ppppppppppp')} */}
           {/* {fileName2} */}
           </div>
            <div className="products-name-div" style={{textTransform:'capitalize'}}>
            {name?.length>=60?<p>{name?.substring(0,60)}...</p>:name}
            </div>
           
            <div className="products-price-div">
                <div className="products-newPrice">Rs:{salePrice}/-</div>
                <div className="products-oldPrice text-danger">{salePrice!==sellingPrice?<del>Rs.{sellingPrice}/-</del>:null}</div>
                
            </div>
<div className="products-star-div">
{/* <div>{item?.size} size</div> */}
</div>
<div className="products-buynow">
<div className="products-buyNow"
 onClick={(e)=>{addToCartHandler({productPictures,name,salePrice,sellingPrice,_id});buyNow()}}
 >Buy Now</div>
<div className="products-addtocart"
onClick={(e)=>{addToCartHandler({productPictures,name,salePrice,sellingPrice,_id})}}>Add To Cart</div>


</div>


            </div>
            </div>
            {/* second div */}
            {/* <div className="second-div" style={{width:'50px'}}>
            {discountInPercent>0?<span>{discountInPercent}%</span>:null}
            </div> */}
          
   
   
   </>
  )
}

export default ProductsProps