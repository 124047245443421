export const POST_OFFER3_REQUEST = "GET_OFFER3_REQUEST";
export const POST_OFFER3_SUCCESS = "GET_OFFER3_SUCCESS";
export const POST_OFFER3_FAIL = "GET_OFFER3_FAIL";

export const POST_OFFER1_REQUEST = "GET_OFFER1_REQUEST";
export const POST_OFFER1_SUCCESS = "GET_OFFER1_SUCCESS";
export const POST_OFFER1_FAIL = "GET_OFFER1_FAIL";

export const POST_OFFER2_REQUEST = "GET_OFFER2_REQUEST";
export const POST_OFFER2_SUCCESS = "GET_OFFER2_SUCCESS";
export const POST_OFFER2_FAIL = "GET_OFFER2_FAIL";

export const GET_OFFER3_REQUEST = "GET_OFFER3_REQUEST";
export const GET_OFFER3_SUCCESS = "GET_OFFER3_SUCCESS";
export const GET_OFFER3_FAIL = "GET_OFFER3_FAIL";

export const GET_OFFER1_REQUEST = "GET_OFFER1_REQUEST";
export const GET_OFFER1_SUCCESS = "GET_OFFER1_SUCCESS";
export const GET_OFFER1_FAIL = "GET_OFFER1_FAIL";

export const GET_OFFER2_REQUEST = "GET_OFFER2_REQUEST";
export const GET_OFFER2_SUCCESS = "GET_OFFER2_SUCCESS";
export const GET_OFFER2_FAIL = "GET_OFFER2_FAIL";