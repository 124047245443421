import React, { Fragment, useEffect } from "react";
import { DataGrid,params } from '@mui/x-data-grid';
import "./myOrders.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, myOrders } from "../../Redux/Action/orderAction";
import Loader from "../layout/Loader/Loader";
import { Link, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/MetaData";
import LaunchIcon from "@material-ui/icons/Launch";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import axios from "axios";
const MyOrders = () => {
  const dispatch = useDispatch();
const {id}=useParams()
  const alert = useAlert();
useEffect(() => {
 dispatch(myOrders())
}, [dispatch])

  const { loading, error, orders } = useSelector((state) => state.myOrdersReducer);
  console.log(orders,'my orders');
  const  {userDetails}= useSelector((state) => state.userLoadReducer);
  const userFromStorage=localStorage.getItem("user")
  ?JSON.parse(localStorage.getItem("user")):null;
     const config={headers:{
      Authorization:`Bearer ${userFromStorage.token}`
  }}

  const handleDelete = async (id) => {
    await axios.delete(`/order/delete/${id}`,config)
      .then((res) => {
        alert.success("Order deleted Successfully");
      })};

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 300, flex: 1 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 150,
      flex: 0.3,
    },

    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      minWidth: 270,
      flex: 0.5,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        // console.log(params,'params');
        return (
          <Fragment>
            <Link to={`/order/${userDetails.userName}/${params.id}`}>
              <EditIcon style={{color:'green'}}/>
            </Link>

            <Button
              onClick={() =>handleDelete(params.id) }
            >
              <DeleteIcon style={{color:'red'}}/>
            </Button>
          </Fragment>
        );
      },
    },
  ];
  const rows = [];

  orders &&
    orders.forEach((item, index) => {
      rows.push({
        itemsQty: item.orderItems.length,
        id: item._id,
        status: item.orderStatus,
        amount: item.totalPrice,
      });
    });

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
handleDelete()
    dispatch(myOrders());
  }, [dispatch, alert, error]);

  return (
    <Fragment>
      <MetaData title={`${userDetails.name} - Orders`} />
    <div className="container-fluid mt-5">
    <div className="row">
    {loading ? (
        <Loader />
      ) : (
        <div className="myOrdersPage">
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="myOrdersTable"
            autoHeight
          /> 

          <Typography id="myOrdersHeading">{userDetails.name}'s Orders</Typography>
        </div>
      )}
   
      </div>

    </div>
    </Fragment>
      
  );
};

export default MyOrders;
