import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MetaData from '../layout/MetaData';
import { loadUser, updateProfile } from '../../Redux/Action/userAction';
import axios from 'axios';

const UpdateProfile = () => {
  const dispatch=useDispatch()
  const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer)
  console.log(userDetails.fileName,'user details');
  const [name, setName] = useState(userDetails.name)
  const [userName, setUserName] = useState(userDetails.userName)
  const [address, setAddress] = useState(userDetails.address)
  const [phoneNumber, setPhoneNumber] = useState(userDetails.phoneNumber)
  const [gender, setGender] = useState(userDetails.gender)
  const [email, setEmail] = useState(userDetails.email)
  const [fileName, setFileName] = useState(userDetails.fileName)
  const [shopName, setShopName] = useState(userDetails.shopName)
  const [preview, setPreview] = useState('')
  const {_id}=userDetails
  // const [oldImage, setOldImage] = useState(userDetails.fileName)
  const [fieldValue,setFieldValue] = useState('')
  const [age, setAge] = useState([userDetails.age])
const id=userDetails._id
const handleSubmit=()=>{
  const formData=new FormData()
  formData.append('name',name)
  formData.append('userName',userName)
  formData.append('shopName',shopName)
  formData.append('address',address)
  formData.append('email',email)
  formData.append('age',age)
  formData.append('gender',gender)
  formData.append('fileName',fileName)
  // formData.append('fieldValue',fieldValue)
  formData.append('phoneNumber',phoneNumber)
  dispatch(updateProfile({fieldValue,name,userName,shopName,address,email,age,gender,fileName,id}))
}
 
  // console.log(userDetails,'user details');

  useEffect(() => {
 dispatch(loadUser())
  }, [dispatch])
  
  return (
    <>
    <MetaData title={`update profile`} />
 <div className="container-fluid mt-5 h-auto">
  <div className="row">
    <div className="col-lg-2 col-sm-12 col-md-2 bg-transparent">side bar</div>
    <div className="col-lg-10 col-sm-12 col-md-10">
<section className="register">
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-12 col-xl-11" >
        <div className="card text-black w-100" style={{background:'none',border:'none'}}>
          <div className="card-bo p-md-5">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">

                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">update Profile</p>
     
<input type='text' value={name} onChange={(e)=>setName(e.target.value)}/>
<input type='text' value={userName} onChange={(e)=>setUserName(e.target.value)}/>
<input type='text' value={shopName} onChange={(e)=>setShopName(e.target.value)}/>
<input type='email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
<input type='text' value={address} onChange={(e)=>setAddress(e.target.value)}/>
<input type='number' value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)}/>
<select  className="ms-3" value={gender} onChange={(e)=>setGender(e.target.value)}>
                    <option value="">{gender}</option>
                    <option value="feMale">Female</option>
                    <option value="Male">Male</option>
               
                    <option value="Not Mention">Not Recmonded</option>
                                   </select>
<input type='date' value={age} onChange={(e)=>setAge(e.target.value)}/>

<input type="file" className="form-control ms-3"                   
                    onChange={(e)=>{
                      let reader= new FileReader();
                       reader.onload=(e)=>{
                  if(reader.readyState===2){
                          setPreview(reader.result)
                          setFieldValue(reader.result)}
                      }
                      reader.readAsDataURL(e.currentTarget.files[0]) 
                      setFileName(e.target.files[0].name)
                      }}
                    />
                   <h1>old image</h1>
                   <img src={`/uploads/userprofile/${userDetails.fileName}`}/>
                   
                   {preview!==''?<img src={preview} style={{height:'75px',width:'75px',borderRadius:'20px'}}/>:<h6>no picture found</h6>}
                
<button type='submit' className='btn btn-primary' onClick={handleSubmit}>submit</button>
                  </div></div></div></div></div></div></div></section></div></div></div>
    
    </>
  )
}

export default UpdateProfile