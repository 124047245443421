import React, { useState } from "react";
import "../Props/ProductProps.css";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import MetaData from "../layout/MetaData";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { pricefilter } from "../../Redux/Action/priceFilter";
import Slider from "@mui/material/Slider";

import ProductsProps from "../Props/ProductsProps";

const { RangePicker } = DatePicker;
const SearchResult = () => {
  const {keyword}=useParams()
  console.log(keyword,'keyword');
// const [keywords, setKeywords] = useState(keyword)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { products } = useSelector((state) => state.productsReducer);
  // console.log(products);
  const { filteredprice } = useSelector((state) => state.filterPrice);
  let [startDates, setStartDates] = useState();
  let [endDates, setEndDates] = useState();
  let [dates, setDates] = useState([]);
  let [color, setColor] = useState('')
  let [ram, setRam] = useState('1 GB')

  const [value, setValue] = useState(false)
  startDates = dates[0];
  endDates = dates[1];

  let min = filteredprice[0];
  let max = filteredprice[1];

  const handleChange = (event, newValue) => {
    dispatch(pricefilter(newValue));
  };
const colorfilter=[...new Set(products.map((item)=>{return item.color}))]
const sizeFilter=[...new Set(products.map((item)=>{return item?.size}))]
const ramFilter=[...new Set(products.map((item)=>{return item?.ram}))]
// console.log(ramFilter,'ram filter');
const processorFilter=[...new Set(products.map((item)=>{return item?.processor}))]
const cameraFilter=[...new Set(products.map((item)=>{return item?.camera}))]
const memoryFilter=[...new Set(products.map((item)=>{return item?.memory}))]


  return (
    <>
      <MetaData title={`Products`} />

        <div className="main-product-div">

<div className="filter">


<div className="sidebar-filter text-center text-capitalize boxsizing">
               <div className="text-center btn btn-danger price-filter w-100 mt-1">Price Filter</div>
          <Slider
            style={{ width: "80%" }}
            getAriaLabel={() => "Temperature range"}
            value={filteredprice}
            aria-label="Always visible"
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={0}
            max={100000}
          />
                            </div></div>

<div className="container-div-products">
  {/* {products?.length} */}
  {products &&
            products
              .filter((item) => item.name?.toLowerCase().includes(`${keyword?.toLowerCase()}`) ||
              item.categoryName?.toLowerCase().includes(`${keyword?.toLowerCase()}`) ||
              item.brand?.toLowerCase().includes(`${keyword?.toLowerCase()}`))           
              .map((item, i) => {
                const {
                  _id,
                  productPictures,
                  name,
                  categoryName,
                  discountInPercent,
                  sellingPrice,
                  salePrice,
                  createdAt,
                } = item;

                return (
                  <>
                    {/* <Link > */}
                    
                    <ProductsProps 
                    key={i}
                    productPictures={<img src={`/uploads/products/${item.productPictures[0]?item.productPictures[0].img:null}`}  alt='' />}
                        name={name}
                      salePrice={salePrice}
                      sellingPrice={sellingPrice}
                      discountInPercent={discountInPercent}
                      _id={_id}
                      categoryname={categoryName}
                    />
                  </>
                );
    })
}




</div>

</div>

    </>
  );
};

export default SearchResult;
