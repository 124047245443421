import { SEND_FILTER, SUB_CAT } from "../Constant/categoryConstant";
import { FILTER_PRODUCT_BYCATEGORY_SUCCESS, FILTER_PRODUCT_BYSUBCATEGORY_SUCCESS } from "../Constant/filterConstant";
import { GET_USER_PRODUCT_REQUEST } from "../Constant/getUserProduct";
import {
    ALL_PRODUCT_FAIL,
    ALL_PRODUCT_REQUEST,
    ALL_PRODUCT_SUCCESS,
     NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_SUCCESS,
    NEW_PRODUCT_FAIL,
    NEW_PRODUCT_RESET,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCT_RESET,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    DELETE_PRODUCT_RESET,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_SUCCESS,
    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_FAIL,
    NEW_REVIEW_RESET,
    ALL_REVIEW_REQUEST,
    ALL_REVIEW_SUCCESS,
    ALL_REVIEW_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    DELETE_REVIEW_RESET,
    CLEAR_ERRORS,
    ALL_PRODUCTS_FILTER_REQUEST,
    ALL_PRODUCTS_FILTER_SUCCESS,
  } from "../Constant/productConstants";

 
  export const productsFilterReducer = (state = { products: [],filterProductBySubCategory:[],
    filterProductByCategory:[],filterProductByuser:[]}, action) => {

    switch (action.type) {
      // case ALL_PRODUCTS_FILTER_REQUEST:
        // return {
        //   loading: true,
        //    products: [],
        //    filterProductBySubCategory:[],filterProductByCategory:[],filterProductByuser:[]
        
        
        
        // };
        case ALL_PRODUCT_SUCCESS:
          // console.log(action.payload,'dates');
          return {
            loading: false,
            products: action.payload,
           
          };
          case 'ADMIN_PRODUCT_REQUEST_BY_USER':
            case 'DATE_FILTER':
         case FILTER_PRODUCT_BYCATEGORY_SUCCESS:
         case FILTER_PRODUCT_BYSUBCATEGORY_SUCCESS:
          const products=state.products
          // console.log(products,'ppppproducts');
           
     
let z=[]
z=products.filter((item)=>item.parentId?._id===action.payload);
let x=[]
x=products.filter((item)=>item.user?._id===action.payload);
let y=[]
y=products.filter((item)=>item.category?._id===action.payload);
let dateFiltering=[]
dateFiltering=products.filter((item)=>{
let vv=item.createdAt
vv=vv.split('T')
vv=vv[0]
let newDate=new Date(vv)
let month=newDate.getMonth()+1
let day= newDate.getDate()
let year=newDate.getFullYear()
newDate=month+'-'+day+'-'+year
// payload date
let payloadDate=new Date(action.payload[0])
month=payloadDate.getMonth()+1
day= payloadDate.getDate()
year=payloadDate.getFullYear()
payloadDate=month+'-'+day+'-'+year
return(<>
{newDate>=payloadDate&&newDate===true}
</>)
  
});

   console.log(dateFiltering,'date filter');       
  
              return {
                ...state,             
                filterProductByCategory:y,
                filterProductBySubCategory:z,
                filterProductByuser:x
               
              }
                default:
              return state;}}

  
  export const productsReducer = (state = { products: [] }, action) => {
    switch (action.type) {
      case ALL_PRODUCT_REQUEST:
                 return {
            loading: true,
            products: [],
          };
        case ALL_PRODUCT_SUCCESS:
          return {
            loading: false,
            products: action.payload,
            productsCount: action.payload.productsCount,
            resultPerPage: action.payload.resultPerPage,
            filteredProductsCount: action.payload.filteredProductsCount,
          };
    
      case ALL_PRODUCT_FAIL:
     
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const newProductReducer = (state = { product: {} }, action) => {
    switch (action.type) {
      case NEW_PRODUCT_REQUEST:
        return {
          ...state,
          loading: true,success:false
        };
      case NEW_PRODUCT_SUCCESS:
        // console.log(action.payload);
        return {
          loading: false,
          success: true,
          product: action.payload.product,
          message:action.payload.message
        };
      case NEW_PRODUCT_FAIL:
        return {
          ...state,
          loading: false,
          success:false,
          error: action.payload,
        };
      case NEW_PRODUCT_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const productReducer = (state = {productUpload:{}}, action) => {
    
    switch (action.type) {
      case DELETE_PRODUCT_REQUEST:
      case UPDATE_PRODUCT_REQUEST:
        return {
          ...state,
          loading: true,
          success:false

        };
      case DELETE_PRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_PRODUCT_SUCCESS:
        console.log(action.payload,'apayloas');
        return {
          ...state,
          loading: false,
          success:true,
          message:action.payload.message,
          isUpdated: action.payload.success,
          productUpload:action.payload
        };
      case DELETE_PRODUCT_FAIL:
      case UPDATE_PRODUCT_FAIL:
        return {
          ...state,
          loading: false,
          success:false,
          error: action.payload,
        };
      case DELETE_PRODUCT_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case UPDATE_PRODUCT_RESET:
        return {
          ...state,
          isUpdated: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const productDetailsReducer = (state = { product: {} }, action) => {
    switch (action.type) {
      case PRODUCT_DETAILS_REQUEST:
        return {
          loading: true,
          ...state,
        };
      case PRODUCT_DETAILS_SUCCESS:
        // console.log(action.payload,'apppppppppppp');
        return {
          loading: false,
          product: action.payload,
         
        };
      case PRODUCT_DETAILS_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const newReviewReducer = (state = {}, action) => {
    switch (action.type) {
      case NEW_REVIEW_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_REVIEW_SUCCESS:
        return {
          loading: false,
          success: action.payload,
        };
      case NEW_REVIEW_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_REVIEW_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const productReviewsReducer = (state = { reviews: [] }, action) => {
    switch (action.type) {
      case ALL_REVIEW_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case ALL_REVIEW_SUCCESS:
        return {
          loading: false,
          reviews: action.payload,
        };
      case ALL_REVIEW_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export const reviewReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_REVIEW_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_REVIEW_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
      case DELETE_REVIEW_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DELETE_REVIEW_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
