import React, { Fragment, useEffect, useState } from "react";
import { DataGrid,params } from '@mui/x-data-grid';
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  clearErrors,
  getAdminProduct,
 
} from "../../Redux/Action/adminAction";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
// import { Avatar } from 'antd'
import {Avatar} from "@material-ui/core";
import { Button } from "@material-ui/core";
import MetaData from "../layout/MetaData";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SideBar from "../DashBoard/Sidebar";
import { DELETE_PRODUCT_RESET } from "../../Redux/Constant/productConstants";
import axios from "axios";
import { listProductDetails } from "../../Redux/Action/productDetailsAction";

const  ProductList = ({ history }) => {
  
  const [showHide, setshowHide] = useState('')
  const dispatch = useDispatch();
const navigate=useNavigate()
  const alert = useAlert();

  const {products,loading,success}=useSelector(state=>state.adminProductsReducer)
console.log(loading,success);
  const {users}=useSelector(state=>state.allUsersReducer)

  
 
   
  useEffect(() => {

    dispatch(getAdminProduct());
    listProductDetails()
  }, [dispatch, alert,history,]);

  const columns = [
    
    { field: "createdAt", headerName: "Date", minWidth: 200, flex: 0.5 },
    

    {
      field: "name",
      headerName: "name",
      minWidth: 250,
      flex: 1,
    },
   
    {
      field: "productPictures",
      headerName: "Products",
      minWidth: 150,
      flex: 1,
      
      renderCell:(params)=>

      <Avatar src={`/uploads/products/${params.row.productPictures.length>0?params.row.productPictures[0].img:null}`} 
      
      style={{height:50,width:50,border:'1px solid wheat'}}/>,
    
      sortable:false,filterable:false,
      // },
    },
  
    {
      field: "stock",
      headerName: "Stock",
      type: "number",
      minWidth: 150,
      flex: 0.3,
    },

    {
      field: "salePrice",
      headerName: "Price",
      type: "number",
      minWidth: 170,
      flex: 0.5,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        
        return (
          <Fragment>
             <MetaData title={`Product List --admin`} />
             
            <Link to={`/admin/update/${params.id}`}>
              <EditIcon style={{color:'green'}} onClick={()=>dispatch(listProductDetails(params.id))}/>
            </Link>

            <Button
              // onClick={Navigate(params.id) }
            >
              <Link to={`/admin/delete/product/${params.id}`}>
              <DeleteIcon style={{color:'red'}}/></Link>
            </Button>
     
          </Fragment>
        );
      },
    },
  ];

  // const rows = [];

  // products &&
  //   products.forEach((item) => {
  //   //   console.log(item,'p list');
  //   //  const productPictures=item.productPictures[0].img
 

  //     rows.push({
  //       id: item._id,
  //       stock: item.stock,
  //       price: item.price,
  //       name: item.name,
  //     //  productPictures:productPictures
      
  //     });
  //   });

  return (
    <Fragment>
      <MetaData title={`ALL PRODUCTS - Admin`} />

      <div className="dashboard">
        <SideBar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL PRODUCTS</h1>

          <DataGrid
            rows={products}
            columns={columns}
            getRowId={row=>row._id}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ProductList;

