import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import "./banner.css";
import { Link } from 'react-router-dom';
import { getBannerAction } from '../../Redux/Action/bannerAction';
import { useDispatch, useSelector } from 'react-redux'

const Banner = () => {
    const dispatch=useDispatch()
    const {banner}=useSelector(state=>state.getBannerReducer)
    console.log(banner,'banner');
    useEffect(() => {
    dispatch(getBannerAction())
    }, [dispatch])
    
    return (
        <>

        
    <Carousel
                className="carasousel m-2"
                autoPlay={true}
                animation="fade"
                indicators={true}
                navButtonsAlwaysVisible={true}
                cycleNavigation={true}
                navButtonsProps={{
                    style: {
                        background: "#fff",
                        color: "#494949",
                        borderRadius: 100,
                        border:10,
                        borderColor:'#494949',
                        marginTop: -22,
                        height: "104px",
                    }
                }}>
                {
                    banner && banner?.map((imag, i) => {
                        return (
                            <>
                          <div className="banner-area">
                                <Link to='/'>
                                    
                                    <img key={i} className="card-img-top img-fluid banner-image" 
                                    src={`/uploads/banner/${imag.fileName}`} 
                                    alt={imag && imag.name}/>
                                    
                                
                                    </Link>
                       
                                    </div>
                        
                            
                            </>
                        )
                    })

                }

            </Carousel>
  


            
        </>
    )
}  

export default Banner;
