import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOffer1Action } from '../../Redux/Action/offerAction'
import { Link } from 'react-router-dom'
import moment, { isDate } from 'moment'
import { TimePicker } from 'antd'
const GetOffer1 = () => {

  const{offer1}=useSelector(state=>state.getOffer1Reducer)
  console.log(offer1,'offer 1S');

  const dispatch=useDispatch()
  useEffect(() => {
dispatch(getOffer1Action())
  }, [dispatch])
  // console.log(offer1.startDate,'start');
  
  return (
    <>
  
                        {
                              offer1 && offer1.map((item,i)=>{
                             
                                return(
                                  <>
                                  
                                {
                                 
                
                            <div className="banner-area products_page mb-3 m-auto">
                      
                                <Link to='/'>
                                    
                                    <img key={i} className="card-img-top img-fluid banner-iteme" 
                                    src={`/uploads/offer1/${item.fileName}`} 
                                    alt={item && item.name}/>
                                    
                                
                                    </Link>
                       
                                    </div>}
                        
                                  </>
                                )
                              })
                            }
                                            
                                  
                         
                        
                            
                    
    
    
    </>
  )
}

export default GetOffer1