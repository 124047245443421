import React, { useEffect, useState } from 'react'
import {useFormik,} from 'formik'
import * as Yup from 'yup'
import {DatePicker} from 'antd'
// import moment from 'moment'
import { useDispatch , useSelector} from 'react-redux'
import { addProduct } from '../../Redux/Action/postProductAction'
import { getSubCategory } from '../../Redux/Action/catgoryAction'
import {Link} from 'react-router-dom'
// import moment from 'moment'
import { createProduct } from '../../Redux/Action/listProductAction'
import Sidebar from '../DashBoard/Sidebar'
import Swal from 'sweetalert2'
import { useAlert } from 'react-alert'
import axios from 'axios'
import { getAllUsers, loadUser } from '../../Redux/Action/userAction'
import MetaData from '../layout/MetaData'
import UserSideBar from '../DashBoard/UserSideBar'
const {RangePicker}=DatePicker

const UserProductUpload = () => {
  const [categorname, setcategorname] = useState('')
   const  {category}= useSelector((state) => state.getCategoryReducer);
   const  {userDetails}= useSelector((state) => state.userLoadReducer);
  // console.log(category,'main category');
  const {getSubcategory}= useSelector((state) => state.getSubCategoryReducer);
  console.log(getSubcategory,'getSubCategoryReducer hs');
  const {users}= useSelector((state) => state.allUsersReducer);
  console.log(users,'users');
  const {loading,success,product}= useSelector((state) => state.newProductReducer);
  // console.log(loading,success,product,'loading,success,product');
  // console.log(users,'allUsersReducer');
  const [images, setImages] = useState([]);
  const [date, setDate] = useState([]);
  const [imagesName, setImagesName] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
let salePrice;
  const dispatch=useDispatch()
  const alert=useAlert()
  const {handleBlur,handleReset,handleSubmit,setFieldValue,handleChange,values,touched,errors}=useFormik({
    // enableReinitialize: true,
    initialValues:{
          
          // compulsory
          name:'',user:'',category:'',subCategory:'',productPictures:'',stock:'',
          costPrice:'',discountInAmount:'',discountInPercent:'',sellingPrice:'',description:'',
          // optional
          weight:'',size:'',color:'',brand:'',newArrival:'',bigDiscount:'',offer:'',deal:''},
      validationSchema:Yup.object().shape({
user:Yup.string().required('user is required'),
name:Yup.string().required('user is required'),
description:Yup.string().required('user is required'),
sellingPrice:Yup.number().required('user is required'),
costPrice:Yup.number().required('user is required'),
stock:Yup.number().required('user is required'),
// productPictures:Yup.array().min(1, "At least one option is required").required(),
subCategory:Yup.string().required('user is required'),
category:Yup.string().required('user is required'),


    }),
      onSubmit:(val)=>{
         console.log(val.deal,'val');
             dispatch(createProduct({val,images,imagesName,salePrice}))
           
            }})
            
        
            const createProductImagesChange = (e) => {
           
              const files = Array.from(e.target.files);
                         
      
          // console.log(abc,'abc');
              setImages([]);
              setImagesPreview([]);
           

              files.forEach((file) => {
            
                const reader = new FileReader();
          
                reader.onload = () => {
                  if (reader.readyState === 2) {
                    setImagesPreview((old) => [...old, reader.result]);
                    setImages((old) => [...old, reader.result]);
                            
                  }
                };
          
                reader.readAsDataURL(file);

                setImagesName(files.map((item)=>(
                  (item.name)
                )))
           
              });
                        }
                        useEffect(() => {
if(loading===false && success===true){
  Swal.fire({
    icon:'success',
    text:'product uploaded successfully'
  })
}
if(loading===false && success===false){
  Swal.fire({
    icon:'error',
    text:'product did not uploaded successfully'
  })
}
            dispatch(getAllUsers())           
                        }, [dispatch,loading,success])
                        
                      
                       
  return (
  <>
 <MetaData title={`Upload Product`} />
<div className="container-fluid">
  <div className="row d-flex flex-row">
 <div className="col-lg-2"><UserSideBar/></div>
    <div className="col-md-10 col-lg-10 col-xl-10 order-2 order-lg-1">

<p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Product upload</p>

<form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
<div className="d-flex flex-row align-items-center mb-4">
<div className='col-3'> <label className="form-label" for="form3Example1c">User Name</label></div>
            <div className="form-outline flex-fill mb-0">
                <select  className="ms-3"           
                onChange={handleChange}  onBlur={handleBlur} name='user'>
                    <option value="">Select User</option>
                    {userDetails.role==='admin'?<option value={userDetails._id}>{userDetails.name}</option>:
                    
                      users&&users?.map((item)=>(
                       
                        <>
                         <option value={item._id}>{item.name}</option>
                        </>
                      ))
                      
                    
                    }
                   
                </select>
       <div className='error' style={{color:'red'}}>
      {touched.user && errors.user ? errors.user:''}
    </div>
  </div>
</div>
<div className="d-flex flex-row align-items-center mb-4">
<div className='col-3'> <label className="form-label" for="form3Example1c">category</label></div>
            <div className="form-outline flex-fill mb-0">
                <select  className="ms-3"   onClick={(e)=>dispatch(getSubCategory(e.target.value))}             
                onChange={handleChange}  onBlur={handleBlur} name='category'>
                    <option value="">select category</option>
                    {
                      category&&category?.map((item)=>(
                       
                        <>
                         <option value={item._id}>{item.name}</option>
                        </>
                      )) }
                   
                </select>
       <div className='error' style={{color:'red'}}>
      {touched.category && errors.category ? errors.category:''}
    </div>
  </div>
</div>
{/* sub category */}
<div className="d-flex flex-row align-items-center mb-4">
<div className='col-3'> <label className="form-label" for="form3Example1c">sub category</label></div>
            <div className="form-outline flex-fill mb-0">
                <select  className="ms-3"   
                // onClick={(e)=>dispatch(getSubCategory(e.target.value))}             
                onChange={handleChange}  onBlur={handleBlur} name='subCategory'>
                    <option value="">select category</option>
                    {
                      getSubcategory&&getSubcategory?.map((item)=>(
                       
                        <>
                         <option value={item._id}>{item.name}</option>
                        </>
                      ))
                      
                    }
                   
                </select>
       <div className='error' style={{color:'red'}}>
      {touched.subCategory && errors.subCategory ? errors.subCategory:''}
    </div>
  </div>
</div>
{/* sub category */}


<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">choose Picture</label></div>
                              <div className="form-outline flex-fill mb-0">
                              <input
                type="file"
                name='productPictures'
                accept="image/*"
                onChange={createProductImagesChange}
                multiple
                onBlur={handleBlur} 
              />
                      
                      <div className='error' style={{color:'red'}}>
                        {touched.name && errors.name ? errors.name:''}
                       <div className="row d-flex flex-row">
                        {imagesPreview.map((image, index) => (
                <img key={index} src={image} alt="Product Preview" style={{height:'50px',width:'75px',margin:'10px'}} />
              ))}
                      </div>
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Product Name</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='product Name'
                 onChange={handleChange}  onBlur={handleBlur} name='name' />
                      <div className='error' style={{color:'red'}}>
                        {touched.name && errors.name ? errors.name:''}
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">stock</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="number" className="form-control ms-3" placeholder='stock'
                 onChange={handleChange}  onBlur={handleBlur} name='stock' />
                      <div className='error' style={{color:'red'}}>
                        {touched.stock && errors.stock ? errors.stock:''}
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">cost Price</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="number" className="form-control ms-3" placeholder='costPrice'
                 onChange={handleChange}  onBlur={handleBlur} name='costPrice' />
                      <div className='error' style={{color:'red'}}>
                        {touched.costPrice && errors.costPrice ? errors.costPrice:''}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Selling price</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="number" className="form-control ms-3" placeholder='sale Price'
                           
                 onChange={handleChange}  onBlur={handleBlur} name='sellingPrice' />
                      <div className='error' style={{color:'red'}}>
                        {touched.sellingPrice && errors.sellingPrice ? errors.sellingPrice:''}
                      </div>
                    </div>
                  </div>

<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Discount in Percent(optional)</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="number" className="form-control ms-3" placeholder='discount in percent'
            
                 onChange={handleChange}  onBlur={handleBlur} name='discountInPercent' />
       
                      <div className='error' style={{color:'red'}}>
                        {touched.discount && errors.discount ? errors.discount:''}
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Discount in Amount (optional)</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="number" className="form-control ms-3" placeholder='discount in amount'
            
                 onChange={handleChange}  onBlur={handleBlur} name='discountInAmount' />
       
                      <div className='error' style={{color:'red'}}>
                        {touched.discount && errors.discount ? errors.discount:''}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">sale price</label></div>
                              <div className="form-outline flex-fill mb-0">
 {salePrice=Math.ceil(values.sellingPrice-(values.sellingPrice*values.discountInPercent/100)-values.discountInAmount)}
   
                  
                    </div>
                  </div>

<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">description</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='description'
                 onChange={handleChange}  onBlur={handleBlur} name='description' />
                      <div className='error' style={{color:'red'}}>
                        {touched.description && errors.description ? errors.description:''}
                      </div>
                    </div>
                  </div>
                  {/* optional */}
                  <h3 className='text-center bg-primary h-100'>OPTIONAL</h3>
                  <div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Brand Name</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='brand'
                 onChange={handleChange}  onBlur={handleBlur} name='brand' />
                      <div className='error' style={{color:'red'}}>
                        {touched.brand && errors.brand ? errors.brand:''}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Size</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='size'
                 onChange={handleChange}  onBlur={handleBlur} name='size' />
                      <div className='error' style={{color:'red'}}>
                        {touched.size && errors.size ? errors.size:''}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">weight</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='weight'
                 onChange={handleChange}  onBlur={handleBlur} name='weight' />
                      <div className='error' style={{color:'red'}}>
                        {touched.weight && errors.weight ? errors.weight:''}
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Offer</label></div>
                              <div className="form-outline flex-fill mb-0">
                              <select  className="ms-3"         
                onChange={handleChange}  onBlur={handleBlur} name='offer'>
                    <option value="">Feature Product</option>
                    <option value='true'>Yes</option>
                     
                </select>
                      <div className='error' style={{color:'red'}}>
                        {touched.offer && errors.offer ? errors.offer:''}
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Deal of the Day</label></div>
                              <div className="form-outline flex-fill mb-0">
              <RangePicker onChange={(values)=>(
                setDate(values.map(item=>{
// return moment(item).format('DD-MM-YYYY')
                }
  )))} name='deal'/>
                      <div className='error' style={{color:'red'}}>
                        {touched.deal && errors.deal ? errors.deal:''}
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Big Discount</label></div>
                              <div className="form-outline flex-fill mb-0">
                              <select  className="ms-3"         
                onChange={handleChange}  onBlur={handleBlur} name='bigDiscount'>
                    <option value="">Big Discount</option>
                    <option value='true'>Yes</option>
                     
                </select>
                      <div className='error' style={{color:'red'}}>
                        {touched.bigDiscount && errors.bigDiscount ? errors.bigDiscount:''}
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">New Arrival</label></div>
                              <div className="form-outline flex-fill mb-0">
                              <select  className="ms-3"         
                onChange={handleChange}  onBlur={handleBlur} name='newArrival'>
                    <option value="">New Arrival</option>
                    <option value='true'>Yes</option>
                     
                </select>
                      <div className='error' style={{color:'red'}}>
                        {touched.newArrival && errors.newArrival ? errors.newArrival:''}
                      </div>
                    </div>
                  </div>
<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">color</label></div>
                              <div className="form-outline flex-fill mb-0">
                              <select  className="ms-3"         
                onChange={handleChange}  onBlur={handleBlur} name='color'>
                    <option value="">Select Color</option>
                   <option value="white">White</option>
                   <option value="yellow">Yellow</option>
                   <option value="blue">Blue</option>
                   <option value="red">Red</option>
                   <option value="green">Green</option>
                   <option value="black">Black</option>
                   <option value="brown">Brown</option>
                   <option value="azure">Azure</option>
                   <option value="ivory">Ivory</option>
                   <option value="teal">Teal</option>
                   <option value="silver">Silver</option>
                   <option value="purple">Purple</option>
                   <option value="navyBlue">Navy Blue</option>
                   <option value="peaGreen">Pea Green</option>
                   <option value="gray">Gray</option>
                   <option value="orange">Orange</option>
                   <option value="maroon">Maroon</option>
                   <option value="charcoal">Charcoal</option>
                   <option value="aquamarine">Aqua Marine</option>
                   <option value="coral">Coral</option>
                   <option value="fuchsia">Fuchsia</option>
                   <option value="wheate">Wheat</option>
                   <option value="lime">Lime</option>
                   <option value="crimson">Crimson</option>
                   <option value="khaki">Khaki</option>
                   <option value="hotPink">Hot Pink</option>
                   <option value="magenta">Magenta</option>
                   <option value="olden">Olden</option>
                   <option value="plum">Plum</option>
                  
                     
                </select>
                      <div className='error' style={{color:'red'}}>
                        {touched.color && errors.color ? errors.color:''}
                      </div>
                    </div>
                  </div>
           
           
            <button type="submit" className="btn btn-primary btn-lg">upload</button>
    </form>
  </div>
     
  </div>
</div>
  </>
  )
}
export default UserProductUpload
