import React, { useEffect, useState } from 'react'
import Sidebar from '../DashBoard/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { getSinleBannerAction, getUpdateBannerAction } from '../../Redux/Action/bannerAction'
import { useParams } from 'react-router-dom'
import { getUserDetails, updateUser } from '../../Redux/Action/userAction'

const UserUpdate = () => {
  const {id}=useParams()
  const {userDetails}=useSelector(state=>state.userLoadReducer)
  const dispatch=useDispatch()

 const [role, setRole] = useState(userDetails.role)
 const [active, setActive] = useState(userDetails.active)

    
      useEffect(() => {
        dispatch(getUserDetails(id))
      }, [dispatch,id])
      const handleClick=async()=>{
        dispatch(updateUser({id,role,active}))
      }
      
  return (
<>
{/* copy area starts */}
<div className="container-fluid">
  <div className="row d-flex flex-row">
 <div className="col-lg-2"><Sidebar/></div>
 <div className="col-lg-10">
<section className="register">
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-12 col-xl-11" >
        <div className="card text-black w-100" style={{background:'none',border:'none'}}>
          <div className="card-bo p-md-5">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">

                <h5 className="text-center mb-5 mx-1 mx-md-4 mt-1">Update User</h5>
                <h5 className="text-center mb-5 mx-1 mx-md-4 mt-1">Name:{userDetails.name}</h5>
                <h5 className="text-center mb-5 mx-1 mx-md-4 mt-1"> Shop Name:{userDetails.shopName}
                </h5>
{/* copy area end */}

<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Select User's Role</label></div>
                              <div className="form-outline flex-fill mb-0">
<select name="" id=""  onChange={(e)=>setRole(e.target.value)}>
<option value=''>
 Select User Role
</option>
<option value="user">user</option>
<option value="admin">Admin</option>
<option value="superadmin">Super Admin</option>
</select>
                  
                    </div>
                  </div>

<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Select User's Status</label></div>
                              <div className="form-outline flex-fill mb-0">
<select name="" id=""  onChange={(e)=>setActive(e.target.value)}>
<option value=''>Select Status</option>
<option value="true">active</option>
<option value="false">Deactive</option>

</select>
                  
                    </div>
                  </div>



                  
                 <button type="submit" className="btn btn-primary btn-lg" onClick={handleClick}>Upload</button>


                </div></div></div></div></div></div></div></section></div></div></div>

</>
  )
}

export default UserUpdate
