import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails } from '../../Redux/Action/userAction';
import { useParams } from 'react-router-dom';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';

import { NavLink, useNavigate } from 'react-router-dom';
import MetaData from '../layout/MetaData';
import Sidebar from '../DashBoard/Sidebar';
import axios from 'axios';
import { useAlert } from 'react-alert';

const GetSingleUser = () => {
  const {id}=useParams()
  const alert=useAlert()
  const navigate=useNavigate()
    const {user}=useSelector(state=>state.userDetails)
    console.log(user,'user detauks');
const dispatch=useDispatch()
    useEffect(() => {
   dispatch(getUserDetails(id))
    }, [dispatch,id])
  
  const handleDelete = async (id) => {
    if(user.role==='admin'){
      alert.error('admin cannot be deleted')
    }
    else {
    await axios.delete(`/admin/users/${id}`)
      .then((res) => {
        alert.success("user deleted Successfully");
        
      });
      navigate('/admin/user')
  
}}
  return (
    <>
        <Fragment>
      <MetaData title={`ALL PRODUCTS - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">{user.name}</h1>
          {user ? (
      <>
<div className="container mt-2 text-center">
<div className="row">
  <div className="col-lg-9"></div>
  <div className="col-lg-3">
  {user.role==='admin'?<button className='btn btn-primary' disabled onClick={()=>handleDelete(user._id)}>Delete User</button>:<button className='btn btn-primary' onClick={()=>handleDelete(user._id)}>Delete User</button>}
</div>
</div>
</div>
<section style={{ backgroundColor: '#eee' }}>
  <MDBContainer className="py-5">
    <MDBRow>
      <MDBCol>
      
        <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
          <MDBBreadcrumbItem>
            <a href='#'>Home</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <a href="#">User</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>{user.name}</MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol lg="4">
        <MDBCard className="mb-4">
          <MDBCardBody className="text-center">
            <MDBCardImage
              src={`/uploads/userprofile/${user.userProfile}`}
              alt="avatar"
              className="rounded-circle"
              style={{ width: '150px' }}
              fluid />
        
            <div className="d-flex justify-content-center mb-2">
              <MDBBtn>Follow</MDBBtn>
              <MDBBtn outline className="ms-1">Message</MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>

       </MDBCol>
       </MDBRow>
      <MDBCol lg="8">
        <MDBCard className="mb-4">
          <MDBCardBody>
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>Full Name</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{user.name}</MDBCardText>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>E-mail</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{user.email}</MDBCardText>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>Phone Number</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{user.phoneNumber}</MDBCardText>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>user Name</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{user.userName}</MDBCardText>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>Address</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{user.address}</MDBCardText>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
</MDBCol>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4 mb-md-0">
             

         
             </MDBCard>
             </MDBCol>
        </MDBRow>
        
  
    
  </MDBContainer>
  
</section>
    </>
    )
    :<h1>no user found</h1>}
   
    
    
          </div></div></Fragment>
    
    
    
    
    </>
  )
}

export default GetSingleUser