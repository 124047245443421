import React, { useEffect, useState } from "react";
import WhatsApp from "./components/whatapp/WhatsApp";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  Navigate,
} from "react-router-dom";

import Register from "./components/user/Register";
import Login from "./components/user/Login";
import store from "./store";
import { getAllUsers, loadUser } from "./Redux/Action/userAction";
import UserProfile from "./components/user/UserProfile";
import ProductUpload from "./components/Products/ProductUpload";
import NewCategory from "./components/Category/NewCategory";

import { getAllCategory } from "./Redux/Action/catgoryAction";
import AddBanner from "./components/Banner/AddBanner";
import Offer1 from "./components/Offers/Offer1";
import Offer2 from "./components/Offers/Offer2";
import Offer3 from "./components/Offers/Offer3";
import UpdateProfile from "./components/user/UpdateProfile";
import UpdateProduct from "./components/Products/UpdateProduct";
// import GetProducts from './components/Products/GetProducts'
import BannerUpdate from "./components/Banner/BannerUpdate";
import BannerList from "./components/Banner/BannerList";
import { getBannerAction } from "./Redux/Action/bannerAction";
import GetSingleBanner from "./components/Banner/GetSingleBanner";
import "./assets/css/style-prefix.css";
import "./assets/css/style.css";
import Footer from "./components/Footer/Footer";
import Shipping from "./components/Cart/Shipping";
import ConfirmOrder from "./components/Cart/ConfirmOrder";
import UserList from "./components/user/UserList";
import OrderSuccesss from "./components/Cart/OrderSuccess";
// import ProductDetails from "./components/home/ProductDetails";

import OrderDetails from "./components/Order/OrderDetails";
import Logout from "./components/forms/Logout";
import Cart from "./components/Cart/Cart";
import Products from "./components/Products/Products";
import { getAdminProduct } from "./Redux/Action/adminAction";
import { getProduct } from "./Redux/Action/listProductAction";
import MyOrders from "./components/Order/MyOrders";
import AllOrders from "./components/Order/AllOrders";
import ErrorPgae from "./components/Page Not Found/ErrorPage";
import ProcessOrder from "./components/Order/ProcessOrder";
import DashBoard from "./components/DashBoard/DashBoard";
import ProductList from "./components/Products/ProductList";
import GetSingleUser from "./components/user/GetSingleUser";
import FilterProductDetails from "./components/Filteration/FilterProductDetails";
import MainPage from "./components/MainPage/MainPage";
import GetMainCategories from "./components/Category/GetCategories/GetMainCategories";
import { filterOrder, getAllOrders } from "./Redux/Action/orderAction";
import ViewAll from "./components/MainPage/ViewAll";
import Payment from "./components/payment/Payment";
import axios from "axios";
import PrivateRoute from "./components/Page Not Found/PrivateRoute";
import { useSelector } from "react-redux";
import Nav from "./components/Navbar/Nav";
import CategoryList from "./components/Category/CategoryList";
import GetSubCategory from "./components/Category/GetSubCategory";
import SearchResult from "./components/Search/SearchResult";
import UpdateCategory from "./components/Category/UpdateCategory";
import UserUpdate from "./components/user/UserUpdate";
import GetOffer1 from "./components/Offers/GetOffer1";
import GetOffer2 from "./components/Offers/GetOffer2";
import GetOffer3 from "./components/Offers/GetOffer3";
import AddSubCategory from "./components/Category/AddSubCategory";
import SubCategoryList from "./components/Category/SubCategoryList";
import GetSingleSubCategory from "./components/Category/GetSingleSubCategory";
import UpdateSubCategory from "./components/Category/UpdateSubCategory";
import ProductDetails from "./components/Products/ProductDetails";
import Navbar from "./components/Navbar/Navbar";
import Form1 from "./components/Cart/forms/Form1";
import Form2 from "./components/Cart/forms/Form2";
import ProductDelete from "./components/Products/ProductDelete";
import DeleteCat from "./components/Category/DeleteCat";
import DeleteSubCat from "./components/Category/DeleteSubCat";
import DeleteBanner from "./components/Banner/DeleteBanner";
import AdminDashBoard from "./components/DashBoard/AdminDashBoard";
import UserProductList from "./components/Products/UserProductList";
import UpdateUserProduct from "./components/Products/UpdateUserProduct";
import UserProductUpload from "./components/Products/UserProductUpload";
import UserProductDelete from "./components/Products/UserProductDelete";

import GetSubCat from "./components/Category/GetSubCat";
import SingleOrder from "./components/Order/SingleOrder";
import { ADD } from "./Redux/Action/cartAction";
import LoginShipping from "./components/user/LoginShipping";
const App = () => {
  const { isAuthenticated, loading, userDetails } = useSelector((state) => state.userLoadReducer);
  //  const {_id}=userDetails
  // console.log(userDetails,'usr details role');

  useEffect(() => {
    store.dispatch(getAllUsers());
    store.dispatch(getBannerAction());
    store.dispatch(loadUser());
    // store.dispatch(ADD())
    store.dispatch(getProduct());
    store.dispatch(getAllOrders());
    store.dispatch(filterOrder());
    store.dispatch(getAdminProduct());
    store.dispatch(getAllCategory());
    getStripeApiKey();
  }, []);
  const [stripeApiKey, setStripeApiKey] = useState("");
  async function getStripeApiKey() {
    const { data } = await axios.get("/sendapikey");
    setStripeApiKey(data.stripeApiKey);
  }


  return (
    <>
      <Router>
        <Navbar />

        <GetMainCategories />
        <GetSubCat />
        <Routes>
          {/* home page Route */}
          <Route path="/orders" element={<AllOrders />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/user/login" element={<LoginShipping />} />
          <Route path="/profile" element={<UserProfile />} />
          {userDetails?.role === 'admin' ? <Route path="/admin/dashboard" element={<AdminDashBoard />} /> : <Route path="/" element={<Products />} />}
          {userDetails?.role === 'superAdmin' ? <Route path="/admin/dashboard" element={<DashBoard />} /> : <Route path="/" element={<Products />} />}
          <Route path="/admin/user/products" element={<UserProductList />} />
          <Route path="/admin/user/update/:id" element={<UpdateUserProduct />} />
          <Route path="/admin/add/user/product" element={<UserProductUpload />} />
          <Route path="/admin/user/delete/product/:id" element={<UserProductDelete />} />
          {/* protected routes */}
          <Route path="/" element={<PrivateRoute />}>

            <Route path="/admin/update/:id" element={<UpdateProduct />} />
            <Route path="/admin/add/category" element={<NewCategory />} />
            <Route path="/admin/delete/product/:id" element={<ProductDelete />} />
            <Route path="/admin/delete/category/:id" element={<DeleteCat />} />
            <Route path="/admin/delete/banner/:id" element={<DeleteBanner />} />
            <Route path="/admin/delete/subcategory/:id" element={<DeleteSubCat />} />
            <Route path="/admin/add/category" element={<NewCategory />} />
            <Route
              path="/admin/update/category/:id"
              element={<UpdateCategory />}
            />
            <Route
              path="/admin/update/subcategory/:id"
              element={<UpdateSubCategory />}
            />
            <Route path="/admin/add/subcategory" element={<AddSubCategory />} />
            <Route path="/admin/add/product" element={<ProductUpload />} />
            <Route path="/admin/add/banner" element={<AddBanner />} />
            <Route path="/admin/get/banner" element={<BannerList />} />
            <Route path="/admin/update/banner/:id" element={<BannerUpdate />} />
            <Route
              path="/admin/get/singlebanner/:id"
              element={<GetSingleBanner />}
            />
            <Route path="/admin/add/offer1" element={<Offer1 />} />
            <Route path="/admin/add/offer2" element={<Offer2 />} />
            <Route path="/admin/add/offer3" element={<Offer3 />} />
            <Route path="/admin/users/update/:id" element={<UserUpdate />} />
            <Route
              path="/admin/update/product/:id"
              element={<UpdateProduct />}
            />

            <Route path="/admin/users/:id" element={<GetSingleUser />} />
            <Route path="/admin/users" element={<UserList />} />
            {/* orders */}


            <Route path="/admin/orders" element={<AllOrders />} />
            <Route path="/single/order/:id" element={<SingleOrder />} />


            <Route path="/admin/dashboard" element={<DashBoard />} />
            <Route path="/admin/add/banner" element={<AddBanner />} />
            <Route path="/admin/products" element={<ProductList />} />
            <Route path="/admin/category" element={<CategoryList />} />
            <Route path="/admin/subcategories" element={<SubCategoryList />} />
          </Route>

          {/* protectec routed end */}

          <Route path="/get/offer1" element={<GetOffer1 />} />
          <Route path="/get/offer2" element={<GetOffer2 />} />
          <Route path="/get/offer3" element={<GetOffer3 />} />
          <Route path="/user/update/profile" element={<UpdateProfile />} />
          <Route path="/product/detail/:id/" element={<ProductDetails />} />
          <Route path="/category/:name" element={<GetSubCategory />} />
          <Route path="/view/all/new/:name" element={<ViewAll />} />
          {/* <Route path="/products" element={<Payment />} /> */}
          <Route path="/products" element={<Products />} />
          <Route path="/products/:keyword" element={<SearchResult />} />
          <Route path="/category/:keyword" element={<SearchResult />} />
          <Route path="/home" element={<MainPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/test/products" element={<FilterProductDetails />} />
          {/* Order Routes */}

          {/* </Route> */}

          <Route path="/order/myorders" element={<MyOrders />} />
          <Route path="/order/:userName/:id" element={<OrderDetails />} />
          <Route path="/order/confirm" element={<ConfirmOrder />} />
          <Route path="/order/success" element={<OrderSuccesss />} />
          <Route path="/order/process/:id" element={<ProcessOrder />} />
          {/* category Routes */}

          {/* DashBoard links */}

          {/* User Routes */}
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/user/create" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          {/* more Links */}
          {/* <Route path="/payment" element={<Pyment />} /> */}
          <Route path="/cart" element={<Cart />} />


          <Route path="/shipping" element={<Shipping />} />

          <Route path="/:wrongurl" element={<ErrorPgae />} />
        </Routes>
        <Footer />
        {/* <WhatsApp/> */}
      </Router>
    </>
  );
};

export default App;
