import {
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_FAIL,
    MY_ORDERS_REQUEST,
    MY_ORDERS_SUCCESS,
    MY_ORDERS_FAIL,
    ALL_ORDERS_REQUEST,
    ALL_ORDERS_SUCCESS,
    ALL_ORDERS_FAIL,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    CLEAR_ERRORS,
    SINGLE_ORDER_REQUEST,
    SINGLE_ORDER_SUCCESS,
    SINGLE_ORDER_FAIL,
  } from "../Constant/orderConstant";
  
  import axios from "axios";
  
  // Create Order
  export const createOrder = (result) => async (dispatch,getState) => {
    // console.log(result,'====order');

    try {
      dispatch({ type: CREATE_ORDER_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
         const config={headers:{
          Authorization:`Bearer ${userFromStorage.token}`
      }}
       const {_id}=userFromStorage.user
      //  console.log(userFromStorage,'id order created');
      const  {data} = await axios.post("/order/new",{_id,result},config);
  console.log(data,'====order data');
      dispatch({ type: CREATE_ORDER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_ORDER_FAIL,
        payload: error.response,
      });
    }
  };
  
  // My Orders
  export const myOrders = () => async (dispatch,getState) => {
    try {
      dispatch({ type: MY_ORDERS_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
         const config={headers:{
          Authorization:`Bearer ${userFromStorage.token}`
      }}
      const { data } = await axios.post("/orders/me",userFromStorage._id,config);
  console.log(data.order,'my orders');
      dispatch({ type: MY_ORDERS_SUCCESS, payload: data.order });
    } catch (error) {
      dispatch({
        type: MY_ORDERS_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Get All Orders (admin)
  export const getAllOrders = () => async (dispatch,getState) => {
    try {
      dispatch({ type: ALL_ORDERS_REQUEST });
      // const userFromStorage=localStorage.getItem("user")
      // ?JSON.parse(localStorage.getItem("user")):null;
      //    const config={headers:{
      //     Authorization:`Bearer ${userFromStorage.token}`
      // }}
      const { data } = await axios.get("/admin/orders")
  // console.log(data,'orders data');
      dispatch({ type: ALL_ORDERS_SUCCESS, payload: data.orders });
    } catch (error) {
      dispatch({
        type: ALL_ORDERS_FAIL,
        payload: error.response,
      });
    }
  };
  //update order by admin

  // Update Order
  export const updateOrder = (orderData) => async (dispatch,getState) => {
   
    console.log(orderData ,'order order0');
    const {_id}=orderData
    try {
      dispatch({ type: UPDATE_ORDER_REQUEST });

      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
         const config={headers:{
          Authorization:`Bearer ${userFromStorage.token}`
      }}
      const { data } = await axios.put(
        `/order/update/${_id}`,orderData,config);
      console.log(data,'order update');
  
      dispatch({ type: UPDATE_ORDER_SUCCESS, payload:data});
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_FAIL,
        // payload: error.response.data.message,
      });
    }
  };
  
  // Delete Order
  export const deleteOrder = (id) => async (dispatch,getState) => {
    try {
      dispatch({ type: DELETE_ORDER_REQUEST });
      const{user:{user}}=getState();
      const config={
        headers:{ 
        
          Authorization:`Bearer ${user.token}`
        }}
  
      const { data } = await axios.delete(`/order/delete/${id}`,config);
  
      dispatch({ type: DELETE_ORDER_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: DELETE_ORDER_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Get Order Details
  export const getSingleOrderDetails = (id) => async (dispatch,getState) => {
    console.log(id,'id id did');
    try {
      dispatch({ type: SINGLE_ORDER_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
         const config={headers:{
          Authorization:`Bearer ${userFromStorage.token}`
      }}
      const { data } = await axios.get(`/single/order/${id}`,config);
  console.log(data,'id order');
      dispatch({ type:SINGLE_ORDER_SUCCESS, payload: data.order });
    } catch (error) {
      dispatch({
        type: SINGLE_ORDER_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  export const getOrderDetails = (id) => async (dispatch,getState) => {
    try {
      dispatch({ type: ORDER_DETAILS_REQUEST });
      const{user:{user}}=getState();
      
      const config={
        headers:{ 
        
          Authorization:`Bearer ${user.token}`
        }}
      const { data } = await axios.get(`/order/${id}`,config);
  // console.log(data,'id order');
      dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data.order });
    } catch (error) {
      dispatch({
        type: ORDER_DETAILS_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Clearing Errors
  export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };
  
  export const filterOrder = (orderData) => async (dispatch) => {
    console.log(orderData,'order date');
   try {
  dispatch({ type:'FILTER_ORDER_SUCCESS',
  payload:orderData
});

} catch (error) {
  // console.log(error);
}
  };