import axios from "axios";
import CircularJSON from 'circular-json'

import {
    ADD_TO_CART,
    REMOVE_CART_ITEM,
    SAVE_SHIPPING_INFO,
  } from "../Constant/cartContant";

  export const ADD=(item)=>async(dispatch,getState)=>{

    console.log(item,'cart item');
      dispatch({
      type:ADD_TO_CART,
      payload:item
      
    });
   
      try {
        // localStorage.clear()
//  localStorage.setItem('cart', CircularJSON.stringify(getState().cart.cartItems))
 localStorage.setItem("cartItems",JSON.stringify(getState().cart.cartItems));
     
      } catch(err) {
        console.log(err);
      }
    };
    
  

  // REMOVE FROM CART
  export const removeItemsFromCart = (id) => async (dispatch, getState) => {
    dispatch({
      type: REMOVE_CART_ITEM,
      payload: id,
    });
  
    localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  };
  
  // SAVE SHIPPING INFO
  export const saveShippingInfo = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_SHIPPING_INFO,
      payload: data,
    });
  
    localStorage.setItem("shippingInfo", JSON.stringify(data));
  };
  
  //payment 
  export const savePaymentMethod = (data) => async (dispatch) => {
    dispatch({
      type: 'SAVE_PAYMENT_INFO',
      payload: data,
    });
  
    localStorage.setItem("paymentInfo", JSON.stringify(data));
  };
  