import React from "react";
import "./Sidebar.css";
import logo from "../../images/logo.png";
import { Link, useParams } from "react-router-dom";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import RateReviewIcon from "@material-ui/icons/RateReview";
import CategoryIcon from '@mui/icons-material/Category';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import UpdateIcon from '@mui/icons-material/Update';


const UserSideBar= () => {
  return (
    <>
 
        {/* <div className="container-fluid">
          <div className="row"> */}

          {/* <div className="col-lg-2 col-md-12 col-sm-12"> */}
          <div className="sidebarleft ">
     
          <Link to='/'>
    <img src='../../images/logo.png' className='banner_img text-light bg-light rounded-circle'  
    alt='logo'/>
    </Link>
      <Link to="/admin/dashboard">
        <p>
          <DashboardIcon /> Dashboard
        </p>
      </Link>
      <Link>
        <TreeView
          // defaultCollapseIcon={<Dropdown />}
          defaultCollapseIcon={<PostAddIcon/>}
          defaultExpandIcon={<AddIcon />}
        >
          <TreeItem nodeId="1" label="Products">
            <Link to="/admin/user/products">
              <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
            </Link>

            <Link to="/admin/add/user/product">
              <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
            </Link>
         
          </TreeItem>
         
        </TreeView>
      </Link>
    
      
      
    </div>
 
  
    </>
  );
};

export default UserSideBar;
