import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFilterProductsBySubCategory } from '../../Redux/Action/listProductAction';
import { Link, useNavigate, useParams } from 'react-router-dom';

const GetSubCat = () => {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const {params}=useParams()
  console.log(params,'params name');
    const {getSubcategory}= useSelector((state) => state.getSubCategoryReducer);
const handleNavigate=(e)=>{
  navigate(`/category/${e}`)
}

return (
  <>
<div className="maincategory mt-2 bg-light boxsizing">
     {
  getSubcategory?.map((item)=>{
      return(
         <>
         
          <div className="card1">

          <div className="cat-text" 
          onClick={(e)=>{dispatch(getFilterProductsBySubCategory(item._id));handleNavigate(item.name)}}>
          {item.name?item.name.substring(0,25):'sello'}
</div>
          
    
     </div>   
           


 </>
     )
 
 })}<br/>
 
 {/* </Slider> */}
     </div>
 
 
    
  </>
   )

}

export default GetSubCat