import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WorkIcon from '@mui/icons-material/Work';
import Dropdown from 'antd';
import './footer.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Footer = () => {
  const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer);

  return (
<>
<div className="container-fluid bg-dark text-light mt-4">
    <div className="row d-flex flex-row">
      {/* 1st div */}
        <div className="col-lg-3 col-sm-6 col-md-6">
        <ul className="">

<li className="">
  <h4 className="">Popular Categories</h4>
</li>

<li className="footer-list">
  <a href="#" className="footer-nav-link">Fashion</a>
</li>

<li className="footer-list">
  <a href="#" className="footer-nav-link">Electronic</a>
</li>

<li className="footer-list">
  <a href="#" className="footer-nav-link">Cosmetic</a>
</li>

<li className="footer-list">
  <a href="#" className="footer-nav-link">Health</a>
</li>

<li className="footer-list">
  <a href="#" className="footer-nav-link">Watches</a>
</li>

</ul>
        
        </div>
        {/* 2nd div */}
        <div className="col-lg-3 col-sm-6 col-md-6">
        <ul className="footer-ul">
        
        <li className="footer-list">
          <h4 className="nav-title">Our Company</h4>
        </li>
      
        <li className="footer-list">
          <a href="#" className="footer-nav-link">Delivery</a>
        </li>
      
        <li className="footer-list">
          <a href="#" className="footer-nav-link">Legal Notice</a>
        </li>
      
        <li className="footer-list">
          <a href="#" className="footer-nav-link">Terms and conditions</a>
        </li>
      
        <li className="footer-list">
          <a href="#" className="footer-nav-link">About us</a>
        </li>
      
        <li className="footer-list">
          <a href="#" className="footer-nav-link">Secure payment</a>
        </li>
      
      </ul>
        
        </div>
        {/* 3rd div */}
        <div className="col-lg-3 col-sm-6 col-md-6">
        <ul className="footer-ul">

<li className="footer-list">
  <h4 className="nav-title">Contact</h4>
</li>

<li className="footer-list flex">
  <div className="icon-box">
    <ion-icon name="location-outline"></ion-icon>
  </div>

  <address className="content">
    419 State 414 Rte
    Beaver Dams, New York(NY), 14812, USA
  </address>
</li>

<li className="footer-list flex">
  <div className="icon-box">
    <ion-icon name="call-outline"></ion-icon>
  </div>


</li>

<li className="footer-list flex">
  <div className="icon-box">
    <ion-icon name="mail-outline"></ion-icon>
  </div>

  <a href="mailto:example@gmail.com" className="footer-nav-link">sellopointofficial@gmail.com</a>
</li>

</ul>


        <h4>CALL US:0341-6266690</h4>

        </div>
        {/* 4th div */}
        <div className="col-lg-3 col-sm-6 col-md-6">

        <div className="social-links">


<ul className="justify-content-between mt-3 d-flex flex-row">


<li className="footer-list">
  <h4 className="nav-title">Follow Us</h4>
</li>

<li>
<NavLink to='/products' className="new-social-link">
<FacebookIcon name="logo-facebook" style={{color:'white',fontSize:'35px'}}/>
</NavLink>
</li>

<li>
<NavLink to='/admin/dashboard' className="social-link text-primary">
<TwitterIcon name="logo-twitter" style={{color:'white',fontSize:'35px'}}></TwitterIcon>
</NavLink>
</li>

<li>
<NavLink to='/admin/add/category' className="social-link">
<InstagramIcon name="logo-instagram" style={{color:'white',fontSize:'35px'}}></InstagramIcon>
</NavLink>
</li>

<li>
<NavLink to='/add/product' className="social-link">
<YouTubeIcon name="logo-linkedin" style={{color:'white',fontSize:'35px'}}></YouTubeIcon>
</NavLink>
</li>
<li>
<Link to='//wa.me/923416266690' target='_blank'>
<WhatsAppIcon name="logo-whatsapp" style={{color:'white',fontSize:'35px'}}/>
</Link>
</li>

</ul>
</div>



        </div>

<p className='text-center'>Dear <h4>{userDetails?userDetails.userName:'dear user'}</h4>
 Free Shipping for you Order Over - Rs.5000/-
</p>
<p className="copyright text-center">
Copyright &copy; <a href="#">sellopoint.com</a> all rights reserved.
</p>
    </div>
</div>
</>
  )
}

export default Footer