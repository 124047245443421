import axios from 'axios'
import { ADMIN_PRODUCT_REQUEST,
    ADMIN_PRODUCT_SUCCESS,
    ADMIN_PRODUCT_FAIL,
    ADMIN_CATEGORY_REQUEST,
    ADMIN_CATEGORY_FAIL,
    ADMIN_CATEGORY_SUCCESS,} from '../Constant/adminConstant'
import { ADMIN_USERS_FAIL, ADMIN_USERS_REQUEST, ADMIN_USERS_SUCCESS } from '../Constant/adminConstant';

export const getAdminProductByUserId = (id) => async (dispatch) => {
  console.log(id);
  dispatch({ type: 'ADMIN_PRODUCT_REQUEST_BY_USER',
payload:id

});


}



export const getAdminProduct = () => async (dispatch,getState) => {
    try {
      dispatch({ type: ADMIN_PRODUCT_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
         const config={headers:{
          Authorization:`Bearer ${userFromStorage.token}`
      }}
      const { data } = await axios.get("/admin/get/adminproducts",config);
  console.log(data,'admin/products');
      dispatch({
        type: ADMIN_PRODUCT_SUCCESS,
        payload: data.products,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_PRODUCT_FAIL,
        payload: error.response.data,
      });
    }
  };

export const getAdminUsers = () => async (dispatch,getState) => {
    try {
      dispatch({ type: ADMIN_USERS_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
         const config={headers:{
          Authorization:`Bearer ${userFromStorage.token}`
      }}
      const { data } = await axios.get("/admin/get/adminusers",config);
  console.log(data,'admin/users');
      dispatch({
        type: ADMIN_USERS_SUCCESS,
        payload: data.users,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_USERS_FAIL,
        payload: error.response.data,
      });
    }
  };
  
export const getAdminCategory = () => async (dispatch,getState) => {
    try {
      dispatch({ type: ADMIN_CATEGORY_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
         const config={headers:{
          Authorization:`Bearer ${userFromStorage.token}`
      }}
      const { data } = await axios.get("/admin/get/admincategories/",config);
  console.log(data,'admin/CATEGORIES');
      dispatch({
        type: ADMIN_CATEGORY_SUCCESS,
        payload: data.categories,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_CATEGORY_FAIL,
        payload: error.response.data,
      });
    }
  };