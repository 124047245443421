import axios from "axios";

import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
 
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_FAIL,
  ALL_REVIEW_REQUEST,
  ALL_REVIEW_SUCCESS,
  ALL_REVIEW_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  CLEAR_ERRORS,
  ALL_PRODUCTS_FILTER_REQUEST,
  ALL_PRODUCTS_FILTER_SUCCESS,
  ALL_PRODUCTS_FILTER_FAIL,
} from "../Constant/productConstants";
import {SEARCH_REQUEST,SEARCH_SUCCESS,SEARCH_FAIL} from '../Constant/searchApiConstant'
import {useAlert} from 'react-alert'
import { FILTER_PRODUCT_BYCATEGORY_SUCCESS, FILTER_PRODUCT_BYSUBCATEGORY_SUCCESS } from "../Constant/filterConstant";
export const searchProduct=(keyword)=>async(dispatch)=>{
  
  console.log(keyword,'key words');
try {
  dispatch({type:SEARCH_REQUEST});
  let link=`/products/${keyword}`
  const {data}=await axios.get(link);
  console.log(data,'search data');
  dispatch({
    type: SEARCH_SUCCESS,
    payload: data.products
  });

} catch (error) {
  dispatch({
    type: SEARCH_FAIL,
    // payload: error.response.data.message,
  });
}
}
// filter by date
export const filterByDate=(data)=>async(dispatch)=>{
  // console.log(data,'date filtering');
try {
  dispatch({type:'DATE_FILTER',
payload:data
})
  
} catch (error) {
  console.log(error);
}
}

// Get All Products
export const getProduct =()=>  async (dispatch) => {
    try {
      dispatch({ type: ALL_PRODUCT_REQUEST });

let link = '/products'
      const  {data} = await axios.get(link);
      // console.log(data,'data');
      dispatch({
        type: ALL_PRODUCT_SUCCESS,
        payload: data.products
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCT_FAIL,
        payload: error.response.data.message,
      });
    }
  };
export const getFilterProductsByCategory =(id)=>  async (dispatch) => {
  console.log(id,'idddddddd');
    try {
      dispatch({
        type: FILTER_PRODUCT_BYCATEGORY_SUCCESS,
        payload: id
      });
     
    } catch (error) {
  
    }
  };
export const getFilterProductsBySubCategory =(id)=>  async (dispatch) => {
  console.log(id,'idddddddd');
    try {
      dispatch({
        type: FILTER_PRODUCT_BYSUBCATEGORY_SUCCESS,
        payload: id
      });
     
    } catch (error) {
  
    }
  };

// Get All Products For Admin


// Create Product
export const createProduct = (formData) => async (dispatch,getState) => {
  console.log(formData,'form data');
  try {
    dispatch({ type: NEW_PRODUCT_REQUEST });
    const userFromStorage=localStorage.getItem("user")
    ?JSON.parse(localStorage.getItem("user")):null;
       const config={headers:{
        Authorization:`Bearer ${userFromStorage.token}`
    }}

    const { data } = await axios.post('/admin/product/create',
 
      formData,
      // config
    );
    console.log(data,'product upload');
    console.log(data.success,'data');
    // console.log(data.products,'data');

    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data
     
    });


  } catch (error) {
    console.log(error)
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
      
    });
  }
};

// Update Product
export const updateProduct = (dataProduct) => async (dispatch) => {

// console.log(dataProduct,'ijjj');
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const userFromStorage=localStorage.getItem("user")
    ?JSON.parse(localStorage.getItem("user")):null;
       const config={headers:{
        Authorization:`Bearer ${userFromStorage.token}`
    }}
    const {data}  = await axios.put(
      `/admin/product/${dataProduct._id}`,dataProduct,config);
    console.log(data,'data list product action');

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteProduct = (id) => async (dispatch) => {
  const userFromStorage=localStorage.getItem("user")
  ?JSON.parse(localStorage.getItem("user")):null;
     const config={headers:{
      Authorization:`Bearer ${userFromStorage.token}`
  }}
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    const { data } = await axios.delete(`/admin/product/${id}`,config);

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Products Details
export const getProductDetails = (id) => async (dispatch,getState) => {
  console.log(id);
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });
   

    const { data } = await axios.get(`/admin/product/${id}`);
console.log(data.product,'product detail');
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response,
    });
  }
};

// NEW REVIEW
export const newReview = (reviewData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_REVIEW_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(`/review`, reviewData, config);

    dispatch({
      type: NEW_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: NEW_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All Reviews of a Product
export const getAllReviews = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALL_REVIEW_REQUEST });

    const { data } = await axios.get(`/reviews?id=${id}`);

    dispatch({
      type: ALL_REVIEW_SUCCESS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch({
      type: ALL_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Review of a Product
export const deleteReviews = (reviewId, productId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_REVIEW_REQUEST });

    const { data } = await axios.delete(
      `/reviews?id=${reviewId}&productId=${productId}`
    );

    dispatch({
      type: DELETE_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const productData=(data)=>(dispatch)=>{

  console.log(data,'single product data');
dispatch({type:'PRODUCT_DETAILS_SUCCESS', payload:data})

}
export const abc=()=>async(dispatch)=>{
  dispatch({type:'P_R'})
  const {data}=await axios.get('/products')
  dispatch({type:'P_S', payload:data.products})
  
  console.log(data.products,'P_s');
}
// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
