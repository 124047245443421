import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
} from "../Constant/searchApiConstant";

export const searchReducer = (state = { keyword: [] }, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        keyword: action.payload,
      };
      case SEARCH_FAIL:
        return {
            ...state,
            loading: false,
       
            key: null,
            error: action.payload,
          };
          default:
      return state;
    
  }
  
};
