import { ADMIN_CATEGORY_FAIL, ADMIN_CATEGORY_REQUEST, ADMIN_CATEGORY_SUCCESS, ADMIN_PRODUCT_FAIL, ADMIN_PRODUCT_REQUEST, ADMIN_PRODUCT_SUCCESS, ADMIN_USERS_FAIL, ADMIN_USERS_REQUEST, ADMIN_USERS_SUCCESS, CLEAR_ERRORS } from "../Constant/adminConstant";


export const adminProductsReducer = (state = { products: [] }, action) => {
    switch (action.type) {
      case ADMIN_PRODUCT_REQUEST:
                 return {
            loading: true,
            success:false,
            products: [],
          };
        case ADMIN_PRODUCT_SUCCESS:
          return {
            loading: false,
            success:true,
            products: action.payload,
          
          };
    
      case ADMIN_PRODUCT_FAIL:
     
        return {
          loading: false,
          success:false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

//   get all users
export const adminUsersReducer = (state = { users: [] }, action) => {
    switch (action.type) {
      case ADMIN_USERS_REQUEST:
                 return {
            loading: true,
            success:false,
           users: [],
          };
        case ADMIN_USERS_SUCCESS:
          return {
            loading: false,
            success:true,
          users: action.payload,
          
          };
    
      case ADMIN_USERS_FAIL:
     
        return {
          loading: false,
          success:false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
//   get all users
export const adminCategoryReducer = (state = { category: [] }, action) => {
    switch (action.type) {
      case ADMIN_CATEGORY_REQUEST:
                 return {
            loading: true,
            success:false,
            category: [],
          };
        case ADMIN_CATEGORY_SUCCESS:
          return {
            loading: false,
            success:true,
            category: action.payload,
          
          };
    
      case ADMIN_CATEGORY_FAIL:
     
        return {
          loading: false,
          success:false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };