import React from "react";
import "./Sidebar.css";
import logo from "../../images/logo.png";
import { Link, useParams } from "react-router-dom";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import RateReviewIcon from "@material-ui/icons/RateReview";
import CategoryIcon from '@mui/icons-material/Category';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import UpdateIcon from '@mui/icons-material/Update';


const Sidebar = () => {
  return (
    <>
 
        {/* <div className="container-fluid">
          <div className="row"> */}

          {/* <div className="col-lg-2 col-md-12 col-sm-12"> */}
          <div className="sidebarleft ">
     
          <Link to='/'>
    <img src='../../images/logo.png' className='banner_img text-light bg-light rounded-circle'  
    alt='logo'/>
    </Link>
      <Link to="/admin/dashboard">
        <p>
          <DashboardIcon /> Dashboard
        </p>
      </Link>
      <Link>
        <TreeView
          // defaultCollapseIcon={<Dropdown />}
          defaultCollapseIcon={<PostAddIcon/>}
          defaultExpandIcon={<AddIcon />}
        >
          <TreeItem nodeId="1" label="Products">
            <Link to="/admin/products">
              <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
            </Link>

            <Link to="/admin/add/product">
              <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
            </Link>
         
          </TreeItem>
         
        </TreeView>
      </Link>
    
      <Link>
      <TreeView
          defaultCollapseIcon={<CategoryIcon />}
          // defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="Categories">
          <Link to="/admin/category">
              <TreeItem nodeId="3" label="All" icon={<PostAddIcon />} />
            </Link>
          <Link to="/admin/add/category">
              <TreeItem nodeId="2" label="Create" icon={<AddIcon />} />
            </Link>

            {/* <Link to="/all/categories/delete"> */}
          
      
          </TreeItem>
           

        </TreeView>
        </Link>
     
      <Link>
      <TreeView
          defaultCollapseIcon={<CategoryIcon />}
          // defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="S.category">
          <Link to="/admin/subcategories">
              <TreeItem nodeId="3" label="All" icon={<PostAddIcon />} />
            </Link>
          <Link to="/admin/add/category">
              <TreeItem nodeId="2" label="Create SubCategory" icon={<AddIcon />} />
            </Link>

            {/* <Link to="/all/categories/delete"> */}
          
      
          </TreeItem>
           

        </TreeView>
        </Link>

        <Link>
      <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="Orders">
          <Link to="/admin/orders">
              <TreeItem nodeId="2" label="All Orders" icon={<CategoryIcon />} />
            </Link>
            <Link to="/admin/delete/product">
              <TreeItem nodeId="4" label="Update" icon={<DeleteIcon />} />
            </Link>
          </TreeItem>
           

        </TreeView>
        </Link>
        {/* add banner */}
        <Link>
      <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="Banner">
          <Link to="/admin/get/banner">
              <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
            </Link>

            
            <Link to={'/admin/add/banner'}>
              <TreeItem nodeId="3" label="Add Banner" icon={<AddIcon />} />
            </Link>
           
          </TreeItem>
           

        </TreeView>
        </Link>
        {/* Offer section */}
        <Link>
      <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="Offers">
          <Link to="/admin/add/offer1">
              <TreeItem nodeId="2" label="Add offer1" icon={<CategoryIcon />} />
            </Link>
          <Link to="/admin/add/offer2">
              <TreeItem nodeId="3" label="Add offer2" icon={<CategoryIcon />} />
            </Link>
          <Link to="/admin/add/offer3">
              <TreeItem nodeId="4" label="Add offer3" icon={<CategoryIcon />} />
            </Link>

            
            
           
          </TreeItem>
           

        </TreeView>
        </Link>
{/* user area */}
      <Link to="/admin/users">
        <p>
          <PeopleIcon /> Users
        </p>
      </Link>
      
      <Link to="/admin/reviews">
        <p>
          <RateReviewIcon />
          Reviews
        </p>
      </Link>
    </div>
    {/* </div>
    </div> */}
    {/* </div> */}
  
    </>
  );
};

export default Sidebar;
