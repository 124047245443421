import React, { useEffect } from 'react'
import SideBar from './Sidebar'
import './dashboard.css'
import { Link } from 'react-router-dom'
import { Typography } from "@material-ui/core";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Doughnut, Line } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { getAdminCategory, getAdminProduct, getAdminUsers } from "../../Redux/Action/adminAction";
import { getAllOrders } from "../../Redux/Action/orderAction";
import { getAllUsers } from "../../Redux/Action/userAction";
import Sidebar from "./Sidebar";
import MetaData from "../layout/MetaData";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import { PieChart } from 'react-minimal-pie-chart';
import { Avatar } from "antd";
import { deepSubCategory } from "../../Redux/Action/subCategoryAction";
import { getAdminSubCategories } from '../../Redux/Action/catgoryAction';
const DashBoard = () => {
  const dispatch = useDispatch();
    const {orders}=useSelector(state=>state.allOrdersReducer)
    const  {isAuthenticated,userDetails}= useSelector((state) => state.userLoadReducer);
    const {users}=useSelector(state=>state.adminUsersReducer)
    console.log(users,'orders');
    const {products}=useSelector(state=>state.adminProductsReducer)
  // console.log(users,'users');
  const  {category}= useSelector((state) => state.getCategoryReducer);
  const  {category:subcategory}= useSelector((state) => state.getAllSubCategoryReducer);
  const {banner,loading}=useSelector(state=>state.getBannerReducer)
  
    useEffect(() => {
    dispatch(getAdminProduct());
    dispatch(getAllOrders());
    dispatch(getAdminUsers())
    dispatch(getAdminCategory())
    dispatch(getAdminSubCategories())
  }, [dispatch]);
  return (
  <>
  <MetaData title="Dashboard - Admin Panel" />
  <div className="containerh">
    <div className="dashboard">
  <div className="sidebar-div"><SideBar/></div>
  <div className="dash">
  <div className='inner-dashboard'>
  <Link to="/admin/products">
  
   <p><ShoppingBagIcon/></p>
   <p>Products</p>
   <p className='stock mx-auto'>{products && products.length}</p>
   
 </Link>
  </div>
  <div className='inner-dashboard'>
  <Link to="/admin/orders">
    <p><CardGiftcardIcon/></p>
  <p>Orders</p>
 <p className='stock mx-auto'>{orders && orders.length>=0?orders.length:0}</p>
            </Link>

  </div>
  <div className='inner-dashboard'>
  <Link to="/admin/category">
           <p><CategoryIcon/></p>
           <p>Categories</p>
              <p className='stock mx-auto'>{category && category.length}</p>
          </Link>
  </div>
  <div className='inner-dashboard'>
  <Link to="/admin/subcategories">
           <p><CategoryIcon/></p>
           <p>Sub Categories</p>
              <p className='stock mx-auto'>{subcategory && subcategory.length}</p>
          </Link>
  </div>
  <div className='inner-dashboard'>
  <Link to="/admin/users">
            <p><PeopleIcon/></p>
            <p>Users</p>
             <p className='stock mx-auto'>{users && users.length}</p>
             </Link>
  </div>
  <div className='inner-dashboard'>
  <Link to="/admin/get/banner">
            <p><PeopleIcon/></p>
            <p>Banners</p>
             <p className='stock mx-auto'>{banner && banner.length}</p>
             </Link>
  </div>

  
    
</div>

</div>
  </div>


 
  
  </>
  )
}

export default DashBoard

// import "./dashboard.css";
//

// const DashBoard = () => {
//   const dispatch = useDispatch();
//   const {orders}=useSelector(state=>state.allOrdersReducer)
//   const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer);
//   const {users}=useSelector(state=>state.adminUsersReducer)
//   console.log(users,'orders');
//   const {products}=useSelector(state=>state.adminProductsReducer)
// // console.log(users,'users');
// const {category}=useSelector(state=>state.adminCategoryReducer)
// // console.log(allCategories.length,'catgepry');
//   let outOfStock = 0;

//   products &&
//     products.forEach((item) => {
//       if (item.Stock === 0) {
//         outOfStock += 1;
//       }
//     });

//   useEffect(() => {
//     dispatch(getAdminProduct());
//     dispatch(getAllOrders());
//     dispatch(getAdminUsers())
//     dispatch(getAdminCategory())
//   }, [dispatch]);

// //   let totalAmount = 0;
// //   orders &&
// //     orders.forEach((item) => {
     
// //       totalAmount += item.totalPrice;
// //     });

// // totalAmount = Math.ceil(totalAmount * 10) / 10;





//   // const lineState = {
//   //   labels: ["Initial Amount", "Amount Earned"],
//   //   datasets: [
//   //     {
//   //       label: "TOTAL AMOUNT",
//   //       backgroundColor: ["tomato"],
//   //       hoverBackgroundColor: ["rgb(197, 72, 49)"],
//   //       data:[0,5000]
//   //       // data: [0, totalAmount],
//   //     },
//   //   ],
//   // };



//   return (
// <>
//       <MetaData title="Dashboard - Admin Panel" />
      
//       <div className="dashboard">
//       <Sidebar />
//       <div className="dashboardContainer">
        

// <div className="row">
//      <div className="col-lg-4"><Avatar src={`/uploads/userprofile/${userDetails?.fileName}`} style={{width:70,height:70}} className="text-cente"/>
//       </div>
     
//      <div className="col-lg-8"><Typography component="h1"><p className="text-primary text-capitalize">{userDetails?.name}`s </p>Dashboard</Typography>
//     </div>
//     </div>
//         <div className="dashboardSummary">
//           <div>
//             <p>
//               {/* Total Amount <br /> Rs:{totalAmount} */}
//             </p>
//           </div>
//           <div className="dashboardSummaryBox2">
//             <Link to="/admin/products">
//               <p>Product</p>
//               {/* <p>6</p> */}
//               <p>{products && products.length}</p>
//             </Link>
            
//             </div>
//             <Link to="/admin/orders">
//               <p>Orders</p>
//               <p>{orders && orders.length}</p>
//             </Link>
//             <Link to="/admin/users">
//               <p>Users</p>
//               <p>{users && users.length}</p>
//             </Link>
//             <br/>
//             <Link to="/admin/all/categories">
//               <p>Categories</p>
//               <p>{category && category.length}</p>
//             </Link>
//           </div>
//         </div>

//         <div className="lineChart">
//           {/* <Line data={lineState} /> */}
//         </div>
//         <div className="doughnutChart">


//         </div>
// {/* 
//         <PieChart
//   data={[
//     { title: 'One', value: 10, color: '#E38627' },
//     { title: 'Two', value: 15, color: '#C13C37' },
//     { title: 'Three', value: 20, color: '#6A2135' },
//   ]}
// />; */}

  
//       </div>
//     </div>
//     </>
//   );
// };

// export default DashBoard;
