import React from 'react'
import './props.css'
import Star from './Star'
import { useNavigate } from 'react-router-dom'
import { ADD } from '../../Redux/Action/cartAction'
import { useDispatch, useSelector } from 'react-redux'
const Props = ({productPictures,name,salePrice,sellingPrice,fileName,star,_id}) => {
  console.log(productPictures,name,salePrice,sellingPrice,fileName,star,_id,'props props props');
  const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer)
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const handleClick=(e)=>{
    // console.log(e,'id');
navigate(`/product/detail/${e}`)
  }
  const addToCartHandler = ({quantity,name,productPictures,salePrice,_id}) => {
    dispatch(ADD({quantity:1,name,productPictures,salePrice,_id}));

  };

  const buyNow=()=>{
    if(!userDetails){
      navigate('/user/login')
      if(userDetails){
        navigate('/shipping')
      }
    }
    else{
      navigate('/shipping')
    }
  }
  return (
 <>
  <div className="map-inner-div">
    {/* <div className="div-test">test</div> */}
                <div className='first-div'>
          <div className="image-div" onClick={()=>handleClick(_id)}>
          {fileName}
           {/* {item.fileName2} */}
           </div>
            <div className="name-div" style={{textTransform:'lowercase'}}>
            {name?.length>=40?<p>{name?.substring(0,25)}...</p>:name}
            </div>
           
            <div className="price-div">
                <div className="newPrice">Rs:{salePrice}/-</div>
                <div className="oldPrice text-danger">{salePrice!==sellingPrice?<del>Rs.{sellingPrice}/-</del>:null}</div>
                
            </div>
<div className="star-div">
    <Star star={star}/>
</div>
<div className="buynow">
<div className="buyNow" onClick={(e)=>{addToCartHandler({productPictures,name,salePrice,sellingPrice,_id});buyNow()}}
>Buy Now</div>
<div className="addtocart" onClick={(e)=>{addToCartHandler({productPictures,name,salePrice,sellingPrice,_id})}}>
Add To Cart
</div>

            </div>
            </div>
            {/* second div */}
            {/* <div className="second-div" style={{width:'50px'}}>
            {item.discountInPercent>0?<span>{item.discountInPercent}%</span>:null}
            </div> */}
          
            </div>
 </>
  )
}

export default Props