import { Box, Typography, grid2Classes } from '@mui/material'
import { red } from '@mui/material/colors'
import { DataGrid, GridDeleteIcon } from '@mui/x-data-grid'
import React, { Fragment, useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../../Redux/Action/userAction'
import { Avatar } from 'antd'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from '@mui/icons-material/Person';
import MetaData from '../layout/MetaData'
// import Sidebar from '../DashBoard/Sidebar'
import SideBar from "../DashBoard/Sidebar";
import { Link, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import { Button } from "@material-ui/core";
import { useAlert } from 'react-alert'
import { getAdminCategory } from '../../Redux/Action/adminAction'
import { confirmAlert } from 'react-confirm-alert'


const CategoryList = () => {
  const alert=useAlert()
   const handleDelete = async (id) => {
    const userFromStorage=localStorage.getItem("user")
    ?JSON.parse(localStorage.getItem("user")):null;
       const config={headers:{
        Authorization:`Bearer ${userFromStorage.token}`
    }}
    await axios.delete(`/admin/category/${id}`,config)
  }
 
  
    const [pageSize, setPageSize] = useState(5)
    const [rowId, setRowId] = useState(null)
    const  {category}= useSelector((state) => state.getCategoryReducer);
    // console.log(category,'admin categoory');
   const dispatch=useDispatch()
    useEffect(() => {
        dispatch(getAdminCategory())
// setSelectedLink(link)
// if(user.length===0) 
    }, [dispatch])
    const columns=useMemo(()=>[
        {field:'fileName', headerName:'Avatar', width:160,
    renderCell:(params)=><NavLink to={`/admin/users/${params.id}`}>
      {params.row.fileName?<Avatar src={`/uploads/categories/${params.row.fileName}`}/>:<PersonIcon/>}</NavLink>,
    sortable:false,filterable:false,
    },
        {field:'_id', headerName:'Id', flex:1},
        {field:'name', headerName:'Name',flex:1},
       
        // {field:'role', headerName:'Role', width:60, type:'singleSelect', valueOptions:['user','admin'],editable:true },
    {
      field: "actions",flex:1,headerName: "Actions",minWidth: 150,type: "number",sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
             <MetaData title={`Category List --Admin`} />
            <Link to={`/admin/update/category/${params.id}`}>
              <EditIcon style={{color:'green'}}/>
            </Link>

            <Button
              // onClick={Navigate(params.id) }
            >
              <Link to={`/admin/delete/category/${params.id}`}>
              <DeleteIcon style={{color:'red'}}/></Link>
            </Button>
          </Fragment>
        );
      },
    },
  ])

  return (

<Fragment>
      <MetaData title={`ALL PRODUCTS - Admin`} />

      <div className="dashboard">
        <SideBar />
        <div className="productListContainer">
          <h1 id="productListHeading">Categories</h1>

          <DataGrid
            rows={category}
            columns={columns}
            getRowId={row=>row._id}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </Fragment>


  )
}

export default CategoryList