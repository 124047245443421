import { Box, Typography, grid2Classes } from '@mui/material'
import { red } from '@mui/material/colors'
import { DataGrid, GridDeleteIcon } from '@mui/x-data-grid'
import React, { Fragment, useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../../Redux/Action/userAction'
import { Avatar } from 'antd'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from '@mui/icons-material/Person';
import MetaData from '../layout/MetaData'
// import Sidebar from '../DashBoard/Sidebar'
import SideBar from "../DashBoard/Sidebar";
import { Link, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import { Button } from "@material-ui/core";
import { useAlert } from 'react-alert'
import { getAdminCategory } from '../../Redux/Action/adminAction'
import { getAdminSubCategories } from '../../Redux/Action/catgoryAction'
import { confirmAlert } from 'react-confirm-alert'


const SubCategoryList = () => {
  const alert=useAlert()
  //  const handleDelete = async (id) => {
  //   const userFromStorage=localStorage.getItem("user")
  //   ?JSON.parse(localStorage.getItem("user")):null;
  //      const config={headers:{
  //       Authorization:`Bearer ${userFromStorage.token}`
  //   }}
  //   await axios.delete(`/admin/category/subcategory/${id}`,config)
  //     .then((res) => {
  //       alert.success("Product deleted Successfully");
  //     });
  // };
  // const Delete = (id) => {

    // confirmAlert({
    //   title: 'Confirm to submit',
    //   icon:'success',
    //   message: 'Are you sure to do this.',
      // buttons: [
      //   {
      //     label: 'Yes',
      //     onClick: () => handleDelete(id)
      //   },
      //   {
      //     label: 'No',

      //   }
      // ]
    // });
  // }
    const [pageSize, setPageSize] = useState(5)
    const [rowId, setRowId] = useState(null)
    const {category}=useSelector(state=>state.getAllSubCategoryReducer)
    console.log(category,'admin categoory');
   const dispatch=useDispatch()
    useEffect(() => {
        dispatch(getAdminSubCategories())
    }, [dispatch])
    const columns=useMemo(()=>[
        {field:'fileName', headerName:'Avatar', width:160,
    renderCell:(params)=><NavLink to={`/admin/users/${params.id}`}>
      {params.row.fileName?<Avatar src={`/uploads/subcategory/${params.row.fileName}`}/>:<PersonIcon/>}</NavLink>,
    sortable:false,filterable:false,
    },
      //   {field:'parentId.name', headerName:'Category', flex:1,
      //   // renderCell:(params)=>console.log(params.row.parentId,'req.pRAMS.PARENTID')
      //   renderCell:(params)=>params.row.parentId?params.row.parantId.name:null
      // },
        {field:'name', headerName:'Name',flex:0.3},
       
        // {field:'role', headerName:'Role', width:60, type:'singleSelect', valueOptions:['user','admin'],editable:true },
    {
      field: "actions",flex:0.3,headerName: "Actions",minWidth: 150,type: "number",sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/admin/update/subcategory/${params.id}`}>
              <EditIcon style={{color:'green'}}/>
            </Link>

            <Button
              // onClick={Navigate(params.id) }
            >
              <Link to={`/admin/delete/subcategory/${params.id}`}>
              <DeleteIcon style={{color:'red'}}/></Link>
            </Button>
          </Fragment>
        );
      },
    },
  ],[])

  return (

<Fragment>
      <MetaData title={`Sub Category List - Admin`} />

      <div className="dashboard">
        <SideBar />
        <div className="productListContainer">
          <h1 id="productListHeading">Categories</h1>

          <DataGrid
            rows={category}
            columns={columns}
            getRowId={row=>row._id}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </Fragment>


  )
}

export default SubCategoryList