import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSingleOrderDetails, updateOrder } from '../../Redux/Action/orderAction'
import './singleOrder.css'

const SingleOrder = () => {
    const {singleOrder}=useSelector(state=>state.singleOrderReducer)
    const [orderStatus,setOrderStatus]=useState(singleOrder.orderStatus)
     const {id}=useParams()
    const {_id}=singleOrder
    const dispatch=useDispatch()
    useEffect(() => {
  dispatch(getSingleOrderDetails(id))
    }, [dispatch,id])
    const handleClick=()=>{
        dispatch(updateOrder({_id,orderStatus}))
    }

useEffect(() => {

}, [])

  return (
  <>
  {/* {JSON.stringify(singleOrder)} */}
  <div className="single-order">
  <table className='w-100'>
    <tr>
    <th>Name</th>
    <th>City</th>
    <th>Phone Number</th>
    <th>Order Date</th>
    <th>Order Status</th>
    </tr>
    <tr>
      <td>{singleOrder?.shippingInfo?.name}</td>
      <td>{singleOrder?.shippingInfo?.city}</td>
      <td>{singleOrder?.shippingInfo?.phoneNo}</td>
      <td>{singleOrder?.creationDate}</td>
      <td>{singleOrder?.orderStatus}</td>
  
    </tr>
  </table>
  </div>



<div className='order-status'>
<select  onChange={(e)=>setOrderStatus(e.target.value)}>
<option value={singleOrder?.orderStatus}>{singleOrder?.orderStatus}</option>

<option value='Processing'>Processing</option>
<option value='Shipping'>Shipping</option>
<option value='Delivered'>Delivered</option>

</select>

<button className='btn btn-primary m-3' onClick={handleClick}>submit</button></div>
  </>
  )
}

export default SingleOrder