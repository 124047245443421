import React, { useEffect, useState } from 'react'
import {useFormik,} from 'formik'
import * as Yup from 'yup'
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, addSubategoryAction, getAllCategory } from '../../Redux/Action/catgoryAction';
import { useAlert } from 'react-alert';
import Swal from 'sweetalert2'
import MetaData from '../layout/MetaData';
const AddSubCategory = () => {
    const  {userDetails}= useSelector((state) => state.userLoadReducer);
    const  {category:category1}= useSelector((state) => state.getCategoryReducer);
    const  {loading,success,addSubCategory}= useSelector((state) => state.addSubCategoryReducer);
    console.log(loading,success,addSubCategory,'loading,success,addSubCategory');
    const [preview, setPreview] = useState('')
    const [fileName, setFileName] = useState('')
    const [user, setUser] = useState(userDetails._id)
const dispatch=useDispatch()
const alert=useAlert()
    // console.log(userDetails._id,'user details,reducers');
    const {handleBlur,handleReset,handleSubmit,setFieldValue,handleChange,values,touched,errors}=useFormik({
        initialValues:{
         name:'',categoryImage:'',category:''
          },
          validationSchema:Yup.object().shape({
     name:Yup.string().min(3,'please enter atleast 4 characters').required('name is required'),
  category:Yup.string().required('category is required'),
    categoryImage:Yup.string().required('picture is required'),
    // user:Yup.string().required('picture is required'),
      
               
        }),
         onSubmit:(values)=>{
        //  console.log(values,fileName,user,'val');
        const {name,category,categoryImage}=values
             dispatch(addSubategoryAction({name,category,categoryImage,fileName,user}))
           
            }})
            useEffect(() => {
              setUser(userDetails._id)
              if(success===true && loading===false){
                Swal.fire({
                  icon:'success',
                  title: "Category Added",
                  // text: "Modal with a custom image.",
                  // imageUrl: "https://unsplash.it/400/200",
                  // imageWidth: 400,
                  // imageHeight: 200,
                  // imageAlt: "Custom image"
                });
              }
              if(success===false && loading===false){
                alert.error('category not created')
              }
                }, [alert,success,loading,userDetails._id])
            
  return (
  <>
   <MetaData title={`Add Sub Category`} />
 <div className="container-fluid mt-5 h-auto">
  <div className="row">
    <div className="col-lg-2 col-sm-12 col-md-2 bg-transparent">side bar</div>
    <div className="col-lg-10 col-sm-12 col-md-10">
<section className="register">
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-12 col-xl-11" >
        <div className="card text-black w-100" style={{background:'none',border:'none'}}>
          <div className="card-bo p-md-5">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">

                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Add Sub Category <br/> {`${userDetails.name}`}</p>
      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
      {/* <div className="d-flex flex-row align-items-center mb-4">
<div className='col-3'> <label className="form-label" for="form3Example1c">User Name</label></div>
            <div className="form-outline flex-fill mb-0">
            <input type="text" readOnly className="form-control ms-3" value={userDetails.name}
onChange={handleChange}  onBlur={handleBlur}  />
       <div className='error' style={{color:'red'}}>
      {touched.category && errors.category ? errors.category:''}
    </div>
  </div>
</div> */}

      <div className="d-flex flex-row align-items-center mb-4">
<div className='col-3'> <label className="form-label" for="form3Example1c">category</label></div>
            <div className="form-outline flex-fill mb-0">
                <select  className="ms-3"                
                onChange={handleChange}  onBlur={handleBlur} name='category'>
                    <option value="">select category</option>
                    {
                      category1&&category1?.map((item)=>(
                       
                        <>
                         <option value={item._id}>{item.name}</option>
                        </>
                      ))
                      
                    }
                   
                </select>
       <div className='error' style={{color:'red'}}>
      {touched.category && errors.category ? errors.category:''}
    </div>
  </div>
</div>

<div className="d-flex flex-row align-items-center mb-4">
<div className='col-3'> <label className="form-label" for="form3Example1c">Sub Category Name</label></div>
            <div className="form-outline flex-fill mb-0">
    <input type="text" className="form-control ms-3"
onChange={handleChange}  onBlur={handleBlur} name='name' />
    <div className='error' style={{color:'red'}}>
      {touched.name && errors.name ? errors.name:''}
    </div>
  </div>
</div>


<div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">upload Image</label>
                  </div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="file" className="form-control ms-3" name='categoryImage'                
                    onChange={(e)=>{
                      let reader= new FileReader();
                      // console.log(reader,'reader');
                      reader.onload=(e)=>{
                  if(reader.readyState===2){
                          setPreview(reader.result)
                          setFieldValue('categoryImage',(reader.result));}
                      }
                      reader.readAsDataURL(e.currentTarget.files[0]) 
                      setFileName(e.target.files[0].name)
                      }}
                    onBlur={handleBlur}
                    />
                      <div className='error' style={{color:'red'}}>
                        {touched.categoryImage && errors.categoryImage ? errors.categoryImage:''}
                      </div>
               
                    
                    </div> 
                  </div>
                        {/* <div className='text-center mb-3'> {preview!==''?<img src={preview}/>:<h6>no picture found</h6>}
                 </div> */}
                 <button type="submit" className="btn btn-primary btn-lg">Upload</button>
</form>
</div></div></div></div></div></div></div></section></div></div></div>
  </>
  )
}

export default AddSubCategory