import React from 'react'
import GetBanner from '../Banner/GetBanner'
import MetaData from '../layout/MetaData'
import GetOffer1 from '../Offers/GetOffer1'
import GetOffer2 from '../Offers/GetOffer2'
import GetOffer3 from '../Offers/GetOffer3'
import NewArrival from './NewArrival'
import DealOfTheDay from './DealOfTheDay'
import Cosmetics from './Costmetics'
import Kids from './KidsAndBabies'
import HomeAppliances from './HomeAppliances'
import WomenFashion from './WomenFashion'
import MenFashion from './MenFashion'
import MobileAndTablet from './MobileAndTablet'


const MainPage = () => {
  return (
    <>
   <MetaData title={`Home`}/>
   <GetBanner/>
  <DealOfTheDay/>
  <hr/>
  <GetOffer1/>
  <hr/>
  <NewArrival/>
  <hr/>
  <Cosmetics/>
  <hr/>
  <Kids/>
  <hr/>
  <HomeAppliances/>
  <hr/>
  <GetOffer2/>
  <hr/>
  <WomenFashion/>
  <hr/>
  <MenFashion/>
  <hr/>
  <MobileAndTablet/>
  <hr/>
  <GetOffer3/>
  <hr/>
    </>
  )
}

export default MainPage