import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getOffer2Action } from '../../Redux/Action/offerAction'

const GetOffer2 = () => {
  
  const {offer2}=useSelector(state=>state.getOffer2Reducer)
  console.log(offer2,);
  const dispatch=useDispatch()
  useEffect(() => {
dispatch(getOffer2Action())
  }, [dispatch])
  return (
    <>
    {
                    offer2 && offer2?.map((imag, i) => {
                        return (
                            <>
                          <div className="banner-area products_page mb-3 m-auto">
                                <Link to='/'>
                                    
                                    <img key={i} className="card-img-top img-fluid banner-image" 
                                    src={`/uploads/offer2/${imag.fileName}`} 
                                    alt={imag && imag.name}/>
                                    
                                
                                    </Link>
                       
                                    </div>
                        
                            
                            </>
                        )
                    })

                }
    
    
    
    </>
  )
}

export default GetOffer2