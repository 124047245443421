import axios from 'axios'
import React,{useEffect, useState} from 'react'
import {DatePicker} from 'antd'
// import moment from 'moment'
import './Register.css'
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import {useFormik,useField, useFormikContext} from 'formik'
import * as Yup from 'yup'
import Loader from '../layout/Loader/Loader'
import { useDispatch , useSelector} from 'react-redux'
import { register } from '../../Redux/Action/userAction';
import { useAlert } from 'react-alert';
const Register = ({...props}) => {
  const alert=useAlert()
  const {loading,isAuthenticated,userRgister,error}=useSelector(state=>state.userRegisterReducer)
  console.log(error,'error');
  const [preview, setPreview] = useState('')
  const [fileName, setFileName] = useState('')
  const dispatch=useDispatch()
  const nameRegExp=/^(?:[\u00c0-\u01ffa-zA-Z'-]){3,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{3,})+$/i
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const emailRegExp=(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
  const imageRegExp=("[^\\s]+(.*?)\\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$")


  const {handleBlur,handleReset,handleSubmit,setFieldValue,handleChange,values,touched,errors}=useFormik({
 
  initialValues:{
    name:'',shopName:'',email:'',password:'',cpassword:'',
    userName:'',phoneNumber:'',address:'',userProfile:'',age:'',},
    validationSchema:Yup.object().shape({
      name:Yup.string().min(3).max(20).matches(nameRegExp, 'please provide a valid name').required('please enter full name Muhammad Ali'),
      shopName:Yup.string().min(4,'please enter atleast 4 characters').max(20,'maximum 20 charactors are allowed').required('Shop Name must be required'),
      email:Yup.string().email().matches(emailRegExp,'please provide a valid email').required('email is required'),
      password: Yup.string().min(6,'password should be atleast 6 characters').max(15,'maximum characters are allowed 15').required('Password is required'),
      cpassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('confirm password is required'),
      userName:Yup.string().min(4,'please enter atleast 4 characters').max(15,'maximum characters are allowed 15').required('User Name is required'),
     phoneNumber:Yup.string().min(8).max(15).matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
      address:Yup.string().min(15,'please enter atleast 4 characters').required('Address is required'),
      userProfile:Yup.string().required('image is required'),
      age:Yup.string().required('valid date of birth required'),
      gender:Yup.string().required('gender required'),
    //  fileName:Yup.string()
    }),
   onSubmit:(val)=>{
  //  console.log(val,'val');
       dispatch(register({val,fileName}))
     
      }})
 

useEffect(() => {
if(isAuthenticated===false && loading===false){
  alert.error('user registration failed')
  alert.error(error)
}
if(isAuthenticated===true){
  alert.success('user registerd successfully')
}
}, [dispatch,alert,isAuthenticated,loading,error])

return(
<>
{/* {loading?<Loader/>:
( */}

<section className="register">
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-12 col-xl-11" >
        <div className="card text-black w-100" style={{background:'none',border:'none'}}>
          <div className="card-bo p-md-5">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">

                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>

                <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>

                  <div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Full Name</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='Full Name'
                 onChange={handleChange}  onBlur={handleBlur} name='name' />
                      <div className='error' style={{color:'red'}}>
                        {touched.name && errors.name ? errors.name:''}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-row align-items-center mb-4">
<div className='col-3'> <label className="form-label" for="form3Example1c">Gender</label></div>
            <div className="form-outline flex-fill mb-0">
                <select  className="ms-3"                
                onChange={handleChange}  onBlur={handleBlur} name='gender'>
                    <option value="">select category</option>
                    <option value="Male">Male</option>
                    <option value="feMale">Female</option>
                    <option value="Not Mention">Not Recmonded</option>
                                   </select>
       <div className='error' style={{color:'red'}}>
      {touched.parentId && errors.parentId ? errors.parentId:''}
    </div>
  </div>
</div>

                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">Your Age</label></div>
                    <div className="form-outline flex-fill mb-0">
                   
                    <input type='date'  className="form-control ms-3"
                                       
     onChange={handleChange} onBlur={handleBlur} name='age'/>
                      <div className='error' style={{color:'red'}}>
                        {touched.age && errors.age ? errors.age:''}
                      </div>
                     
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">Shop Name</label></div>
                    <div className="form-outline flex-fill mb-0">
                   
                    <input type="text" className="form-control ms-3" placeholder=' Shop Name'
                 onChange={handleChange}  onBlur={handleBlur} name='shopName' />
                      <div className='error' style={{color:'red'}}>
                        {touched.shopName && errors.shopName ? errors.shopName:''}
                      </div>
                     
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c"><em>Email</em></label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="text" className="form-control ms-3" placeholder='Email'
                 onChange={handleChange}  onBlur={handleBlur} name='email' />
                      <div className='error' style={{color:'red'}}>
                        {error}
                        {touched.email && errors.email ? errors.email:''}
                      </div>
                      
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">password</label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="password" className="form-control ms-3" placeholder='Password'
                 onChange={handleChange}  onBlur={handleBlur} name='password' />
                      <div className='error' style={{color:'red'}}>
                        {touched.password && errors.password ? errors.password:''}
                      </div>
               
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">confirm password</label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="password" className="form-control ms-3" placeholder='Confirm Password'
                 onChange={handleChange}  onBlur={handleBlur} name='cpassword' />
                      <div className='error' style={{color:'red'}}>
                        {touched.cpassword && errors.cpassword ? errors.cpassword:''}
                      </div>
                     
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">user name</label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="text" className="form-control ms-3" placeholder='User Name'
                 onChange={handleChange}  onBlur={handleBlur} name='userName' />
                      <div className='error' style={{color:'red'}}>
                        {touched.userName && errors.userName ? errors.userName:''}
                      </div>
                   
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">phone number</label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="number" className="form-control ms-3" placeholder='Phone Number'
                 onChange={handleChange}  onBlur={handleBlur} name='phoneNumber' />
                      <div className='error' style={{color:'red'}}>
                        {touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber:''}
                      </div>
                     
                    </div>
                  </div>

                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">address</label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="text" className="form-control ms-3" placeholder='Address'
                 onChange={handleChange}  onBlur={handleBlur} name='address' />
                      <div className='error' style={{color:'red'}}>
                        {touched.address && errors.address ? errors.address:''}
                      </div>
                     
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">upload Image</label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="file" className="form-control ms-3"                   
                    onChange={(e)=>{
                      let reader= new FileReader();
                      // console.log(reader,'reader');
                      reader.onload=(e)=>{
                  if(reader.readyState===2){
                          setPreview(reader.result)
                          setFieldValue('userProfile',(reader.result));}
                      }
                      if(e.currentTarget.files[0].size>200000){
                        alert.error('image size is greater than 2MB')
                        
                        return;
                      }
                      else{
                        reader.readAsDataURL(e.currentTarget.files[0]) 
                      }
                      
                      setFileName(e.target.files[0].name)
                      }}
                    />
                      <div className='error' style={{color:'red'}}>
                        {touched.userProfile && errors.userProfile ? errors.userProfile:''}
                      </div>
                  
                    
                    </div>
                  </div>
           
                 <div className='text-center mb-3'> 
                 {preview!==''?<img src={preview} style={{height:'75px',width:'75px',borderRadius:'20px'}}/>:<h6>no picture found</h6>}
                 
                 </div>
                     <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                    <button type="submit" className="btn btn-primary btn-lg">Register</button>
                    <button className='btn btn-primary ms-3' type="reset"onReset={handleReset}>Reset</button>
                  </div>

                </form>

              </div>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/* )} */}
</>
)}
export default Register
