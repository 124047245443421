import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  newReviewReducer,
  productsFilterReducer,
  bigDeal,
  productsReducer,
  productDetailsReducer,
  newProductFilterReducer,
  newProductReducer,

  productReducer,
} from "./Redux/Reducers/productListReducers";
import {
  categoryListReducer,
  categoryDetailsReducers,
  getCategoriesDetailsReducer,
  getSubCategoriesDetailsReducer,
  getAllSubCategoryReducer,
  updateSubCategoriesDetailsReducer,
  updateCategoriesReducer,
} from "./Redux/Reducers/categoryreducer";
import { cartReducer } from "./Redux/Reducers/cartReducers";
import {
  allUsersReducer,
  profileReducer,
  userDetailsReducer,
  userLoadReducer,
  userReducer,
} from "./Redux/Reducers/userReducer";
import {
  allOrdersReducer,
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
  orderFilterReducer,
  orderReducer,
  singleOrderReducer,
} from "./Redux/Reducers/orderReducer";

import {
  getBannerReducer,
  postBannerReducer,
  updateBannerReducer,
} from "./Redux/Reducers/bannerReducer";
import { searchReducer } from "./Redux/Reducers/searchApiReducer";
import { filterPrice } from "./Redux/Reducers/filterPriceReducer";
import {

  getOffer2Reducer,
  getOffer3Reducer,
  getOffer1Reducer,
} from "./Redux/Reducers/offerReducer";
import { userProductReducer } from "./Redux/Reducers/getUserProduct";
import {
  userLoginReducer,
  userRegisterReducer,
} from "./Redux/Reducers/userReducer";
import {
  addSubCategoryReducer,
  getCategoryReducer,
  getSubCategoryReducer,
  newCategoryReducer,
} from "./Redux/Reducers/categoryreducer";
import { getSingleBannerReducer } from "./Redux/Reducers/bannerReducer";
import {
  adminCategoryReducer,
  adminProductsReducer,
  adminUsersReducer,
} from "./Redux/Reducers/adminReducers";

const shippingInfo = localStorage.getItem("shippingInfo")
  ? JSON.parse(localStorage.getItem("shippingInfo"))
  : [];

const cartitemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];
// user
// const userFromStorage = localStorage.getItem("user")
//   ? JSON.parse(localStorage.getItem("user"))
//   : null;

const reducer = combineReducers({
  userRegisterReducer: userRegisterReducer,
  userLoginReducer: userLoginReducer,
  userLoadReducer: userLoadReducer,
  newCategoryReducer: newCategoryReducer,
  getCategoryReducer: getCategoryReducer,
  addSubCategoryReducer: addSubCategoryReducer,
  getSubCategoryReducer: getSubCategoryReducer,
  allUsersReducer: allUsersReducer,
  postBannerReducer: postBannerReducer,
  getSingleBannerReducer: getSingleBannerReducer,
  productsReducer: productsReducer,
  productReducer: productReducer,
  newProductReducer: newProductReducer,
  getBannerReducer: getBannerReducer,
  productDetails: productDetailsReducer,
  cart: cartReducer,
  profileReducer: profileReducer,
  orderDetails: orderDetailsReducer,
  myOrdersReducer: myOrdersReducer,
  allOrdersReducer: allOrdersReducer,
  productsFilterReducer: productsFilterReducer,
  order: orderReducer,
  newReview: newReviewReducer,
  searchReducer: searchReducer,
  filterPrice: filterPrice,
  getOffer2Reducer: getOffer2Reducer,
  getOffer3Reducer: getOffer3Reducer,
  userProductRed: userProductReducer,
  adminProductsReducer: adminProductsReducer,
  adminUsersReducer: adminUsersReducer,
  adminCategoryReducer: adminCategoryReducer,
  getCategoriesDetailsReducer:getCategoriesDetailsReducer,
  getSubCategoriesDetailsReducer:getSubCategoriesDetailsReducer,
  updateBannerReducer:updateBannerReducer,
  getAllSubCategoryReducer:getAllSubCategoryReducer,
  updateSubCategoriesDetailsReducer:updateSubCategoriesDetailsReducer,
  updateCategoriesReducer:updateCategoriesReducer,
  getOffer1Reducer:getOffer1Reducer,
  orderFilterReducer:orderFilterReducer,
  singleOrderReducer:singleOrderReducer,
  newOrderReducer:newOrderReducer,


});
let initialState = {
  cart: { cartItems: cartitemsFromStorage, shippingInfo: shippingInfo },
  // user: { user: userFromStorage },
};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
