import { Box, Typography, grid2Classes } from '@mui/material'
import { red } from '@mui/material/colors'
import { DataGrid, GridDeleteIcon } from '@mui/x-data-grid'
import React, { Fragment, useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../../Redux/Action/userAction'
import { Avatar } from 'antd'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import MetaData from '../layout/MetaData'
// import Sidebar from '../DashBoard/Sidebar'
import SideBar from "../DashBoard/Sidebar";
import { Link, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import { Button } from "@material-ui/core";
import { useAlert } from 'react-alert'
import { getBannerAction } from '../../Redux/Action/bannerAction'
import Loader from '../layout/Loader/Loader'
import { confirmAlert } from 'react-confirm-alert'

const BannerDelete = () => {
  const alert=useAlert()
   
  // const {id}=useParams()
  // console.log(id,'params');
  
  const {banner,loading}=useSelector(state=>state.getBannerReducer)
   const dispatch=useDispatch()
    useEffect(() => {
      
        dispatch(getBannerAction())

    }, [dispatch])
    let link=``
    const columns=useMemo(()=>[
        {field:'file', headerName:'Avatar', width:160,
    renderCell:(params)=>
    
      <img src={`/uploads/banner/${params.row.fileName}`} style={{height:100,width:100}}/>,
    sortable:false,filterable:false,
    },
        // {field:'_id', headerName:'Id', width:260},
        {field:'name', headerName:'Name', width:160},
        
    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/admin/update/banner/${params.id}`}>
              <EditIcon style={{color:'green'}}/>
            </Link>

 
            <Button
              // onClick={Navigate(params.id) }
            >
              <Link to={`/admin/delete/banner/${params.id}`}>
              <DeleteIcon style={{color:'red'}}/></Link>
            </Button>
          </Fragment>
        );
      },
    },
  ])

  return (

<Fragment>
      <MetaData title={`ALL BANNERS - Admin`} />

     {
      loading?<Loader/>: <div className="dashboard">
      <SideBar />
      <div className="productListContainer">
        <h1 id="productListHeading">ALL Banners</h1>

        <DataGrid
          rows={banner}
          columns={columns}
          getRowId={row=>row._id}
          pageSize={10}
          disableSelectionOnClick
          className="productListTable"
          autoHeight
        />
      </div>
    </div>
     }
    </Fragment>


  )
}

export default BannerDelete