import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../layout/MetaData";
import { Link, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";

import {
  getOrderDetails,
  clearErrors,
  updateOrder,
} from "../../Redux/Action/orderAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { Button } from "@material-ui/core";
import { UPDATE_ORDER_RESET } from "../../Redux/Constant/orderConstant";
import "./processOrder.css";
import Sidebar from "../DashBoard/Sidebar";

const ProcessOrder = () => {
  const { order, error, loading } = useSelector((state) => state.orderDetails);
  const { error: updateError, isUpdated } = useSelector((state) => state.order);
  const [status, setStatus] = useState("");
const {id}=useParams()
// console.log(id,'id id id');
const getOrder=()=>{
  setStatus(order.name)
}
useEffect(() => {
getOrder()
}, [])
const dispatch = useDispatch();
  const updateOrderSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", status);

    dispatch(updateOrder(id,status));
  };
//   const updateOrderSubmitHandler=(e)=>{
//     e.preventDefault();
   
// dispatch(updateOrder(id,status))
// }
  
  const alert = useAlert();

  

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Order Updated Successfully");
      dispatch({ type: UPDATE_ORDER_RESET });
    }

    dispatch(getOrderDetails(id));
  }, [dispatch, alert, error, id, isUpdated, updateError]);

  return (
    <Fragment>
      <MetaData title="Process Order" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form onSubmit={updateOrderSubmitHandler}>
          <input value={status} onChange={(e)=>setStatus((e.target.value))}/>
          <button type="submit">submit</button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ProcessOrder;
