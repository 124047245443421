export const ADMIN_PRODUCT_REQUEST = "ADMIN_PRODUCT_REQUEST";
export const ADMIN_PRODUCT_SUCCESS = "ADMIN_PRODUCT_SUCCESS";
export const ADMIN_PRODUCT_FAIL = "ADMIN_PRODUCT_FAIL";

export const ADMIN_BANNER_REQUEST = "ADMIN_BANNER_REQUEST";
export const ADMIN_BANNER_SUCCESS = "ADMIN_BANNER_SUCCESS";
export const ADMIN_BANNER_FAIL = "ADMIN_BANNER_FAIL";

export const ADMIN_CATEGORY_REQUEST = "ADMIN_CATEGORY_REQUEST";
export const ADMIN_CATEGORY_SUCCESS = "ADMIN_CATEGORY_SUCCESS";
export const ADMIN_CATEGORY_FAIL = "ADMIN_CATEGORY_FAIL";

export const ADMIN_SUBCATEGORY_REQUEST = "ADMIN_SUBCATEGORY_REQUEST";
export const ADMIN_SUBCATEGORY_SUCCESS = "ADMIN_SUBCATEGORY_SUCCESS";
export const ADMIN_SUBCATEGORY_FAIL = "ADMIN_SUBCATEGORY_FAIL";

export const ADMIN_OFFER1_REQUEST = "ADMIN_OFFER1_REQUEST";
export const ADMIN_OFFER1_SUCCESS = "ADMIN_OFFER1_SUCCESS";
export const ADMIN_OFFER1_FAIL = "ADMIN_OFFER1_FAIL";

export const ADMIN_OFFER2_REQUEST = "ADMIN_OFFER2_REQUEST";
export const ADMIN_OFFER2_SUCCESS = "ADMIN_OFFER2_SUCCESS";
export const ADMIN_OFFER2_FAIL = "ADMIN_OFFER2_FAIL";

export const ADMIN_OFFER3_REQUEST = "ADMIN_OFFER3_REQUEST";
export const ADMIN_OFFER3_SUCCESS = "ADMIN_OFFER3_SUCCESS";
export const ADMIN_OFFER3_FAIL = "ADMIN_OFFER3_FAIL";

export const ADMIN_USERS_REQUEST = "ADMIN_USERS_REQUEST";
export const ADMIN_USERS_SUCCESS = "ADMIN_USERS_SUCCESS";
export const ADMIN_USERS_FAIL = "ADMIN_USERS_FAIL";

export const CLEAR_ERRORS="CLEAR_ERROR"

