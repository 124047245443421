import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getOffer3Action } from '../../Redux/Action/offerAction'

const GetOffer3 = () => {
  
  const {offer3}=useSelector(state=>state.getOffer3Reducer)
  console.log(offer3,);
  const dispatch=useDispatch()
  useEffect(() => {
dispatch(getOffer3Action())
  }, [dispatch])
  return (
    <>
    {
                    offer3 && offer3?.map((imag, i) => {
                        return (
                            <>
                          <div className="banner-area products_page mb-3 m-auto">
                                <Link to='/'>
                                    
                                    <img key={i} className="card-img-top img-fluid banner-image" 
                                    src={`/uploads/offer3/${imag.fileName}`} 
                                    alt={imag && imag.name}/>
                                    
                                
                                    </Link>
                       
                                    </div>
                        
                            
                            </>
                        )
                    })

                }
    
    
    
    </>
  )
}

export default GetOffer3