import { Box, Typography, grid2Classes } from '@mui/material'
import { red } from '@mui/material/colors'
import { DataGrid, GridDeleteIcon } from '@mui/x-data-grid'
import React, { Fragment, useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../../Redux/Action/userAction'
import { Avatar } from 'antd'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from '@mui/icons-material/Person';
import MetaData from '../layout/MetaData'
// import Sidebar from '../DashBoard/Sidebar'
import SideBar from "../DashBoard/Sidebar";
import { Link, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import { Button } from "@material-ui/core";
import { useAlert } from 'react-alert'
import { getAdminUsers } from '../../Redux/Action/adminAction'
import { confirmAlert } from 'react-confirm-alert'

const UserList = () => {
  const alert=useAlert()
   const handleDelete = async (id) => {
    await axios.delete(`/admin/users/${id}`)
      .then((res) => {
        alert.success("Product deleted Successfully");
      });
  };
  const Delete = (id) => {

    confirmAlert({
      title: 'Confirm to submit',
      icon:'success',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDelete(id)
        },
        {
          label: 'No',

        }
      ]
    });}
    const [pageSize, setPageSize] = useState(5)
    const [rowId, setRowId] = useState(null)
    const {users}=useSelector(state=>state.adminUsersReducer)
    console.log(users,'admin users');
   const dispatch=useDispatch()
    useEffect(() => {
        dispatch(getAdminUsers())
// setSelectedLink(link)
// if(user.length===0) 
    }, [dispatch])
    const columns=useMemo(()=>[
        {field:'fileName', headerName:'Avatar', width:160,
    renderCell:(params)=><NavLink to={`/admin/users/${params.id}`}>
      {params.row.fileName?<Avatar src={`/uploads/userprofile/${params.row.fileName}`}/>:<PersonIcon/>}</NavLink>,
    sortable:false,filterable:false,
    },
        {field:'_id', headerName:'Id', width:60},
        {field:'name', headerName:'Name', width:60},
        {field:'email', headerName:'Email', width:100},
        {field:'active', headerName:'Active', width:100,
    type:'boolean',editable:true
    },
        {field:'userName', headerName:'UserName', width:60},
        {field:'phoneNumber', headerName:'PhoneNumber', width:100},
        {field:'address', headerName:'Address', width:160},
        {field:'createdAt', headerName:'CreatedBy', width:160},
        {field:'role', headerName:'Role', width:60,
        type:'singleSelect', valueOptions:['user','admin'],editable:true
    
    },
    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/admin/users/update/${params.id}`}>
              <EditIcon style={{color:'green'}}/>
            </Link>

            <Button
              onClick={() =>Delete(params.id) }
            >
              <DeleteIcon style={{color:'red'}}/>
            </Button>
          </Fragment>
        );
      },
    },
  ])

  return (

<Fragment>
      <MetaData title={`ALL PRODUCTS - Admin`} />

      <div className="dashboard">
        <SideBar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL Users</h1>
{/* 
          <Button
              onClick={() =>handleDelete(params.id) }
            >
              <GridDeleteIcon style={{color:'red'}}/>
            </Button> */}
          <DataGrid
            rows={users}
            columns={columns}
            getRowId={row=>row._id}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </Fragment>


  )
}

export default UserList