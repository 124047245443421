import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Slider from "react-slick";
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  clearErrors,
  getProductDetails,
  newReview,
} from "../../Redux/Action/listProductAction";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import {ADD } from "../../Redux/Action/cartAction";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";

import { Rating } from "@material-ui/lab";
import { NEW_REVIEW_RESET } from "../../Redux/Constant/productConstants";

import Loader from '../layout/Loader/Loader'
import React, { Fragment, useEffect, useState } from "react";
// import Carousel from "react-material-ui-carousel";
import "./ProductDetails.css";
import ReviewCard from "../home/ReviewCard";
import FilterProductDetails from '../Filteration/FilterProductDetails';
import { subCategory } from '../../Redux/Action/subCategoryAction';
import ProductFilter from './ProductFilter';
import ProductsProps from '../Props/ProductsProps';

const ProductDetails = () => {
  const {products}=useSelector(state=>state.productsReducer)
  const navigate=useNavigate()
 const {id,categoryname}=useParams()
//  console.log(id,categoryname,'params');

 const dispatch=useDispatch()
 const alert=useAlert()
 const handleClick=(e)=>{
  navigate(`/product/detail/${e}`)
 }
  // const {loading}=useSelector(state=>state.productDetails)
  // const product=useSelector(state=>state.productDetails)
  const { product, loading, error } = useSelector(
    (state) => state.productDetails);  
const {name,productPictures,salePrice}=product

  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );
  // console.log(product,'product details use selector');
  const options = {
    size: "large",
    // value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };

  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  // const increaseQuantity = () => {
  //  const qty=setQuantity(quantity+1)
  //   if (product.Stock <= qty) 
  //   return(alert.error(`maximum stock for this product is .... (${product.stock})`));
 
  // };

  // const decreaseQuantity = () => {
  //   if (1 >= quantity) return;

  //   const qty = quantity - 1;
  //   setQuantity(qty);
  // };

  const addToCartHandler = () => {
    dispatch(ADD({quantity,name,productPictures,salePrice}));
    alert.success("Item Added To Cart");
  };

  const submitReviewToggle = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const reviewSubmitHandler = () => {
    const myForm = new FormData();

    myForm.set("rating", rating);
    myForm.set("comment", comment);
    myForm.set("productId", id);

    dispatch(newReview(myForm));

    setOpen(false);
  };

  useEffect(() => {
   

    if (reviewError) {
      alert.error(reviewError);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Review Submitted Successfully");
      dispatch({ type: NEW_REVIEW_RESET });
    }
    dispatch(getProductDetails(id));
  }, [dispatch,id, alert, reviewError, success]);
  const setings={
    dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
  }

  // const filterdProductDetail=useSelector(state=>state.productsFilterReducer)
  // console.log(filterdProductDetail,'filter product details list');
  return (
    <>
  <MetaData title={`${product.name} -- ECOMMERCE`} />
          <div className="ProductDetails">
            <div className='slider-div'>
            <Carousel
                className="carasousel m-2"
                autoPlay={false}
                animation="fade"
                infiniteLoop={false}
                indicators={false}
                navButtonsAlwaysVisible={false}
                cycleNavigation={false}
                navButtonsProps={{
                    style: {
                        background: "#fff",
                        color: "#494949",
                        borderRadius: 100,
                        border:10,
                        borderColor:'#494949',
                        marginTop: -22,
                        height: "104px",
                    }
                }}>
                {product.productPictures &&
                  product.productPictures.map((item, i) => (
               
                    <img
                      className="CarouselImage w-50 align-center"
                      key={i}
                      src={`/uploads/products/${item.img}`}
                      alt={`${i} Slide`}
                    
//  style={{height:'60px',width:'auto'}}
                    />
                  
                  ))}
              </Carousel>
            </div>
            <hr/>
            <div className="div-2">
            <div className="detailsBlock-1">
                <h2>{product.name}</h2>
                <p className='substring'>Product # {product._id}</p>
              </div>
              <div className="detailsBlock-2">
                <Rating {...options} />
                <span className="detailsBlock-2-span">
                  {" "}
                  ({product.numOfReviews} Reviews)
                </span>
              </div>
              <div className="detailsBlock-3">
                <h1>{`Rs:${product.salePrice}`}</h1> 
                <h3>{`item Total Price(${quantity*product.salePrice})`}</h3>
                {/* <h1>{`Stock:${product.stock}`}</h1> */}
                <div className="detailsBlock-3-1">
                  <div className="detailsBlock-3-1-1">
                    {/* <button onClick={decreaseQuantity}>-</button> */}
                    <input className='quantity text-center fw-bold text-danger' type="number" 
                    value={quantity                   
                  } onChange={(e)=>setQuantity(e.target.value)} 
                    style={{width:'100px',height:'30px',fontSize:'22px'}}/>
                  
                    {/* {product.stock>=quantity+1?<button  onClick={increaseQuantity}>+</button>:<button disabled onClick={increaseQuantity}>+</button>} */}
                    
                  </div>
                  <button className='btn btn-warning m-2'
                    disabled={product.Stock < 1 ? true : false}
                    onClick={addToCartHandler}
                  >
                    Add to Cart
                  </button>
                </div>

                <p>
                  Status:
                  <b className={product.Stock < 1 ? "redColor" : "greenColor"}>
                    {product.Stock < 1 ? "OutOfStock" : "InStock"}
                  </b>
                </p>
              </div>

              <div className="">
                Description : <p>{product.description}</p>
              </div>

              <button onClick={submitReviewToggle} className="submitReview">
                Submit Review
              </button>
      
          <h3 className="reviewsHeading">REVIEWS</h3>

          <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={submitReviewToggle}
          >
            <DialogTitle>Submit Review</DialogTitle>
            <DialogContent className="submitDialog">
              <Rating
                onChange={(e) => setRating(e.target.value)}
                value={rating}
                size="large"
              />

              <textarea
                className="submitDialogTextArea"
                cols="30"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </DialogContent>
            <DialogActions>
              <Button onClick={submitReviewToggle} color="secondary">
                Cancel
              </Button>
              <Button onClick={reviewSubmitHandler} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {product.reviews && product.reviews[0] ? (
            <div className="reviews">
              {product.reviews &&
                product.reviews.map((review) => (
                  <ReviewCard key={review._id} review={review} />
                ))}
            </div>
          ) : (
            <p className="noReviews">No Reviews Yet</p>
          )}
                
          </div>

            </div>


    
    
    
    
    
    
    
    
    
    
    
    
    
    
    <div className="container-div-products">
  {products && products
  // .filter((item)=>item.categoryName.includes(categoryName)) 
  .map((item,i)=>{
  const {_id,productPictures,name,categoryName, discountInPercent,sellingPrice,salePrice,createdAt}=item
  
  return(
      <>
     {/* <Link > */}
     <ProductsProps 
                    key={i}
                    productPictures={<img src={`/uploads/products/${item.productPictures[0]?item.productPictures[0].img:null}`}  alt='' />}

                      name={name}
                      salePrice={salePrice}
                      sellingPrice={sellingPrice}
                      discountInPercent={discountInPercent}
                      _id={_id}
                      categoryname={categoryName}
                    />
      </>

  )})}
 
  </div>
    
    </>
  )
}

export default ProductDetails
