
import axios from "axios";
import {GET_ALL_CATEGORIES_REQUEST,GET_ALL_CATEGORIES_SUCCESS,GET_ALL_CATEGORIES_FAILURE,ADD_NEW_CATEGORY_REQUEST,
    ADD_NEW_CATEGORY_SUCCESS,ADD_NEW_CATEGORY_FAILURE,UPDATE_CATEGORIES_REQUEST,UPDATE_CATEGORIES_SUCCESS,
    UPDATE_CATEGORIES_FAILURE,DELETE_CATEGORIES_REQUEST,DELETE_CATEGORIES_SUCCESS,DELETE_CATEGORIES_FAILURE,
    ADD_SUB_CATEGORY_REQUEST,ADD_SUB_CATEGORY_SUCCESS,ADD_SUB_CATEGORY_FAILURE,
    GET_SUB_CATEGORIES_REQUEST,GET_SUB_CATEGORIES_SUCCESS,GET_SUB_CATEGORIES_FAILURE, GET_CATEGORY_DETAILS_REQUEST, GET_CATEGORY_DETAILS_SUCCESS, GET_CATEGORY_DETAILS_FAILURE, GET_SUB_CATEGORY_REQUEST, GET_SUB_CATEGORY_SUCCESS, GET_SUB_CATEGORY_FAILURE, GET_SUBCATEGORY_DETAILS_FAILURE, GET_SUBCATEGORY_DETAILS_SUCCESS, GET_SUBCATEGORY_DETAILS_REQUEST, UPDATE_SUB_CATEGORIES_REQUEST, UPDATE_SUB_CATEGORIES_SUCCESS, UPDATE_SUB_CATEGORIES_FAILURE,
    } from '../Constant/categoryConstant';

export const getAllCategory = () => {
    return async dispatch => {

        dispatch({ type: GET_ALL_CATEGORIES_REQUEST });
        try {
            const {data} = await axios.get('/category/getcategories/');
            console.log(data,'data');
            dispatch({
                type: GET_ALL_CATEGORIES_SUCCESS,
                payload:data
            });
        } catch (error) {
             dispatch({
                type: GET_ALL_CATEGORIES_FAILURE,
                payload: error
            });}}}
  
export const getSubCategory = (id) => {
    console.log(id,'get sub category id');
    return async dispatch => {

        dispatch({ type: GET_SUB_CATEGORY_REQUEST });
        try {
            // const userFromStorage=localStorage.getItem("user")
            // ?JSON.parse(localStorage.getItem("user")):null;
            //    const config={headers:{
            //     Authorization:`Bearer ${userFromStorage.token}`
            // }}
            const {data} = await axios.post(`/admin/getsubcategories/`,{id});
            console.log(data,'data');
            dispatch({
                type: GET_SUB_CATEGORY_SUCCESS,
                payload:data.categories
            });
        } catch (error) {
             dispatch({
                type: GET_SUB_CATEGORY_FAILURE,
                payload: error
            });}}
        }
export const getAdminSubCategories = () => {
    return async dispatch => {

        dispatch({ type: GET_SUB_CATEGORIES_REQUEST });
        try {
            // const userFromStorage=localStorage.getItem("user")
            // ?JSON.parse(localStorage.getItem("user")):null;
            //    const config={headers:{
            //     Authorization:`Bearer ${userFromStorage.token}`
            // }}
            const {data} = await axios.get('/admin/getsubcategories/');
            console.log(data,'data');
            dispatch({
                type: GET_SUB_CATEGORIES_SUCCESS,
                payload:data.categories
            });
        } catch (error) {
             dispatch({
                type: GET_SUB_CATEGORIES_FAILURE,
                payload: error
            });}}
        }
  


export const addCategory = (formData) => {
    console.log(formData,'form data');
    return async dispatch => {
        dispatch({ type: ADD_NEW_CATEGORY_REQUEST });
        try {
            const userFromStorage=localStorage.getItem("user")
            ?JSON.parse(localStorage.getItem("user")):null;
               const config={headers:{
                Authorization:`Bearer ${userFromStorage.token}`
            }}
            const {data} = await axios.post(`/admin/add/category`, formData,config)
     console.log(data,'add main category action');
           
                dispatch({
                    type: ADD_NEW_CATEGORY_SUCCESS,
                    payload: data
                });
         
               
            
        } catch (error) {   
            console.log(error.response);
             dispatch({
                    type: ADD_NEW_CATEGORY_FAILURE,
                    payload: error
                });
        }

    }
}
// add sub category
export const addSubategoryAction = (formData) => {
    // console.log(formData,'form data');
 
    return async dispatch => {
        dispatch({ type: ADD_SUB_CATEGORY_REQUEST });
        try {
            const userFromStorage=localStorage.getItem("user")
            ?JSON.parse(localStorage.getItem("user")):null;
               const config={headers:{
                Authorization:`Bearer ${userFromStorage.token}`
            }}
            const {data} = await axios.post(`/admin/add/subcategory`,formData,config)
     console.log(data,'sub catgory action');
            
                dispatch({
                    type: ADD_SUB_CATEGORY_SUCCESS,
                    payload:data.category
                });
        
              
            }
         catch (error) {   
            console.log(error.response);
              dispatch({
                    type: ADD_SUB_CATEGORY_FAILURE,
                    payload: error
                });
        }

    }
}

export const updateCategories = (formData) => {
    console.log(formData,'ata');

    const {id}=formData
    // console.log(id,'id');
    
    return async dispatch => {
        try {
            dispatch({ type: UPDATE_CATEGORIES_REQUEST });
        const userFromStorage=localStorage.getItem("user")
        ?JSON.parse(localStorage.getItem("user")):null;
           const config={headers:{
            Authorization:`Bearer ${userFromStorage.token}`
        }}
        const {data} = await axios.put(`/admin/update/category/${id}`,formData,config);
        console.log(data,'data');
        dispatch({ type: UPDATE_CATEGORIES_SUCCESS ,
            payload:data,
            
            });
        } catch (error) {
            dispatch({
                type: UPDATE_CATEGORIES_FAILURE,
                payload: { error }
            });
        }
       
    }
}

export const updateSubCategory = (formData) => {
    // console.log(formData,'ata');

    const {id}=formData
    // console.log(id,'id');
    
    return async dispatch => {
        dispatch({ type: UPDATE_SUB_CATEGORIES_REQUEST });
        const userFromStorage=localStorage.getItem("user")
        ?JSON.parse(localStorage.getItem("user")):null;
           const config={headers:{
            Authorization:`Bearer ${userFromStorage.token}`
        }}
     try {
        const {data} = await axios.put(`/admin/category/subcategory/${id}`,formData,config);
        console.log(data,'data');
                    dispatch({ type: UPDATE_SUB_CATEGORIES_SUCCESS ,
                    payload:data.category,
                    
                    });
                    // dispatch(getAllCategory());
               
                
     } catch (error) {
        console.log(error)
        dispatch({
            type: UPDATE_SUB_CATEGORIES_FAILURE,
            payload: error.response.data.message 
        });
        
     }
        }
    }


export const getCategoryDetails=(id)=>async(dispatch)=>{
    console.log(id,'id');
    try {
        
        dispatch({
            type:GET_CATEGORY_DETAILS_REQUEST,})
        
        const userFromStorage=localStorage.getItem("user")
        ?JSON.parse(localStorage.getItem("user")):null;
           const config={headers:{
            Authorization:`Bearer ${userFromStorage.token}`
        }}
        const {data}=await axios.get(`/admin/category/${id}`,config)

        console.log(data);
        dispatch({type:GET_CATEGORY_DETAILS_SUCCESS,
        payload:data.category
        })
    } catch (error) {
    dispatch({type:GET_CATEGORY_DETAILS_FAILURE,
    payload:error.payload
    })
    }
}
export const getSubCategoryDetails=(id)=>async(dispatch)=>{
    console.log(id,'id');
    try {
        
        dispatch({
            type:GET_SUBCATEGORY_DETAILS_REQUEST,})
        
        const userFromStorage=localStorage.getItem("user")
        ?JSON.parse(localStorage.getItem("user")):null;
           const config={headers:{
            Authorization:`Bearer ${userFromStorage.token}`
        }}
        const {data}=await axios.get(`/admin/category/subcategory/${id}`,config)
        console.log(data,'data');
       dispatch({type:GET_SUBCATEGORY_DETAILS_SUCCESS,
        payload:data.category
        })
    } catch (error) {
    dispatch({type:GET_SUBCATEGORY_DETAILS_FAILURE,
    payload:error.payload
    })
    }
}

