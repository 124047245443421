import axios from 'axios'
import { BANNER_UPLOAD_FAIL, BANNER_UPLOAD_REQUEST, BANNER_UPLOAD_SUCCESS,
    GET_BANNER_FAIL, GET_BANNER_REQUEST, GET_BANNER_SUCCESS,
    GET_SINGLE_BANNER_FAIL, GET_SINGLE_BANNER_REQUEST, GET_SINGLE_BANNER_SUCCESS,
    DELETE_BANNER_FAIL, DELETE_BANNER_REQUEST, DELETE_BANNER_SUCCESS, UPDATE_BANNER_REQUEST, UPDATE_BANNER_SUCCESS, UPDATE_BANNER_FAIL
} from '../Constant/bannerConstant';
export const addBannerAction = (bannerData) => async(dispatch)=>{
    // console.log(bannerData,'banner data');
dispatch({type:BANNER_UPLOAD_REQUEST})
try {
    const userFromStorage=localStorage.getItem("user")
    ?JSON.parse(localStorage.getItem("user")):null;
       const config={headers:{
        Authorization:`Bearer ${userFromStorage.token}`
    }}
    const {data,message}=await axios.post('/admin/add/banner',bannerData,config)
dispatch({type:BANNER_UPLOAD_SUCCESS,
    payload:data,message})
} catch (error) {
    dispatch({type:BANNER_UPLOAD_FAIL,
        payload: error.response.data,
    })}}

    //get banner
    
    export const getBannerAction = () => async(dispatch)=>{
        dispatch({type:GET_BANNER_REQUEST})
        try {
            const {data}=await axios.get('/admin/get/adminbanners/')
            console.log(data,'banner data')
        dispatch({type:GET_BANNER_SUCCESS,
            payload:data.banners})
        } catch (error) {
            dispatch({type:GET_BANNER_FAIL,
                payload: error.response.data,
            })}}

    export const getSinleBannerAction = (id) => async(dispatch)=>{
        console.log(id,'jtttttttttt');
        dispatch({type:GET_SINGLE_BANNER_REQUEST})
        try {
            const userFromStorage=localStorage.getItem("user")
            ?JSON.parse(localStorage.getItem("user")):null;
               const config={headers:{
                Authorization:`Bearer ${userFromStorage.token}`
            }}
            const {data}=await axios.get(`/admin/get/banner/${id}`,config)
            console.log(data.banner,'banner data by id')
        dispatch({type:GET_SINGLE_BANNER_SUCCESS,
            payload:data.banner})
        } catch (error) {
            dispatch({type:GET_SINGLE_BANNER_FAIL,
                payload: error.response.data,
            })}}

    export const getUpdateBannerAction = (id) => async(dispatch)=>{
        // console.log(id._id,'jtttttttt`````````````````````````````tt');
        // const {_id}=id
        dispatch({type:UPDATE_BANNER_REQUEST})
        try {
            const userFromStorage=localStorage.getItem("user")
            ?JSON.parse(localStorage.getItem("user")):null;
               const config={headers:{
                Authorization:`Bearer ${userFromStorage.token}`
            }}
            const {data}=await axios.put(`/admin/update/banner/${id.id}`,id,config)
            console.log(data,'banner data by id')
        dispatch({type:UPDATE_BANNER_SUCCESS,
            payload:data})
        } catch (error) {
            dispatch({type:UPDATE_BANNER_FAIL,
                payload: error.response.data,
            })}}
