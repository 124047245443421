import { message } from "antd";
import {
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    CLEAR_ERRORS,
  } from "../Constant/userConstants";
  import axios from "axios";
import { Navigate, redirect, useNavigate } from "react-router-dom";

//Register New user
export const register = (userData) => async (dispatch) => {
console.log(userData,'user action');
const {val,fileName}=userData
// console.log(fileName,'file name');
const {name,shopName,email,password,phoneNumber,address,userName,userProfile,gender,age}=val
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    // const config = { headers: { "Content-Type": "multipart/form-data" } };

    const  {data}  = await axios.post('/register',
    {name,shopName,email,password,phoneNumber,address,userName,userProfile,fileName,gender,age});
    console.log(data, 'user final registration');

    dispatch({ type: REGISTER_USER_SUCCESS, payload: data});
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response
    });
  }
};

// Register New user end<===========================================================>






  
  // Login
  export const login = (userLogin) => async (dispatch) => {
    console.log(userLogin,'login user action');
   
    try {
      dispatch({ type: LOGIN_REQUEST });
     
      const config = { headers: { "Content-Type": "application/json" } };
  
      const  {data}  = await axios.post(  
        `/login`, userLogin, config);  
console.log(data,'user login data');


      dispatch({ type: LOGIN_SUCCESS,payload:data      
      });
  //  console.log(data.user,'login data user');
      localStorage.setItem("user", JSON.stringify(data));
    } catch (error) {
      dispatch({ type: LOGIN_FAIL, payload:error});
    }
  };
  
  // Register
 
  
  // Load User
  export const loadUser = () => async (dispatch,getState) => {
 
    try {
      dispatch({ type: LOAD_USER_REQUEST });
      // const{user:{user}}=getState();
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;

      // console.log(userFromStorage.user._id,'user data 1S');
      const config={
        headers:{
          // 'Content-Type':'application.json',
          Authorization:`Bearer ${userFromStorage.token}`
        }
      }
      // console.log(config,'config');
  
      const {data} = await axios.post(`/me`,{id:userFromStorage.user._id},config);
  console.log(data,'user data user data');

      dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({ type: LOAD_USER_FAIL, payload: message });
    }
  };



  export const updateProfile = (userData) => async (dispatch) => {
    console.log(userData,'user data');
    try {
      
      // console.log(userFromStorage,'ufs');
      dispatch({ type: UPDATE_PROFILE_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
      const config = {
      Authorization:`Bearer ${userFromStorage.token}`
    };
   console.log(config,'id');
      const { data } = await axios.put(`/me/update`,userData,config );
  
      dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  // Logout User
  export const logout = () => async (dispatch) => {
    try {
      await axios.get(`/logout`); 
  
      dispatch({ type: LOGOUT_SUCCESS });
    } catch (error) {
      dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
    }
  };
  
  // Update Profile
  // export const updateProfile = (userData) => async (dispatch) => {
  //   try {
  //     dispatch({ type: UPDATE_PROFILE_REQUEST });
  
  //     const config = { headers: { "Content-Type": "multipart/form-data" } };
  
  //     const { data } = await axios.put(`/me/update`, userData, config);
  
  //     dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.success });
  //   } catch (error) {
  //     dispatch({
  //       type: UPDATE_PROFILE_FAIL,
  //       payload: error.response.data.message,
  //     });
  //   }
  // };
  
  // Update Password
  export const updatePassword = (passwords) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PASSWORD_REQUEST });
  
      const config = { headers: { "Content-Type": "application/json" } };
  
      const { data } = await axios.put(
        `/password/update`,
        passwords,
        config
      );
  
      dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: UPDATE_PASSWORD_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Forgot Password
  export const forgotPassword = (email) => async (dispatch) => {
    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });
  
      const config = { headers: { "Content-Type": "application/json" } };
  
      const { data } = await axios.post(`/password/forgot`, email, config);
  
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message });
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Reset Password
  export const resetPassword = (token, passwords) => async (dispatch) => {
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });
  
      const config = { headers: { "Content-Type": "application/json" } };
  
      const { data } = await axios.put(
        `/password/reset/${token}`,
        passwords,
        config
      );
  
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // get All Users
  export const getAllUsers = () => async (dispatch) => {
    try {
      dispatch({ type: ALL_USERS_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
      const config = {
      Authorization:`Bearer ${userFromStorage.token}`
    };
      const { data } = await axios.get(`/admin/users`);
  console.log(data.users,'admin/user');
      dispatch({ type: ALL_USERS_SUCCESS, payload: data.users });
    } catch (error) {
      dispatch({ type: ALL_USERS_FAIL, payload: error.response.data.message });
    }
  };
  
  // get  User Details

  export const getUserDetails = (id) => async (dispatch) => {
    try {
      dispatch({ type: USER_DETAILS_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
      const config = {
      Authorization:`Bearer ${userFromStorage.token}`
    };
      const { data } = await axios.get(`/admin/users/${id}`,config);
  console.log(data,'data get user details');
      dispatch({ type: USER_DETAILS_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({ type: USER_DETAILS_FAIL, payload: error.response.data.message });
    }
  };
  
  // Update User
  export const updateUser = (postData) => async (dispatch) => {
    console.log(postData,'post data');
    try {
      dispatch({ type: UPDATE_USER_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
      const config={
        headers:{
          // 'Content-Type':'application.json',
          Authorization:`Bearer ${userFromStorage.token}`
        }
      }
  
  
      const { data } = await axios.put(
        `/update/userrole/${postData.id}`,
        postData,
        config
      );
  
      dispatch({ type: UPDATE_USER_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Delete User
  export const deleteUser = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_USER_REQUEST });
      const userFromStorage=localStorage.getItem("user")
      ?JSON.parse(localStorage.getItem("user")):null;
      const config = {
      Authorization:`Bearer ${userFromStorage.token}`
    };
  
      const { data } = await axios.delete(`/admin/user/${id}`,config);
  
      dispatch({ type: DELETE_USER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_USER_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  export const updateStatus=()=>async(updatedFields,userId,dispatch)=>{
  try {
    dispatch({ type: 'UPDATE_STATUS_REQUEST'})
    const {data}=await axios.patch(`http:localhost/5000/update/${userId}`)
    console.log(data,'data data');
    dispatch({ type: 'UPDATE_STATUS_SUCCESS', payload: data });
  } catch (error) {
    console.log(error);
  }
    }
   
  // Clearing Errors
  export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };
  