import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
    const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer);
 return (
 <>
 {userDetails && userDetails?.role==='superAdmin'?<Outlet/>:
  <Navigate to='/home'/>}
  
    </>
 )
}

export default PrivateRoute