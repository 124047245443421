import { BANNER_UPLOAD_FAIL, BANNER_UPLOAD_REQUEST, BANNER_UPLOAD_SUCCESS, GET_BANNER_FAIL, GET_BANNER_REQUEST,
    GET_SINGLE_BANNER_FAIL, GET_SINGLE_BANNER_REQUEST, GET_SINGLE_BANNER_SUCCESS,
   GET_BANNER_SUCCESS, 
   UPDATE_BANNER_REQUEST,
   UPDATE_BANNER_SUCCESS,
   UPDATE_BANNER_FAIL} from '../Constant/bannerConstant';
export const postBannerReducer = (state = { banner: [] }, action) => {
    switch (action.type) {
        case BANNER_UPLOAD_REQUEST:
         
            return {
              loading: true,
              banner: [],
            }

case BANNER_UPLOAD_SUCCESS:
    
    return{
        loading:false,
        success:true,
        banner:action.payload,
    }
    case BANNER_UPLOAD_FAIL:
        console.log(action.payload);
        return{
            loading:false,success:false,
            banner:action.payload
        }


            default:
                return state;}}
                export const getBannerReducer = (state = { banner: [] }, action) => {
                    switch (action.type) {
                        case GET_BANNER_REQUEST:
                         
                            return {
                              loading: true,
                              banner: [],
                            }
                        case GET_BANNER_SUCCESS:
                         
                            return {
                              loading: false,
                              banner:action.payload,
                            }
                        case GET_BANNER_FAIL:
                         
                            return {
                              loading: false,
                              banner: action.payload,
                            }
                        
                            default:
                                return state
                        }

                }

                export const getSingleBannerReducer = (state = { banner: {} }, action) => {
                    switch (action.type) {
                        case GET_SINGLE_BANNER_REQUEST:
                         
                            return {
                              loading: true,
                              success:false,
                              banner: {},
                            }
                        case GET_SINGLE_BANNER_SUCCESS:
                         
                            return {
                              loading: false,success:true,
                              banner:action.payload,
                            }
                        case GET_SINGLE_BANNER_FAIL:
                         
                            return {
                              loading: false,
                              success:false,
                              banner: action.payload,
                            }
                        
                            default:
                                return state
                        }

                }

                export const updateBannerReducer = (state = { updateBanner: {} }, action) => {
                  switch (action.type) {
                      case UPDATE_BANNER_REQUEST:
                       
                          return {
                            loading: true,
                            success:false,
                            updateBanner: {},
                          }
                      case UPDATE_BANNER_SUCCESS:
                       
                          return {
                            loading: false,
                            success:true,
                            updateBanner:action.payload,
                          }
                      case UPDATE_BANNER_FAIL:
                       
                          return {
                            loading: false,
                            success:false,
                            banner: action.payload,
                          }
                      
                          default:
                              return state
                      }

              }
