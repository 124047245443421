import axios from "axios"
import { 
    GET_OFFER3_FAIL, GET_OFFER3_REQUEST, GET_OFFER3_SUCCESS,
    GET_OFFER2_FAIL, GET_OFFER2_REQUEST, GET_OFFER2_SUCCESS,
    GET_OFFER1_FAIL, GET_OFFER1_REQUEST, GET_OFFER1_SUCCESS,

    POST_OFFER3_FAIL, POST_OFFER3_REQUEST, POST_OFFER3_SUCCESS,
    POST_OFFER2_FAIL, POST_OFFER2_REQUEST, POST_OFFER2_SUCCESS,
    POST_OFFER1_FAIL, POST_OFFER1_REQUEST, POST_OFFER1_SUCCESS 

} from "../Constant/offerContant"
export const addOffer1Action = (offerData) => async(dispatch)=>{
    console.log(offerData,'banner data');
    const {values}=FormData
dispatch({type:POST_OFFER1_REQUEST})
try {
    const userFromStorage=localStorage.getItem("user")
    ?JSON.parse(localStorage.getItem("user")):null;
       const config={headers:{
        Authorization:`Bearer ${userFromStorage.token}`
    }}
    const {data,message}=await axios.post('/admin/add/offer1',offerData,config)
    console.log(data);
dispatch({type:POST_OFFER1_SUCCESS,
    payload:data.offer,message})
} catch (error) {
    dispatch({type:POST_OFFER1_FAIL,
        payload: error.response
    })}}
export const addOffer2Action = (offerData) => async(dispatch)=>{
    console.log(offerData,'banner data');
dispatch({type:POST_OFFER2_REQUEST})
try {
    const userFromStorage=localStorage.getItem("user")
    ?JSON.parse(localStorage.getItem("user")):null;
       const config={headers:{
        Authorization:`Bearer ${userFromStorage.token}`
    }}
    const {data,message}=await axios.post('/admin/add/offer2',offerData,config)
dispatch({type:POST_OFFER2_SUCCESS,
    payload:data.offer2,message})
} catch (error) {
    dispatch({type:POST_OFFER2_FAIL,
        payload: error.response
    })}}

export const addOffer3Action = (offerData) => async(dispatch)=>{
    console.log(offerData,'banner data');
dispatch({type:POST_OFFER3_REQUEST})
try {
    const userFromStorage=localStorage.getItem("user")
    ?JSON.parse(localStorage.getItem("user")):null;
       const config={headers:{
        Authorization:`Bearer ${userFromStorage.token}`
    }}
    const {data,message}=await axios.post('/admin/add/offer3',offerData,config)
dispatch({type:POST_OFFER3_SUCCESS,
    payload:data.offer3,message})
} catch (error) {
    dispatch({type:POST_OFFER3_FAIL,
        payload: error.response.data,
    })}}

export const getOffer1Action = () => async(dispatch)=>{
    dispatch({type:GET_OFFER1_REQUEST})
    try {
        const {data}=await axios.get('/get/offer1')
        console.log(data,'data');
    dispatch({type:GET_OFFER1_SUCCESS,
        payload:data.offer1})
    } catch (error) {
        dispatch({type:GET_OFFER1_FAIL,
            payload: error.response.data,
        })}}

export const getOffer2Action = () => async(dispatch)=>{
    dispatch({type:GET_OFFER2_REQUEST}) 
    try {
        const {data}=await axios.get('/get/offer2')
        console.log(data.offer,'data');
    dispatch({type:GET_OFFER2_SUCCESS,
        payload:data.offer2})
    } catch (error) {
        dispatch({type:GET_OFFER2_FAIL,
            payload: error.response.data,
        })}}

        export const getOffer3Action = () => async(dispatch)=>{
            dispatch({type:GET_OFFER3_REQUEST})
            try {
                const {data}=await axios.get('/get/offer3')
                console.log(data.offer,'data');
            dispatch({type:GET_OFFER3_SUCCESS,
                payload:data.offer3})
            } catch (error) {
                dispatch({type:GET_OFFER3_FAIL,
                    payload: error.response.data,
                })}}