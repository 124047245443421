import React, { Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import { DataGrid, GridDeleteIcon } from '@mui/x-data-grid'
import { Link, NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import { Avatar } from 'antd';
import { useAlert } from 'react-alert';
import MetaData from '../layout/MetaData';
import Sidebar from '../DashBoard/Sidebar';
import { getAllOrders } from '../../Redux/Action/orderAction';
const AllOrders = () => {
  const {orders}=useSelector(state=>state.allOrdersReducer)
  console.log(orders,'orders');
  const {users}=useSelector(state=>state.allUsersReducer)
  // console.log(orders,'orders');
  const {id}=useParams()
  const dispatch=useDispatch()
  const alert=useAlert()
  const handleDelete = async (id) => {
    console.log(id,'order id');
    await axios.delete(`/order/delete/${id}`)
      .then((res) => {
        alert.success("order deleted Successfully");
      });
  };
  // useEffect(() => {
  // dispatch(getAllOrders())
  // }, [dispatch])
  
  const columns=useMemo(()=>[
    // {field:'_id', headerName:'Id', width:160},
    {field:'userProfile', headerName:'Avatar', width:160,
    renderCell:(params)=><NavLink to={`/order/process/${params.id}`}>
      <Avatar src={`/uploads/userprofile/${params.row.userProfile}`}/></NavLink>,
    sortable:false,filterable:false,
    },
    {field:'orderStatus', headerName:'Order Status', width:120,},
    {field:'totalPrice', headerName:'Price', width:120},
    {field:'createdAt', headerName:'Created At', width:120},
    {field:'name', headerName:'Name', width:120},
    {field:'shippingInfo', headerName:'Address', width:220,
    renderCell:(params)=>(params.row.shippingInfo.address?params.row.shippingInfo.address:null), },

  {field:'phoneNo', headerName:'PhoneNumber', width:100,
renderCell:(params)=>(params.row.shippingInfo.phoneNo),},
    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/single/order/${params.id}`}>
              <EditIcon style={{color:'green'}}/>
            </Link>
            <Button
              onClick={() =>handleDelete(params.id) }
            >
              <DeleteIcon style={{color:'red'}}/>
            </Button>
          </Fragment>
        );
      },
    },

  ])
  return (

    <Fragment>
          <MetaData title={`Orders`} />
    
          <div className="dashboard">
            <Sidebar />
            <div className="productListContainer">
              <h1 id="productListHeading">ALL Orders</h1>

              <DataGrid
                rows={orders}
                columns={columns}
                getRowId={row=>row._id}
                pageSize={10}
                disableSelectionOnClick
                className="productListTable"
                autoHeight
              />
            </div>
          </div>
        </Fragment>
    
    
      )
}

export default AllOrders