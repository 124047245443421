import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
} from "../Constant/cartContant";

const initState={
  cartItems:[],  
  shippingInfo: {},
  paymentInfo:{}
}

export const cartReducer=(state=initState,action)=>{
  switch (action.type)
     {
    case ADD_TO_CART:
      let itemTest=state.cartItems.find((x)=>x._id===action.payload._id)
      // console.log(itemTest,'item test');
      if(itemTest===undefined){
        return {
          ...state,
          cartItems: [...state.cartItems, action.payload],
        }}
        else{
          return{
            ...state
          }
        }
      case  REMOVE_CART_ITEM:
      const cartItems= state.cartItems.filter((i) => i._id !== action.payload);
      return {
        ...state,cartItems
        
      };

    case SAVE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: action.payload,
      };
    case 'SAVE_PAYMENT_INFO':
      return {
        ...state,
        paymentInfo: action.payload,
      };


    default:
      return state;
  }
};
