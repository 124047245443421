export const BANNER_UPLOAD_REQUEST = "BANNER_UPLOAD_REQUEST";
export const BANNER_UPLOAD_SUCCESS = "BANNER_UPLOAD_SUCCESS";
export const BANNER_UPLOAD_FAIL = "BANNER_UPLOAD_FAIL";

export const GET_BANNER_REQUEST = "GET_BANNER_REQUEST";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_FAIL = "GET_BANNER_FAIL";

export const GET_SINGLE_BANNER_REQUEST = "GET_SINGLE_BANNER_REQUEST";
export const GET_SINGLE_BANNER_SUCCESS = "GET_SINGLE_BANNER_SUCCESS";
export const GET_SINGLE_BANNER_FAIL = "GET_SINGLE_BANNER_FAIL";



export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL";


export const UPDATE_BANNER_REQUEST = "UPDATE_BANNER_REQUEST";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL";