 
    export const GET_ALL_CATEGORIES_REQUEST= "GET_ALL_CATEGORIES_REQUEST";
    export const GET_ALL_CATEGORIES_SUCCESS= "GET_ALL_CATEGORIES_SUCCESS";
    export const GET_ALL_CATEGORIES_FAILURE= "GET_ALL_CATEGORIES_FAILURE";

    export const GET_CATEGORY_DETAILS_REQUEST= "GET_CATEGORY_DETAILS_REQUEST";
    export const GET_CATEGORY_DETAILS_SUCCESS= "GET_CATEGORY_DETAILS_SUCCESS";
    export const GET_CATEGORY_DETAILS_FAILURE= "GET_CATEGORY_DETAILS_FAILURE";

    export const GET_SUBCATEGORY_DETAILS_REQUEST= "GET_SUBCATEGORY_DETAILS_REQUEST";
    export const GET_SUBCATEGORY_DETAILS_SUCCESS= "GET_SUBCATEGORY_DETAILS_SUCCESS";
    export const GET_SUBCATEGORY_DETAILS_FAILURE= "GET_SUBCATEGORY_DETAILS_FAILURE";

    export const GET_SUB_CATEGORY_REQUEST= "GET_SUB_CATEGORY_REQUEST";
    export const GET_SUB_CATEGORY_SUCCESS= "GET_SUB_CATEGORY_SUCCESS";
    export const GET_SUB_CATEGORY_FAILURE= "GET_SUB_CATEGORY_FAILURE";

    export const GET_SUB_CATEGORIES_REQUEST= "GET_SUB_CATEGORIES_REQUEST";
    export const GET_SUB_CATEGORIES_SUCCESS= "GET_SUB_CATEGORIES_SUCCESS";
    export const GET_SUB_CATEGORIES_FAILURE= "GET_SUB_CATEGORIES_FAILURE";

    export const ADD_NEW_CATEGORY_REQUEST= "ADD_NEW_CATEGORY_REQUEST";
    export const ADD_NEW_CATEGORY_SUCCESS= "ADD_NEW_CATEGORY_SUCCESS";
    export const ADD_NEW_CATEGORY_FAILURE= "ADD_NEW_CATEGORY_FAILURE";

    export const ADD_SUB_CATEGORY_REQUEST= "ADD_SUB_CATEGORY_REQUEST";
    export const ADD_SUB_CATEGORY_SUCCESS= "ADD_SUB_CATEGORY_SUCCESS";
    export const ADD_SUB_CATEGORY_FAILURE= "ADD_SUB_CATEGORY_FAILURE";

    export const UPDATE_CATEGORIES_REQUEST= "UPDATE_CATEGORIES_REQUEST";
    export const UPDATE_CATEGORIES_SUCCESS= "UPDATE_CATEGORIES_SUCCESS";
    export const UPDATE_CATEGORIES_FAILURE= "UPDATE_CATEGORIES_FAILURE";

    export const UPDATE_SUB_CATEGORIES_REQUEST= "UPDATE_SUB_CATEGORIES_REQUEST";
    export const UPDATE_SUB_CATEGORIES_SUCCESS= "UPDATE_SUB_CATEGORIES_SUCCESS";
    export const UPDATE_SUB_CATEGORIES_FAILURE= "UPDATE_SUB_CATEGORIES_FAILURE";
    
    export const DELETE_CATEGORIES_REQUEST= "DELETE_CATEGORIES_REQUEST";
    export const DELETE_CATEGORIES_SUCCESS= "DELETE_CATEGORIES_SUCCESS";
    export const DELETE_CATEGORIES_FAILURE= "DELETE_CATEGORIES_FAILURE";
    
   
    export const SEND_FILTER= "SEND_FILTER";
    export const SUB_CAT= "SUB_CAT";
    export const SUB_SUB_CAT= "SUB_SUB_CAT";
    export const ALL_CAT= "ALL_CAT";
    export const CAT_REQUEST= "CAT_REQUEST";
    export const CHILD_SUB_CAT='CHILD_SUB_CAT'


  