const nameData=[
   {color:'#FF0000',name:'Red'},
   {color:'#00FFFF',name:'Cyan'},
   {color:'#0000FF',name:'Blue'},
   {color:'#00008B',name:'DarkBlue'},
   {color:'#ADD8E6',name:'LightBlue'},
   {color:'#800080',name:'Purple'},
   {color:'#FFFF00',name:'Yellow'},
   {color:'#00FF00',name:'Lime'},
   {color:'#FF00FF',name:'Magenta'},
   {color:'#FFC0CB',name:'Pink'},
   {name:'White',color:'#FFFFFF'},
   {name:'Silver',color:'#C0C0C0'},
   {name:'Gray or Grey',color:'#808080'},
   {name:'Black',color:'#000000'},
   {name:'Orange',color:'#FFA500'},
   {name:'Brown',color:'#A52A2A'},
   {name:'Maroon',color:'#800000'},
   {name:'Green',color:'#008000'},
   {name:'Olive',color:'#808000'},
   {name:'Aquamarine',color:'#7FFFD4'},
   

]
export default nameData