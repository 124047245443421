
import React, { Fragment } from "react";
import CheckoutSteps from "./CheckoutSteps";
import { useDispatch, useSelector } from "react-redux";
import {createOrder} from '../../Redux/Action/orderAction'
import MetaData from "../layout/MetaData";
import "./ConfirmOrder.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useAlert } from "react-alert";

const ConfirmOrder = () => {
    const navigate=useNavigate()
    const alert=useAlert()
    const btnDisable=(e)=>{
      e.disabled=true
    }
    const cart= useSelector((state) => state.cart);
    const {newOrder, loading,success}= useSelector((state) => state.newOrderReducer);
    console.log(newOrder,'new order', loading,'Loading',success,'success');
    // console.log(cart,'cart');
    const { shippingInfo, cartItems ,paymentInfo} =cart
    console.log(shippingInfo,'infi shipping',cartItems,'cartitems');
    // const { user } = useSelector((state) => state.user);
    const dispatch=useDispatch()
    const subtotal = cartItems.reduce(
        (acc, item) => acc + item.quantity *item.salePrice,0);
      // const shippingCharges = subtotal > 6000 ? 0 : 200;
      // const tax = subtotal * 0.18;
      // const totalPrice = 100
      const totalPrice = subtotal
      const address = `${shippingInfo.address},${shippingInfo.name}, ${shippingInfo.city}, 
      ${shippingInfo.state}, ${shippingInfo.pinCode}, ${shippingInfo.country}`;
// console.log(address,'address');
      const placeOrder=()=>{
        dispatch(createOrder({
          shippingInfo,orderItems:cartItems,
            // address:shippingInfo.pinCode,
            // city:shippingInfo.city,
            // postalCode:shippingInfo.postalCode,
            // country:shippingInfo.country,
            //  shippingCharges,
            //  tax,
          totalPrice,
          // user,
           }))
           if( loading===false &&success===true){
            alert.success('Order Place Successfully')
           }
           else{
            alert.error('Order Place Successfully')
           }
 localStorage.removeItem("cartItems");
navigate (`/order/success`)      
} 
       
        
        
  return (
    <Fragment>
    <MetaData title="Confirm Order" />
    <CheckoutSteps activeStep={1} />
    <div className="confirmOrderPage">
      <div>
        <div className="confirmshippingArea">
          <Typography>Shipping Info</Typography>
          <div className="confirmshippingAreaBox">
            <div>
              <p>Name:</p>
              {/* <span>{user.name}</span> */}
            </div>
            <div>
              <p>address</p>
              <span>{shippingInfo.address}</span>
              <span>{shippingInfo.postalCode}</span>
              <span>{shippingInfo.country}</span>
              <span>{shippingInfo.city}</span>
            </div>
          
          </div>
        </div>
        <div>
              <p>Payment Method:</p>
              <span>{paymentInfo}</span>
            </div>
        <div className="confirmCartItems">
          <Typography>Your Cart Items:</Typography>
          <div className="confirmCartItemsContainer">
            {cartItems &&
              cartItems.map((item) => (
                <div key={item.product}>
                 <img src={`/uploads/products/${item.productPictures && item.productPictures[0] ? item.productPictures[0].img:null}`}
                 style={{width:75,height:75,margin:2,border:"3px solid silver"}}/>
                  <Link to={`/product/${item.product}`}>
                    {item.name}
                  </Link>{" "}
                  <span>
                    {item.quantity} X Rs:{item.salePrice}=
                    <b>Rs:{item.salePrice * item.quantity}</b>
                  </span>
                  
                </div>
              ))}
          </div>
        </div>
      </div>
      {/*  */}
      <div>
        <div className="orderSummary">
          <Typography>Order Summery</Typography>
          <div>
            <div>
              <p>Subtotal:</p>
              <span>Rs:{subtotal}</span>
            </div>
            {/* <div>
              <p>Shipping Charges:</p>
              <span>Rs:{shippingCharges}</span>
            </div> */}
            {/* <div>
              <p>GST:</p>
              <span>Rs:{tax}</span>
            </div> */}
          </div>

          <div className="orderSummaryTotal">
            <p>
              <b>Total:</b>
            </p>
            <span>Rs:{totalPrice}</span>
          </div>

          <button onClick={(e)=>{placeOrder()}}>Place Order</button>
        </div>
      </div>
    </div>
  </Fragment>
  )
}

export default ConfirmOrder