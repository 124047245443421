
import {MDBBtn, MDBContainer, MDBRow,  MDBCol,  MDBCard,  MDBCardBody,  MDBInput,  MDBIcon}from 'mdb-react-ui-kit';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import {useFormik,} from 'formik'
import * as Yup from 'yup'
import { login, register } from '../../Redux/Action/userAction';
import { useAlert } from 'react-alert';

const LoginShipping = () => {
    const {loading,isAuthenticated,userLogin,error}=useSelector(state=>state.userLoginReducer)
    console.log(loading,isAuthenticated,userLogin,'user login');
    const emailRegExp=(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    
    const {handleBlur,handleReset,handleSubmit,setFieldValue,handleChange,values,touched,errors}=useFormik({
        initialValues:{
          email:'',password:'',
          },
          validationSchema:Yup.object().shape({
            email:Yup.string().email().matches(emailRegExp,'please provide a valid email').required('email is required'),
            password: Yup.string().min(6,'password should be atleast 6 characters').max(15,'maximum characters are allowed 15').required('Password is required'),
               
        }),
         onSubmit:(val)=>{
        //  console.log(val,'val');
             dispatch(login(val))
           
            }})
      
const navigate=useNavigate()
const alert=useAlert()
const dispatch=useDispatch()
useEffect(() => {
    // dispatch(login())
   
    if(isAuthenticated===false && loading===false){
        alert.error('invalid email or password')
    }
  if(loading===false && isAuthenticated===true){
        alert.success('user logged in successfully')
        navigate('/shipping')
    }
    }, [dispatch,alert,isAuthenticated,loading,navigate])




  

  return (
    <MDBContainer fluid>

      <MDBRow className='d-flex justify-content-center align-items-center h-100'>
        <MDBCol col='12'>

          <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

              <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
              <p className="text-white-50 mb-5">Please enter your login and password!</p>
              <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
         
              <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c"><em>Email</em></label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="text" className="form-control ms-3" placeholder='Email'
                 onChange={handleChange}  onBlur={handleBlur} name='email' />
                      <div className='error' style={{color:'red'}}>
                        {error}
                        {touched.email && errors.email ? errors.email:''}
                      </div>
                      
                    </div>
          
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">password</label></div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="password" className="form-control ms-3" placeholder='Password'
                 onChange={handleChange}  onBlur={handleBlur} name='password' />
                      <div className='error' style={{color:'red'}}>
                        {touched.password && errors.password ? errors.password:''}
                      </div>
               {error}
                    </div>
                  </div>

                 <button className='btn btn-primary' type="submit">Login</button>
                 <button className='btn btn-primary ms-3' type="reset"onReset={handleReset}>Reset</button>
</form>
              <p className="small mb-3 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>
              <MDBBtn outline className='mx-2 px-5' color='white' size='lg'>
                Login
              </MDBBtn>

              <div>
                <p className="mb-0">Don't have an account? <Link to="/register" class="text-white-50 fw-bold">Sign Up</Link></p>

              </div>
            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>

    </MDBContainer>
  );
}

export default LoginShipping;
