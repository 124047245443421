import React from 'react'
import Props from '../Props/Props'
import ViewMore from '../Props/ViewMore'
import { useSelector } from 'react-redux'

const DealOfTheDay = () => {
    const {products}=useSelector(state=>state.productsReducer)
    // console.log(products,'products');
    let name='newArrival'
  return (
  <>
  <div className="row mt-3">
  <ViewMore name={name} title={`Best Deals`}/>
  </div>
  
  <div className="container-div">  
     {
    products?products.filter((item)=>{
             return(item?.newArrival==='true')      
             }).slice(0,6).map((item)=>{
             return(
                 <>
       
                <Props
                key={item._id}
                fileName={<img src={`/uploads/products/${item.productPictures[0]?.img}`}  alt='' />}

                // fileName2={<img src={`/uploads/products/${item.productPictures[1]?item.productPictures[1].img:item.productPictures[0]?.img}`}  alt='' />}
     
                name={item.name}
               salePrice={item.salePrice}
                sellingPrice={item.sellingPrice}
                star={item.star}
                productPictures={item.productPictures}
                _id={item._id}
               
                />
                 
                 </>
             )
         }):null
     }
     </div>
     
  
  </>
  )
}

export default DealOfTheDay