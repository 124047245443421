import {GET_ALL_CATEGORIES_REQUEST,GET_ALL_CATEGORIES_SUCCESS,GET_ALL_CATEGORIES_FAILURE,ADD_NEW_CATEGORY_REQUEST,
    ADD_SUB_CATEGORY_REQUEST,ADD_SUB_CATEGORY_SUCCESS,ADD_SUB_CATEGORY_FAILURE,
ADD_NEW_CATEGORY_SUCCESS,ADD_NEW_CATEGORY_FAILURE,UPDATE_CATEGORIES_REQUEST,UPDATE_CATEGORIES_SUCCESS,
GET_SUB_CATEGORIES_REQUEST,GET_SUB_CATEGORIES_SUCCESS,GET_SUB_CATEGORIES_FAILURE,
UPDATE_CATEGORIES_FAILURE,DELETE_CATEGORIES_REQUEST,DELETE_CATEGORIES_SUCCESS,DELETE_CATEGORIES_FAILURE, CHILD_SUB_CAT,SUB_CAT, GET_CATEGORY_DETAILS_REQUEST, GET_CATEGORY_DETAILS_SUCCESS, GET_CATEGORY_DETAILS_FAILURE, GET_SUBCATEGORY_DETAILS_FAILURE, GET_SUBCATEGORY_DETAILS_SUCCESS, GET_SUBCATEGORY_DETAILS_REQUEST, GET_SUB_CATEGORY_REQUEST, GET_SUB_CATEGORY_SUCCESS, GET_SUB_CATEGORY_FAILURE, UPDATE_SUB_CATEGORIES_FAILURE, UPDATE_SUB_CATEGORIES_SUCCESS, UPDATE_SUB_CATEGORIES_REQUEST
} from '../Constant/categoryConstant';

export const newCategoryReducer=(state = {category:{}}, action) => {
    switch(action.type){
        case ADD_NEW_CATEGORY_REQUEST:
        return {
            loading: true,
            success:false,
            category:{}
                  
                  };
        case ADD_NEW_CATEGORY_SUCCESS:
            console.log(action.payload,'action payload category');
            return {
                loading: false,
                success:true,
                category: action.payload
            };

        case ADD_NEW_CATEGORY_FAILURE:
        return{
            // ...state,
    loading: false,
    success:false,
    error: action.payload,
}
default:
    return state;
}}

export const addSubCategoryReducer=(state = {addSubCategory:{}}, action) => {
    switch(action.type){
        case ADD_SUB_CATEGORY_REQUEST:
        return {
            loading: true,
            success:false,
            addSubCategory:{}
                  
                  };
        case ADD_SUB_CATEGORY_SUCCESS:
            console.log(action.payload,'action payload category');
            return {
                loading: false,
               success:true,
                addSubCategory: action.payload
            };

        case ADD_SUB_CATEGORY_FAILURE:
        return{
            // ...state,
    loading: false,
 success:false,
    error: action.payload,
}
default:
    return state;
}}





        export const getCategoryReducer=(state = {category:[]}, action) => {
            switch(action.type){
                case GET_ALL_CATEGORIES_REQUEST:
                return {
                    loading: true,
                    success:false,
                    category:[]
                          
                          };
                case GET_ALL_CATEGORIES_SUCCESS:
                    // console.log(action.payload,'actio payload category');
                    return {
                        loading: false,
                        success:true,
                        category: action.payload.category
                    };
        
                case GET_ALL_CATEGORIES_FAILURE:
                return{
                    // ...state,
            loading: false,
            success:false,
            error: action.payload,
        }
        default:
            return state;
        }}
        export const getAllSubCategoryReducer=(state = {category:[]}, action) => {
            switch(action.type){
                case GET_SUB_CATEGORIES_REQUEST:
                return {
                    loading: true,
                    success:false,
                    category:[]
                          
                          };
                case GET_SUB_CATEGORIES_SUCCESS:
                    // console.log(action.payload,'actio payload category');
                    return {
                        loading: false,
                        success:true,
                        category: action.payload
                    };
        
                case GET_SUB_CATEGORIES_FAILURE:
                return{
                    // ...state,
            loading: false,
            success:false,
            error: action.payload,
        }
        default:
            return state;
        }}

        export const getSubCategoryReducer=(state = {getSubcategory:[]}, action) => {
            switch(action.type){
                case GET_SUB_CATEGORY_REQUEST:
                return {
                    loading: true,
                    success:false,
                    getSubcategory:[]
                          
                          };
                case GET_SUB_CATEGORY_SUCCESS:
                    console.log(action.payload,'actio payload category');
                    return {
                        loading: false,
                        success:true,
                        getSubcategory: action.payload
                    };
        
                case GET_SUB_CATEGORY_FAILURE:
                return{
                    // ...state,
            loading: false,
            success:false,
            error: action.payload,
        }
        default:
            return state;
        }}
        export const getCategoriesDetailsReducer=(state = {category:{}}, action) => {
            switch(action.type){
                case GET_CATEGORY_DETAILS_REQUEST:
                return {
                    loading: true,
                    success:false,
                    category:{}
                          
                          };
                case GET_CATEGORY_DETAILS_SUCCESS:
                    console.log(action.payload,'actio payload category');
                    return {
                        loading: false,
                        success:true,
                        category: action.payload
                    };
        
                case GET_CATEGORY_DETAILS_FAILURE:
                return{
                    // ...state,
            loading: false,
            success:false,
            error: action.payload,
        }
        default:
            return state;
        }}

        export const getSubCategoriesDetailsReducer=(state = {category:{}}, action) => {
            switch(action.type){
                case GET_SUBCATEGORY_DETAILS_REQUEST:
                return {
                    loading: true,
                    success:false,
                    category:{}
                          
                          };
                case GET_SUBCATEGORY_DETAILS_SUCCESS:
                    console.log(action.payload,'actio payload category');
                    return {
                        loading: false,
                        success:true,
                        category: action.payload
                    };
        
                case GET_SUBCATEGORY_DETAILS_FAILURE:
                return{
                    // ...state,
            loading: false,
            success:false,
            error: action.payload,
        }
        default:
            return state;
        }}
        export const updateCategoriesReducer=(state = {updateCategory:{}}, action) => {
            switch(action.type){
                case UPDATE_CATEGORIES_REQUEST:
                return {
                    loading: true,
                    success:false,
                    // updateCategory:{}
                          
                          };
                case UPDATE_CATEGORIES_SUCCESS:
                    console.log(action.payload,'actio payload category');
                    return {
                        loading: false,
                        success:true,
                        // updateCategory: action.payload
                    };
        
                case UPDATE_CATEGORIES_FAILURE:
                return{
                    // ...state,
            loading: false,
            success:false,
            // error: action.payload,
        }
        default:
            return state;
        }}

        export const updateSubCategoriesDetailsReducer=(state = {updateSubCategory:{}}, action) => {
            switch(action.type){
                case UPDATE_SUB_CATEGORIES_REQUEST:
                return {
                    loading: true,
                    success:false,
                    updateSubCategory:{}
                          
                          };
                case UPDATE_SUB_CATEGORIES_SUCCESS:
                    console.log(action.payload,'actio payload category');
                    return {
                        loading: false,
                        success:true,
                        updateSubCategory: action.payload
                    };
        
                case UPDATE_SUB_CATEGORIES_FAILURE:
                return{
                    // ...state,
            loading: false,
            success:false,
            error: action.payload,
        }
        default:
            return state;
        }}

