import React from 'react'
import './viewmore.css'
import { useNavigate } from 'react-router-dom'
const ViewMore = ({name,title}) => {
  const navigate=useNavigate()
  // console.log(name,'name');
  const handleClick=()=>{
navigate(`/viewmore/${name}`)
  }

  return (
 <>
 <div className="viewmore" onClick={handleClick} style={{cursor:'pointer'}}>
    <div className="pagename">{title}</div>
    <div className="emptydiv"></div>
    <div className="viewall">view all</div>
 </div>
 </>
  )
}

export default ViewMore