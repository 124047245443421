import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import './viewall.css'
import { NavLink } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { subCategory } from '../../Redux/Action/subCategoryAction';
import { searchProduct } from '../../Redux/Action/listProductAction';
import { pricefilter } from '../../Redux/Action/priceFilter';
import { ADD } from '../../Redux/Action/cartAction';
import MetaData from '../layout/MetaData';
import { Slider } from '@mui/material';

const ViewAll = () => {
  const dispatch=useDispatch()
    const params=useParams()
  const {name}=params
  console.log(name,'parmas');
  useEffect(() => {
dispatch(searchProduct(name))
  }, [dispatch,name])
  let quantity=1
     
    const {filteredprice}=useSelector(state=>state.filterPrice)
    console.log(filteredprice);
    let min=filteredprice[0]
    let max=filteredprice[1]
    
    const handleChange = (event, newValue) => {
    dispatch(pricefilter(newValue));
    };
    const {keyword}=useSelector(state=>state.searchReducer)
  return (
<>
<MetaData title={`Products`} />
        <div className="products-main-fluid mb-5 mt-4  products_page">
        <div className="container-fluid">
    <div className="row ">
      <div className="col-lg-2 col-sm-2 col-md-2 products_page ms-0">
      <div className="price-filter bg-primary mt-3 text-light text-center">Price Filter</div>



<Slider
        getAriaLabel={() => 'Temperature range'}
        value={filteredprice}
        aria-label="Always visible"
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={0}
        max={100000}
      /> 
<button className='btn btn-danger' onClick={()=>window.location.reload()}>Reset Filter</button>



      </div>
      <div className="col-lg-10 col-sm-10 col-md-10">
        <div className="row">

{keyword && keyword?.filter((item)=>item.salePrice>min && item.salePrice<max )
.map((item,i)=>{
  const {_id,productPictures,name,categoryName, discountInPercent,sellingPrice,salePrice}=item
  return(
      <>
      
  <Card className='card-div mx-auto products_one' key={i}>
      <div className="img-div">
    <NavLink to={`/product/detail/${_id}`}>
      {productPictures.length>0 ?    <Card.Img variant="top" className='image-div' onClick={()=>dispatch(subCategory({_id,categoryName}))}
 src={`/uploads/products/${productPictures && productPictures[0].img}`}  />:
<Card.Img variant="top" className='image-div' onClick={()=>dispatch(subCategory({_id,categoryName}))}
 src='./images/logo1.png'  />
      }
</NavLink>
    <div className="discount">{discountInPercent?<div>{discountInPercent}%off</div>:null}</div>
    </div>  
    <hr style={{margin:'2px'}}/>
    <Card.Body>
     <NavLink to='/products'> 
     <div className="card-title text-primary fw-semibold text-capitalize">
      {name && name.length>25?<div className='substring'>{name.substring(0,25)}...</div>:name}</div>
     </NavLink>
      <div className="price1">
          <div className="price-div fw-semibold">Rs.{salePrice}/-</div>
          <div className="orgininal-price fw-semibold"><del>{sellingPrice!==salePrice?<div className='selling'>{sellingPrice}/-</div>:null}</del></div>
      </div>
      <hr/>

      {/* <div className="price1 w-100">
          <div className="price-div1 fw-semibold"> <ShoppingCartIcon style={{width:'40px',cursor:'pointer'}} onClick={()=>dispatch(ADD(item,quantity))}/></div>
          <div className="orgininal-price1 fw-semibold">
 <Link to={`/products/${category.name}`}  target='_blank'>
  <div className='text-primary' onClick={(e)=>{dispatch(subCategory(category))}}> View More</div>
  </Link> 

          {/* </div> */}
      {/* </div>  */}
      <div className='btn btn-primary w-100' onClick={(e)=>{dispatch(ADD({productPictures,name,categoryName, discountInPercent,sellingPrice,salePrice,quantity}))}}> <button className='btn btn=primary'>Add To Cart</button></div>

   
    </Card.Body>
    
  </Card>
{/* {product1.name} */}
      </>
  )
})}
</div></div>
<div className='text-center'>
{/* {
  product1 && product1.length<total &&(
  <button className="btn btn-info m-3 w-50 text-center"
  onClick={(e)=>{e.preventDefault()
  setPage(page + 1)
  }}>{loading?'loading':'Load more'}</button>
)} */}
</div>
</div></div></div>
</>
  )
}

export default ViewAll