import axios from 'axios';
import React from 'react'
import { useAlert } from 'react-alert';
import { Link, useNavigate, useParams } from 'react-router-dom'
// import './productdelete.css'

const DeleteSubCat = (props) => {
    const {id}=useParams()
    const alert=useAlert()
    const navigate=useNavigate()

    const handleReturn=()=>{
      navigate('/admin/subcategories')
    }
    const handleDelete = async () => {

        const userFromStorage=localStorage.getItem("user")
        ?JSON.parse(localStorage.getItem("user")):null;
           const config={headers:{
            Authorization:`Bearer ${userFromStorage.token}`
        }}
        await axios.delete(`/admin/category/subcategory/${id}`,config)
      
          .then((res) => {
            alert.success("category deleted Successfully");
          });
          navigate('/admin/subcategories')
      };

  return (
 <>
<div className="container">
  <div className="row">
<div className="productdel">
<h5> Are You Sure To Delete Item</h5>

<div className="row d-flex flex-row mx-auto mt-5">
<button className='btn btn-danger w-25 mx-auto mb-2' onClick={handleDelete}>Yes</button>
<button className='btn btn-success w-25 mx-auto mb-2 text-dark' onClick={handleReturn}>No</button>
</div>


</div>
  </div>
</div>

 

 </>
  )
}

export default DeleteSubCat