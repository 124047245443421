import React from 'react'
import Props from '../Props/Props'
import ViewMore from '../Props/ViewMore'
import { useSelector } from 'react-redux'

const Cosmetics = () => {
    const {products}=useSelector(state=>state.productsReducer)
    console.log(products,'products');
    let name='bigDiscount'
  return (
  <>
  <div className="row mt-3+">
  <ViewMore name={name} title={`Cosmetics`}/>
  </div>
  
  <div className="container-div">  
     {
    products?products.filter((item)=>{
             return(item.categoryName?.toLowerCase().includes('Cosmetics'))      
             }).slice(0,5).map((item)=>{
             return(
                 <>
       
                <Props
                   key={item._id}
                   fileName={<img src={`/uploads/products/${item.productPictures[0]?.img}`}  alt='' />}
   
                   fileName2={<img src={`/uploads/products/${item.productPictures[1]?item.productPictures[1].img:item.productPictures[0]?.img}`}  alt='' />}
        
                   name={item.name}
                  salePrice={item.salePrice}
                   sellingPrice={item.sellingPrice}
                   star={item.star}
               
                />
                 
                 </>
             )
         }):null
     }
     </div>
     
  
  </>
  )
}

export default Cosmetics