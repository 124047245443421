import React, { useEffect } from 'react';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { loadUser } from '../../Redux/Action/userAction';

export default function UserProfile() {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  // const dispatch=useDispatch()
  const logoutHandler = () => {
    localStorage.removeItem("user") 
    navigate('/login')};
  const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer);
  console.log(userDetails,'user details,reducers');
 
// console.log(user,'profile user');
  useEffect(() => {
 dispatch(loadUser())
  }, [dispatch])
  
  return (
    <>
    {userDetails ? (
      <>
<div className="container mt-2 text-center">
<div className="row">
  <div className="col-lg-9"></div>
  <div className="col-lg-3">
<button className='btn btn-danger' onClick={()=>logoutHandler()}>logout</button>
<NavLink to='/updateProfile'><button className='btn btn-danger m-1'>update profile</button></NavLink></div>
</div>
</div>
<section style={{ backgroundColor: '#eee' }}>
  <MDBContainer className="py-5">
    <MDBRow>
      <MDBCol>
      
        <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
       
          <MDBBreadcrumbItem active>{userDetails.name}</MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol lg="4">
        <MDBCard className="mb-4">
          <MDBCardBody className="text-center">
            <MDBCardImage
              src={`/uploads/userprofile/${userDetails.fileName}`}
              alt="avatar"
              className="rounded-circle"
              style={{ width: '150px' }}
              fluid />
        
            <div className="d-flex justify-content-center mb-2">
              <MDBBtn>Follow</MDBBtn>
              <MDBBtn outline className="ms-1">Message</MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>

       </MDBCol>
       </MDBRow>
      <MDBCol lg="8">
        <MDBCard className="mb-4">
          <MDBCardBody>
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>Full Name</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{userDetails.name}</MDBCardText>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>E-mail</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{userDetails.email}</MDBCardText>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>Phone Number</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{userDetails.phoneNumber}</MDBCardText>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>user Name</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{userDetails.userName}</MDBCardText>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>Address</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{userDetails.address}</MDBCardText>
              </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
              <MDBCol sm="3">
                <MDBCardText>shop name</MDBCardText>
              </MDBCol>
              <MDBCol sm="9">
                <MDBCardText className="text-muted">{userDetails.shopName}</MDBCardText>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
</MDBCol>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard className="mb-4 mb-md-0">
             

         
             </MDBCard>
             </MDBCol>
        </MDBRow>
        
  
    
  </MDBContainer>
  
</section>
    </>
    )
    :<h1>no user found</h1>}
   
    </>
  );
}