import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSinleBannerAction } from '../../Redux/Action/bannerAction'

const GetSingleBanner = () => {
    const {id}=useParams()
    const dispatch=useDispatch()
    const {loading,success,banner}=useSelector(state=>state.getSingleBannerReducer)
    console.log(banner);
    useEffect(() => {
    dispatch(getSinleBannerAction(id))
    }, [dispatch,id])
    
  return (
   <>
    {
        banner.name
    }
   </>
  )
}

export default GetSingleBanner