import { PRICE_FILTER } from "../Constant/priceFilter";

export const filterPrice=(state = {filteredprice:[0,1000000]}, action) => {
    switch(action.type){
        case PRICE_FILTER:
        //    let a=action.payload.split(",")[0];
        //     let b=action.payload.split(",")[1];
            return{
                // filteredprice:[a,b],
                filteredprice:action.payload,
            };
            default:
                return state

}}