import { useSelector } from "react-redux";
import { useLocation,useParams} from "react-router-dom"


const ErrorPgae = () => {
    const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer);

    const {wrongurl}=useParams()
    const location=useLocation()
    const loc=location.pathname===`/${wrongurl}`?true:false
   
    
return(
    <>
         {/* {location.pathname===`/sajjad`?<h1>welcome {user.name}</h1>:<h1>welcome Dear User</h1>} */}
         <h1 className="text-center"> WellCome Dear {userDetails?userDetails.name:<h1>User</h1>}</h1>
         <br></br>
         <br></br>
         <br></br>
    <h1 className="text-center">The link <u className="text-danger">"{`http://wwww.com/${wrongurl}`}"</u> is under process</h1>
   
    </>
)


}





export default ErrorPgae