import { 
  GET_OFFER3_FAIL, GET_OFFER3_REQUEST, GET_OFFER3_SUCCESS, 
  GET_OFFER1_FAIL, GET_OFFER1_REQUEST, GET_OFFER1_SUCCESS, 
  GET_OFFER2_FAIL, GET_OFFER2_REQUEST, GET_OFFER2_SUCCESS 


} from "../Constant/offerContant"
export const getOffer1Reducer = (state = { offer1: [] }, action) => {
    switch (action.type) {
        case GET_OFFER1_REQUEST:
         
            return {
              loading: true,
          offer1: [],
            }
        case GET_OFFER1_SUCCESS:
         console.log(action.payload,'appppppppp');
            return {
              loading: false,
              offer1:action.payload,
            }
        case GET_OFFER1_FAIL:
         
            return {
              loading: false,
           offer1: action.payload,
            }
        
            default:
                return state
        }

}
export const getOffer2Reducer = (state = { offer2: [] }, action) => {
    switch (action.type) {
        case GET_OFFER2_REQUEST:
         
            return {
              loading: true,
          offer2: [],
            }
        case GET_OFFER2_SUCCESS:
          console.log(action.payload,'appppppppp');
            return {
              loading: false,
              offer2:action.payload,
            }
        case GET_OFFER2_FAIL:
         
            return {
              loading: false,
           offer2: action.payload,
            }
        
            default:
                return state
        }

}
export const getOffer3Reducer = (state = { offer3: [] }, action) => {
    switch (action.type) {
        case GET_OFFER3_REQUEST:
         
            return {
              loading: true,
          offer3: [],
            }
        case GET_OFFER3_SUCCESS:
          console.log(action.payload,'appppppppp');
            return {
              loading: false,
              offer3:action.payload,
            }
        case GET_OFFER3_FAIL:
         
            return {
              loading: false,
           offer3: action.payload,
            }
        
            default:
                return state
        }

}