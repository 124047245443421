import axios from "axios";
import "./products.css";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MetaData from "../layout/MetaData";
import Sidebar from "../DashBoard/Sidebar";
import {
  getProductDetails,
  updateProduct,
} from "../../Redux/Action/listProductAction";
import UserSideBar from "../DashBoard/UserSideBar";

const UpdateUserProduct = () => {
  const alert = useAlert();
  const { getSubcategory } = useSelector(
    (state) => state.getSubCategoryReducer
  );
  let salePrice;
  const { category } = useSelector((state) => state.getCategoryReducer);
  const { product } = useSelector((state) => state.productDetails);
  // console.log(product.productPictures[0].img);
  const { loading, success } = useSelector((state) => state.productReducer);
  console.log(loading, "ssssssss", success, "sdfghip");
  const { _id } = product;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [image, setImage] = useState([]);
  const [imagesName, setImagesName] = useState([]);
  const [name, setName] = useState(product.name);
  const [description, setDescription] = useState(product.description);
  const [sellingPrice, setSellingPrice] = useState(product.sellingPrice);
  const [discountInAmount, setDiscountInAmount] = useState(
    product.discountInAmount
  );
  const [discountInPercent, setDiscountInPercent] = useState(
    product.discountInPercent
  );
  const [costPrice, setCostPrice] = useState(product.costPrice);
  const [stock, setStock] = useState(product.stock);
  const [brand, setBrand] = useState(product.brand);
  const [size, setSize] = useState(product.size);
  const [weight, setWeight] = useState(product.weight);
  const [color, setColor] = useState(product.color);
  const [offer, setOffer] = useState(product.offer);
  const [bigDiscount, setBigDiscount] = useState(product.bigDiscount);
  const [newArrival, setNewArrival] = useState(product.newArrival);
 
  const handleSubmit = async () => {
    // const formData = new FormData();
    // formData.append('name',name)
    // formData.append('')
    dispatch(
      updateProduct({
        _id,
        name,
        imagesName,
        image,
        description,
        newArrival,
        bigDiscount,
        sellingPrice,
        color,
        weight,
        stock,
        costPrice,
        discountInAmount,
        discountInPercent,
        salePrice,
      })
    );
  };

  useEffect(() => {
    setName(product.name)
    setBigDiscount(bigDiscount)
    setBrand(product.brand)
    setColor(product.color)
    setCostPrice(product.costPrice)
    setDescription(product.description)
    setDiscountInAmount(product.discountInAmount)
    setDiscountInPercent(product.discountInPercent)
    setName(product.name)
    setOffer(product.offer)
    setSellingPrice(product.sellingPrice)
    setSize(product.size)
    setStock(product.stock)
    setWeight(product.weight)
    setNewArrival(product.newArrival)
    if (success === true && loading === false) {
      alert.success("product updated successsfully");
    }
    dispatch(getProductDetails(id));
  }, [dispatch, id, alert, success, loading,product.name]);

  const createProductImagesChange = (e) => {
    const files = Array.from(e.target.files);

    // console.log(abc,'abc');
    setImage([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
          setImage((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);

      setImagesName(files.map((item) => item.name));
    });
  };
  return (
    <>
      <div>
        <MetaData title={`Update Product`} />
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-2 products_page me-2">
              <UserSideBar />
            </div>
            <div className="col-9 products_page">
              <section className="register w-100 mt-2 products_page">
                <div className="container h-100">
                  <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-12 col-xl-11">
                      <div
                        className="card text-black w-100"
                        style={{ background: "none", border: "none" }}
                      >
                        <div className="card-bo p-md-5">
                          <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">
                              <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                                Update Product
                              </p>

                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    choose Picture
                                  </label>
                                </div>
                                <div className="form-outline flex-fill mb-0">
                                  <input
                                    type="file"
                                    name="productPictures"
                                    accept="image/*"
                                    onChange={createProductImagesChange}
                                    multiple
                                  />
                                  <div className="row d-flex flex-row">
                                    <h6>old images</h6>
                                  {product.productPictures?.map((image, index) => (
                                    // console.log(image,'iiiiiiiiiiiiiiiiiiiiiiiiiiii')
                                    <img
                                      key={index}
                                      src={`/uploads/products/${image.img}`}
                                      alt="Product Preview"
                                      style={{
                                        height: "50px",
                                        width: "75px",
                                        margin: "10px",
                                      }}
                                    />
                                  ))}</div>
                                  <h6>New Images</h6>
                                  <div className="row d-flex flex-row">
                                  {imagesPreview.map((image, index) => (
                                    <img
                                      key={index}
                                      src={image}
                                      alt="Product Preview"
                                      style={{
                                        height: "50px",
                                        width: "75px",
                                        margin: "10px",
                                      }}
                                    />
                                  ))}</div>
                                </div>
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    Name
                                  </label>
                                </div>
                                <input
                                  type=""
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  className="product_upload"
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                  description
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    cost Price
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={costPrice}
                                  onChange={(e) => setCostPrice(e.target.value)}
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                  Price
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={sellingPrice}
                                  onChange={(e) =>
                                    setSellingPrice(e.target.value)
                                  }
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                 discount In Percent
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={discountInPercent}
                                  onChange={(e) =>
                                    setDiscountInPercent(e.target.value)
                                  }
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    discount In Amount
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={discountInAmount}
                                  onChange={(e) =>
                                    setDiscountInAmount(e.target.value)
                                  }
                                /></div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    Sale Price
                                  </label>
                                </div>
                              
                               <h4 className="text-danger text-center bg-light product_upload w-100"> {salePrice=Math.ceil(sellingPrice-(sellingPrice*discountInPercent/100)-discountInAmount)}
                               </h4></div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    stock
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={stock}
                                  onChange={(e) => setStock(e.target.value)}
                                />
                              </div>
                             
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    brand
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={brand}
                                  onChange={(e) => setBrand(e.target.value)}
                                />
                              </div>

                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    size
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={size}
                                  onChange={(e) => setSize(e.target.value)}
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    big Discount
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={bigDiscount}
                                  onChange={(e) =>
                                    setBigDiscount(e.target.value)
                                  }
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    color
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={color}
                                  onChange={(e) => setColor(e.target.value)}
                                />
                              </div>
                              <div className="d-flex flex-row align-items-center mb-4">
                                <div className="col-3">
                                  {" "}
                                  <label
                                    className="form-label"
                                    for="form3Example1c"
                                  >
                                    weight
                                  </label>
                                </div>
                                <input
                                  type=""
                                  className="product_upload"
                                  value={weight}
                                  onChange={(e) => setWeight(e.target.value)}
                                />
                              </div>

                              <button
                                className="btn btn-primary"
                                onClick={handleSubmit}
                              >
                                post data
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateUserProduct;
