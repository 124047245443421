import React, { Fragment, useEffect, useState } from "react";
import "./Cart.css";
import DeleteIcon from '@mui/icons-material/Delete';
import CartItemCard from "./CartItemCard";
import { useSelector, useDispatch } from "react-redux";
import { ADD, removeItemsFromCart,DLT } from "../../Redux/Action/cartAction";
import { Typography } from "@material-ui/core";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import { Link,  useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import MetaData from "../layout/MetaData";

const Cart = () => {

  const navigate=useNavigate()
  const {id}=useParams()
  const dispatch = useDispatch();
  const {cartItems}=useSelector(state=>state.cart)
  const  {isAuthenticated,loading,userDetails}= useSelector((state) => state.userLoadReducer)
  const [quantity, setQuantity] = useState(1)
  // const  {user}= useSelector((state) => state.loadedUser);
  const checkoutHandler = () => {
    if(!userDetails){
      navigate('/user/login')
      if(userDetails){
        navigate('/shipping')
      }
    }
    else{
      navigate('/shipping')
    }

  };
   
 
 
  return (
    <Fragment>
    <MetaData title={`Cart`}/>

      {cartItems.length === 0 ? (
        <div className="emptyCart">
          <RemoveShoppingCartIcon />

          <Typography>No Product in Your Cart</Typography>
          <Link to="/products">View Products</Link>
        </div>
      ) : (
        <Fragment>
          <table class="table table-striped fw-bold products_page mt-3">
  <thead className="bg-dark text-light">
    <tr>
      <th scope="col">#</th>
      <th scope="col">item</th>
      <th scope="col">price</th>
      <th scope="col">quantity</th>
      <th scope="col">subtotal</th>
    </tr>
  </thead>
  <tbody>
  {cartItems &&
              cartItems.map((item,i) => (
   
               <>
          
       
  <tr>
      <th scope="row">{i+1}</th>
      <td className="d-flex flex-row"><img src={`/uploads/products/${item?.productPictures && item?.productPictures[0]? item.productPictures[0]?.img:null}`}
                 style={{width:75,height:75,margin:2,border:"3px solid silver"}}/>
             {item.name}</td>

      <td>Rs:{item.salePrice}/-</td>
      <td>{item.quantity}</td>
      {/* <td>
      <input className='quantity text-center fw-bold text-danger' type="number" 
                    value={quantity} onChange={(e)=>setQuantity(e.target.value)} 
                    style={{width:'100px',height:'30px',fontSize:'22px'}}/>
      </td> */}
      <td>{item.quantity*item.salePrice}
      <DeleteIcon style={{color:'red',cursor:'pointer', marginLeft:'15px'}} 
               onClick={()=>dispatch(removeItemsFromCart(item._id))}

               
               />
      </td>
    </tr>
</>))}

</tbody>
</table>
<div className="row d-flex flex-row products_page fw-bold mt-3 mb-3 bg-danger text-light">
      <div className="col-lg-9 col-sm-9 col-md-9">Groass Total</div>
    <div className="col-lg-3 col-sm-3 col-md-3 fw-bold text-center"> <p>{`Rs:${cartItems.reduce(
                  (acc, item) => acc + item.quantity *item.salePrice,0
                )}`}</p></div>

</div>
<div className="checkOutBtn">
                <button onClick={checkoutHandler}>Check Out</button>
              </div>
             
              </Fragment>

        
      )}
      
    </Fragment>
  );
};

export default Cart;
