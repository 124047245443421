import React from 'react'
import Helmet from 'react-helmet'
import './metadata.css'
const MetaData = ({title}) => {
  return (
<>
<Helmet>
<title className='meta'>
    {title}
</title>

</Helmet>


</>

  )
  
}

export default MetaData