import axios from 'axios';
import {PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS,PRODUCT_DETAILS_FAIL} from '../Constant/productConstants'
export const listProductDetails=(id)=>async(dispatch)=>{
    console.log(id,'id id id ');
    try {
        dispatch({
            type:PRODUCT_DETAILS_REQUEST
        });
        const {data}=await axios.get(`/admin/product/${id}`)
console.log(data,'product details');

        dispatch({type:PRODUCT_DETAILS_SUCCESS,
        payload:data.product
        })
        
    } catch (error) {
    dispatch({type:PRODUCT_DETAILS_FAIL,
    payload:error.payload
    })
    }
}