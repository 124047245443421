import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'

const Logout = () => {
  const [account, setAccount] = useState('')
    const navigate=useNavigate()
    const logoutHandler = () => {
        localStorage.removeItem("user");

        navigate("/login");
      };
   
 
      

  return (
   <>
 
 <button className='btn btn-danger' onClick={logoutHandler}>logout</button>
   
   </>
  )
}

export default Logout