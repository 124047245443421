import React, { useEffect, useState } from 'react'
import Sidebar from '../DashBoard/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { getSinleBannerAction, getUpdateBannerAction } from '../../Redux/Action/bannerAction'
import { useParams } from 'react-router-dom'
import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader/Loader'
import './banner.css'
import { useAlert } from 'react-alert'

const BannerUpdate = () => {
  const {banner}=useSelector(state=>state.getSingleBannerReducer)
  const {loading,success,}=useSelector(state=>state.updateBannerReducer)
  // console.log(loading,success,'update banner');

  // console.log(banner,'banner');
  const dispatch=useDispatch()
  const alert=useAlert()
  const {id}=useParams()
    const [preview, setPreview] = useState(banner.fileName)
   let [fileName, setFileName] = useState(banner.fileName)
    const [name, setName] = useState(banner.name)
    const [image, setImage] = useState('')

    const handleImage=(e)=>{
        let reader= new FileReader();
            reader.onload=(e)=>{
    if(reader.readyState===2){
            setPreview(reader.result)
            setImage(reader.result)}
        }
        if(e.currentTarget.files[0].size>300000){
        
  alert.error('size limit increased')
  return;
        }else{
          reader.readAsDataURL(e.currentTarget.files[0]) 
        }
        setFileName(e.target.files[0].name)
      }
      useEffect(() => {
  
        // setImage(banner.fileName)
      dispatch(getSinleBannerAction(id))
      if(loading===false && success===true){
        alert.success('banner Updated')
      }
      if(loading===false && success===false){
        alert.error('Banner did not Update')
      }
      }, [dispatch,id,alert,loading,success])
      useEffect(() => {
        setName(banner.name)
        setFileName(banner.fileName)
        setPreview(banner.fileName)
      }, [banner.name,banner.fileName])
      
    
      const handleClick=async()=>{
          dispatch(getUpdateBannerAction({name,image,fileName,id}))}
      
  return (
<>
<MetaData title={`Banner Update - Admin`} />
{/* copy area starts */}
{loading?<Loader/>:(
  <div className="container-fluid">
  <div className="row d-flex flex-row">
 <div className="col-lg-2"><Sidebar/></div>
 <div className="col-lg-10">
<section className="register">
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-lg-12 col-xl-11" >
        <div className="card text-black w-100" style={{background:'none',border:'none'}}>
          <div className="card-bo p-md-5">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-12 col-xl-10 order-2 order-lg-1">

                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Update Banner</p>
{/* copy area end */}

<div className="d-flex flex-row align-items-center mb-4">
                 <div className='col-3'> <label className="form-label" for="form3Example1c">Image Name</label></div>
                              <div className="form-outline flex-fill mb-0">
                      <input type="text" className="form-control ms-3" placeholder='Full Name'
                     value={name} onChange={(e)=>setName(e.target.value)}
                  name='name' />
                  
                    </div>
                  </div>


<div className="d-flex flex-row align-items-center mb-4">
                  <div className='col-3'><label className="form-label" for="form3Example3c">upload Image</label>
                  </div>
                    <div className="form-outline flex-fill mb-0">
                    <input type="file" className="form-control ms-3"     
                    // value={banner.fileName}              
            //    onChange={handleImage}
               onChange={handleImage}
                    />
                
                  
                    
                    </div>
                  </div>
           
                  <div className="row bottom-images-div d-flex flex-row">
      
                  <div className='text-center m-2'>
                    Old Image: {<img src={`/uploads/banner/${banner.fileName && banner.fileName}`}
                  alt={fileName}  style={{height:'75px',width:'100px'}}/>
                 
                  }
                 </div>
                 <div className='text-center m-2'> {preview!==''?<img src={preview}style={{height:'75px',width:'100px'}}/>:<h6>no picture found</h6>}</div>
                  </div>

                 <button type="submit" className="btn btn-primary btn-lg" onClick={handleClick}>Upload</button>


                </div></div></div></div></div></div></div></section></div></div></div>
)}

</>
  )
}

export default BannerUpdate
