import React, { useEffect, useState } from 'react'
import SideBar from './Sidebar'
import './dashboard.css'
import { Link } from 'react-router-dom'
import { Typography } from "@material-ui/core";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Doughnut, Line } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { getAdminCategory, getAdminProduct, getAdminProductByUserId, getAdminUsers } from "../../Redux/Action/adminAction";
import { getAllOrders } from "../../Redux/Action/orderAction";
import { getAllUsers } from "../../Redux/Action/userAction";
import Sidebar from "./Sidebar";
import MetaData from "../layout/MetaData";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import { PieChart } from 'react-minimal-pie-chart';
import { Avatar } from "antd";
import { deepSubCategory } from "../../Redux/Action/subCategoryAction";
import { getAdminSubCategories } from '../../Redux/Action/catgoryAction';
import axios from 'axios';
import UserSideBar from './UserSideBar';
const AdminDashBoard = () => {
    const [products, setProducts] = useState('')
  const dispatch = useDispatch();
  const userFromStorage=localStorage.getItem("user")
  ?JSON.parse(localStorage.getItem("user")):null;
     const config={headers:{
      Authorization:`Bearer ${userFromStorage.token}`
  }}
  const{_id}=userFromStorage.user

    const {filterProductByuser}=useSelector(state=>state.productsFilterReducer)
console.log(filterProductByuser,'filterProductByuser');
  
    useEffect(() => {
      dispatch(getAdminProductByUserId(_id))
  }, [dispatch,_id]);
  return (
  <>
  <MetaData title="Dashboard - Admin Panel" />
  <div className="containerh">
    <div className="dashboard">
  <div className="sidebar-div"><UserSideBar/></div>
  <div className="dash">
  <div className='inner-dashboard'>
  <Link to="/admin/user/products">
  
   <p><ShoppingBagIcon/></p>
   <p>Products</p>
   <p className='stock mx-auto'>{filterProductByuser && filterProductByuser?.length}</p>
   
 </Link>
  </div>
 
  
</div>

</div>
  </div>


 
  
  </>
  )
}

export default AdminDashBoard